import axios from 'axios';
import { handleResponse } from './HelperService';

const getInspMstAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/mst/get-all', {params});
  console.log(response);
  return handleResponse(response, onSuccess, onError);
};

const getInspMstById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/mst/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createInspMst = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/mst/create`, payload);
  return handleResponse(response, onSuccess, onError);
};

const updateInspMst = (payload, onSuccess, onError) => {
  const id = payload.id;
  const response = axios.put (`v1/admin/mst/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const changeStatusInspMst = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/mst/updateSta/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteInspMst = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/mst/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getInspMstAll, getInspMstById, createInspMst, updateInspMst, changeStatusInspMst, deleteInspMst};
