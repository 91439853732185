import React, { Component, useEffect, useState, useRef } from 'react';
import { createInspAql, deleteInspAql, getInspAqlAll, updateInspAql } from '../../../Service/InspAqlService';
import { useForm, Controller, handleSubmit } from 'react-hook-form';
import Swal from 'sweetalert2';
import MTable from '../../../Components/MTable/MTable';
import Switch from '@mui/material/Switch';
import Overlay from '../../../Components/Overlay/Overlay';
const { $ } = window;
let processingId = -1;

const InspAqlScreen = () => {
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm();
    const mTable = useRef();
    const [state, setState] = useState({aql: {}, processing: false}); 

    const columns = [
        { id: 1, title: 'AQL Code', field: 'code', sortable: true },
        { id: 2, title: 'AQL', field: 'aql', sortable: true },
        { id: 3, title: 'Lot size min', field: 'lotsizemin', sortable: true },
        { id: 4, title: 'Lot size max', field: 'lotsizemax', sortable: true },
        {
            id: 5,
            title: 'Status',
            sortable: true,
            render: item => {
                return (
                    item.is_active ? `Active` : `Inactive`
                );
            },
        },
        {
            id: 3,
            title: '',
            sortable: true,
            render: item => {
                return (
                    <div>
                        <a onClick={onEdit(item)} style={{ cursor: 'pointer', color: 'green', display: 'inline-block', marginRight: 15 }}>
                            <i className="fas fa-edit" />
                            <span style={{ marginLeft: 10 }}>Edit</span>
                        </a>
                        <a onClick={onRemove(item)} style={{ cursor: 'pointer', color: 'maroon', display: 'inline-block' }}>
                            <i className="fas fa-trash" />
                            <span style={{ marginLeft: 10 }}>Remove</span>
                        </a>
                    </div>);
            },
        },
    ];

    const propsTable = { columns, getData: getInspAqlAll };

    const removeData = id => {
        deleteInspAql(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

    const onEdit = item => () => {
        reset(item);
        setState({...state, aql: item});
        console.log('selected ', item);
    }

    const onRemove = item => () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(item.id);
            }
        });
    }

    const onAddData = () => {
        onReset();
    }

    const startProcessing = () => {
        processingId = setTimeout(() => {
            setState({...state, processing: true});
        }, 150);
    }

    const stopProcessing = () => {
        clearTimeout(processingId);
        setState({...state, processing: false});
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);
        }
        startProcessing();
        const response = aql.id ? updateInspAql(formData) : createInspAql(formData);
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                stopProcessing();
                Swal.fire({
                    icon: 'success',
                    title: 'Save data success',
                    text: 'Data has been saved!'
                }).then(r => { mTable.current.refresh(); onReset() })
            }
        }).catch((err) => {
            if (err.response.status == 422) {
                const obj = Object.keys(err.response.data.errors);
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: err.response.data.errors[obj[0]][0]
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Save data error!'
                });
            }
            stopProcessing();
        });
    }

    const onReset = () => {
        reset({
            id: 0,
            code: '',
            aql: 0,
            lotsizemin: 0,
            lotsizemax: 0,
            level: 0,
            qty: 0,
            acc: 0,
            reject: 0,
            is_active: true,
        });
        setState({...state, aql: {}});
    }

    const { aql, processing } = state;

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Inspection AQL </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Inspection AQL Page</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-4'>
                            <Overlay display={processing} />
                            <form name="form-detail" onSubmit={handleSubmit(onSubmit)}>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h3 className="card-title">
                                            <i className="fas fa-tag mr-1" />
                                            {aql.id ? 'Edit Inspection AQL' : 'New Inspection AQL'}
                                        </h3>
                                    </div>
                                    <div className='card-body'>
                                        <div className='form-group'>
                                            <label htmlFor="code">Code</label>
                                            <input id="code" {...register("code", { required: { value: true, message: 'Code is required!' } })} className='form-control' />
                                            {errors.code && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.code.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="aql">AQL</label>
                                            <input type="number" min="0" step="0.1" id="aql" {...register("aql", { required: { value: true, message: 'AQL is required!' } })} className='form-control' />
                                            {errors.aql && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.aql.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="lotsizemin">Lot Size Minimum</label>
                                            <input type="number" min="0" id="lotsizemin" {...register("lotsizemin", { required: { value: true, message: 'Lot Size Minimum is required!' } })} className='form-control' />
                                            {errors.lotsizemin && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.lotsizemin.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="lotsizemax">Lot Size Maximum</label>
                                            <input type="number" min="0" id="lotsizemax" {...register("lotsizemax", { required: { value: true, message: 'Lot Size Maximum is required!' } })} className='form-control' />
                                            {errors.lotsizemax && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.lotsizemax.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="level">Level</label>
                                            <input type="number" min="0" id="level" {...register("level", { required: { value: true, message: 'Level is required!' } })} className='form-control' />
                                            {errors.level && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.level.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="qty">Qty</label>
                                            <input type="number" min="0" id="qty" {...register("qty", { required: { value: true, message: 'Quantity is required!' } })} className='form-control' />
                                            {errors.qty && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.qty.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="acc">Accept</label>
                                            <input type="number" min="0" id="acc" {...register("acc", { required: { value: true, message: 'Accept is required!' } })} className='form-control' />
                                            {errors.acc && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.acc.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="reject">Reject</label>
                                            <input type="number" min="0" id="reject" {...register("reject", { required: { value: true, message: 'Reject is required!' } })} className='form-control' />
                                            {errors.reject && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.reject.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="brand-name">Active</label>
                                            <div>
                                                <Controller name="is_active" defaultValue={true} control={control} render={({ field }) => { return (<Switch color="warning" {...field} checked={field.checked} />) }} />
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div style={{ height: 1, background: '#ccc', marginTop: 16 }}></div>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                                <div style={{ width: 150, marginRight: 10 }}><button type="button" className="btn btn-block btn-outline-danger" onClick={onReset}><i className='fa fa-times'></i>  Clear</button></div>
                                                <div style={{ width: 150 }}><button type="submit" className="btn btn-block btn-outline-dark"><i className='fa fa-save'></i> Save</button></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-tags mr-1" />
                                        List Inspection AQL
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <MTable ref={mTable} {...propsTable} onAddData={onAddData} showIndex={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default InspAqlScreen;