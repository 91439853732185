import React, { Component, useEffect, useState, useRef } from 'react';
import { deleteQualityIssue, getQualityIssueAll, getQualityIssueSummaryByIqc } from '../../Service/QualityIssueService';
import { useForm, Controller, handleSubmit } from 'react-hook-form';
import Swal from 'sweetalert2';
import MTable from '../../Components/MTable/MTable';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { getIqcAll } from '../../Service/IqcService';
import { Dropdown } from 'primereact/dropdown';
const { $ } = window;
const localState = {};

const QualityIssueScreen = () => {
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const mTable = useRef();

    const [summaries, setSummaries] = useState([]);
    const [summaryId, setSummaryId] = useState(null);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {}, []); 

    const onView = item => () => {
        navigate("view/" + item.id);
    };

    const onEdit = item => () => {
        navigate("edit/" + item.id);
    };

    const onRemove = item => () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(item.id);
            }
        });
    }

    const onSubmit = (data) => {
        if (summaryId != null) {
            getQualityIssueSummaryByIqc(summaryId).then(res => {
                navigate("summary/" + summaryId);
            }).catch((err) => {
                if(err.response.status == 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found',
                        text: 'Data Quality Issue Not Found!'
                    });
                }
            });
        }
        
        setShowDialog(false);
        setSummaryId(null);
    }

    const removeData = id => {
        deleteQualityIssue(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

    const columns = [
        { id: 1, title: 'Doc No', field: 'docnumber', sortable: true },
        { id: 2, title: 'Report Date', field: 'report_date', sortable: true },
        { id: 3, title: 'Lot Order', field: 'lotorder', sortable: true },
        { id: 4, title: 'KBT No', field: 'iqc_docnumber', sortable: true },
        { id: 5, title: 'Model', field: 'model', sortable: true },
        { id: 6, title: 'Created By', field: 'created_by', sortable: true },
        {
            id: 7,
            title: 'Action',
            render: item => {
                return (
                    <div>
                        <a
                            onClick={onView(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-eye" />
                            <span style={{ marginLeft: 10 }}>View</span>
                        </a>
                        <a
                            onClick={onEdit(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'green',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-edit" />
                            <span style={{ marginLeft: 10 }}>Edit</span>
                        </a>
                        <a
                            onClick={onRemove(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'maroon',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-trash" />
                            <span style={{ marginLeft: 10 }}>Delete</span>
                        </a>
                    </div>
                );
            },
        },
    ];

    const onAddData = () => {
        getIqcAll().then(res => {
            setSummaries(res.data.data);
        });

        setShowDialog(true);
    }

    const  propsTable = { order: 'report_date', direction: 'desc', columns, getData: getQualityIssueAll };


    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Quality Issue</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Quality Issue</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='card-title'><i className='fa fa-tools' /> Quality Issue List</div>
                                    <button type='button' className='btn btn-outline-dark' style={{ float: 'right', width: 100, marginRight: 20 }} onClick={onAddData}><i className="far fa-list-alt"></i></button>
                                </div>
                                <div className='card-body'>
                                    <MTable ref={mTable} {...propsTable} showIndex={true} showAddButton={false}/>
                                </div>
                                <div className='card-footer'></div>
                            </div>
                        </div>
                        <Dialog header="View Summary" visible={showDialog} onHide={() => setShowDialog(false)} breakpoints={{'960px': '120vw'}} style={{width: '30vw'}} >
                            <form name="form-detail" onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-8 offset-2'>
                                        <div className='form-group'>
                                            <label>No KBT</label>
                                            <Dropdown value={summaryId} options={summaries} 
                                                onChange={(e) => setSummaryId(e.target.value)} 
                                                optionValue="id" optionLabel="docnumber" filter filterBy="docnumber" style={{width: '100%', height: '37px'}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className='form-group'>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                                <div style={{ width: 150 }}><button type="submit" className="btn btn-block btn-outline-dark"><i className="fas fa-eye"></i> View</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Dialog>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default QualityIssueScreen;