import React, { Component, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPlanProdById, updatePlanProd, updatePlanProdDetail, createPlanProdDetail, deletePlanProdDetail } from '../../Service/PlanProdService';
import Swal from 'sweetalert2';
import { getMetaValueAll } from '../../Service/MetaValueService';
import '../../../src/App.css';
import Select from 'react-select';
import { set } from 'react-hook-form';
import { useSelector } from 'react-redux';
        
const { $ } = window;
const localState = {};

const PlanProdEditScreen = (props) => {
    const navigate = useNavigate();
    let { userInfo } = useSelector(state => state.auth);
    const { id } = useParams();
    const [ LSBCData, setLSBCData ] = useState({
        id: 0,
        docnumber: null,
        docdate: null,
        docsource: null,
        doctype: null,
        grade: null,
        lotorder: null,
        itemcode: null,
        itemname: null,
        itemdesc: null,
        serialno: null,
        whfrom: null,
        inspdate: null,
        inspdocnum: null,
        inspgrade: null,
        inspwh: null,
        inspstatus: null,
        created_by: null,
        created_on: null,
        updated_by: null,
        updated_on: null,
        ipaddress: null,
        data: []
    });

    const [ planProdData, setPlanProdData ] = useState({
        id: 1,
        productiondate: null,
        ponumber: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        itemmodel: "",
        lotorder: "",
        transferqty: "",
        assytype: "",
        planpriority: "",
        statusqc: "",
        destinationwhs: "",
        statusititr: "",
        companycode: "",
        st_ave: 0,
        operator: 0,
        target: 0,
        prodqty: 0,
        prodgroup: "",
        processmin: 0,
        prepmin: 0,
        totprocessmin: 0,
        statusapproval: "",
        itemstock: {},
        openQty: 0,
    });

    const [planDetail, setPlanDetail] = useState([
        {
            id: 1,
            planpriority: '',
            productiondate: '',
            planprodqty: '',
            prodgroup: '',
            operator: '',
            target: '',
            processmin: '',
            prepmin: '',
            totprocessmin: '',
            statusapprove: '',
            statusprodsap: '',
            actualprodqty: '',
          }
     ]);
    
    const [add, setAdd] = useState(false);

    const [selectedOption, setSelectedOption] = useState([]);

    const [selectedOptionDetail, setSelectedOptionDetail] = useState([]);
    
    const [ defectName, setDefectName ] = useState([{
        meta_value: null,
        meta_description: null,
    }]);
    const [ defectLocation, setDefectLocation ] = useState([{
        meta_value: null,
        meta_description: null,
    }]);
    const [ grade, setGrade ] = useState([{
        meta_value: null,
        meta_description: null,
    }]);

    const onGoback = () => {
        navigate(-1);
    }

    const getPlanProdData = (id) => {
        getPlanProdById(id).then(res => {
            setPlanProdData(res.data);
        });
    }

    const getPlanProdDataDetail = (id) => {
        getPlanProdById(id).then(res => {
            setPlanDetail(res.data.plandetail);
        });
    }


    const addRow = () => {
        setAdd(true);
        document.getElementById('add-row-btn').disabled = true;
        const newRow = {
          id: planDetail.length + 1,
          planpriority: '',
          productiondate: '',
          planprodqty: 0,
          prodgroup: '',
          operator: 0,
          target: 0,
          processmin: 0,
          prepmin: 0,
          totprocessmin: 0,
          statusapprove: '',
          statusprodsap: '',
          actualprodqty: 0,
          id_planprod: id
        };
        setPlanDetail([...planDetail, newRow]);
      };

    const shouldHideAddRowButton = () => {
        const openQty = planProdData.openQty; 
        if (planDetail.length === 0) return false; // Return false if no rows exist
        if (openQty < 0 || openQty === 0) return true; //this will hide button if openQty is 0
        const lastRow = planDetail[planDetail.length - 1];
        return lastRow.statusapprove === 'ONPROGRESS' || lastRow.statusapprove === null;
    };

    const onSubmit = () => {
        const st_ave = planProdData.st_ave;
        let flag = true;
        const manipulated =  planDetail.map((e) => {
        const newObj = { ...e }; // Create a new object to avoid mutating the original
        delete newObj['selectedOptionDetail']; // Delete the specified key from the new object
        return newObj;
        })

    const filteredPlanDetail = manipulated.find(item => item.statusapprove !== 'ONPROGRESS' && item.statusapprove !== 'COMPLETED');
    if(filteredPlanDetail === undefined){
        flag = false;
            Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Please click add row to insert new data!'
        });
    }

    if (st_ave === undefined || st_ave === null || st_ave === 0) {
        flag = false;
    }

        if(flag && add){
            filteredPlanDetail.name = userInfo && userInfo.fullname;
            const response = createPlanProdDetail(filteredPlanDetail);
            response.then(res => {
                if (res.status == 200 || res.status == 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Save data success',
                        text: 'Data has been saved!'
                    });

                    navigate("/planprod");
                }
            }).catch(({response: { data } }) => {
                if(data.status == 422){
                    const obj = Object.keys(data.errors);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.errors[obj[0]][0]
                    });
                }else
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Save data error!'
                    });
            });
        } else {
                filteredPlanDetail.name = userInfo && userInfo.fullname;
                const response = updatePlanProdDetail(filteredPlanDetail.id, filteredPlanDetail);
                response.then(res => {
                    if (res.status == 200 || res.status == 201) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Save data success',
                            text: 'Data has been updated!'
                        });
    
                        navigate("/planprod");
                    }
                }).catch(({response: { data } }) => {
                    if(data.status == 422){
                        const obj = Object.keys(data.errors);
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: data.errors[obj[0]][0]
                        });
                    }else
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Save data error please fill ST AVE before insert row!'
                        });
                });
        }
    }

    const getMetaValue = () => {
        getMetaValueAll({"filter":"meta_function:defect_type", "perpage": 1000}).then(res => {
            setDefectName(res.data.data);
        });
        getMetaValueAll({"filter":"meta_function:defect_area", "perpage": 1000}).then(res => {
            setDefectLocation(res.data.data);
        });
        getMetaValueAll({"filter":"meta_function:grade:", "perpage": 1000}).then(res => {
            setGrade(res.data.data);
        });
    }


    const formattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    
    const removeData = id => {
        deletePlanProdDetail(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { 
                    const updatedPlanDetail = planDetail.filter(item => item.id !== id);
                    setPlanDetail(updatedPlanDetail); 
                });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

      const deleteRow = (id) => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(id);
            }
        });
      };

    const calculateClosedTotal = () => {
        return planDetail.reduce((total, row) => {
            return row.statusprodsap === 'Closed' ? total + Number(row.actualprodqty) : total;
        }, 0);
    };

      const handleRowChange = (id, value, key, type = null) => {
        const prodQty = planProdData.prodqty;
        const totalClosed = calculateClosedTotal();
        const realQty = prodQty - totalClosed;
        const stAveNew = planProdData.st_ave;
        const openQty = planProdData.openQty;
        const stAv= planProdData.st_ave;
        const numericValue = parseInt(value, 10);
        const validatedValue = isNaN(numericValue) ? "" : Math.max(0, Math.min(numericValue, prodQty));
        const updatedRows = planDetail.map(row => {
          if (row.id === id) {
            if(type === 'date'){
                return { ...row, [key]: formattedDate(value) };
            }

            if (key === 'planprodqty') {
                if(Number(value) > openQty){
                    return row;
                }
            }

            // coba baru
            let planprodqtyValue = row.planprodqty;
            let operatorValue = row.operator;
            let prepminValue = row.prepmin || 30
            if (key === 'planprodqty' || key === 'operator') {
                planprodqtyValue = key === 'planprodqty' ? parseInt(value, 10) : planprodqtyValue;
                operatorValue = key === 'operator' ? parseInt(value, 10) : operatorValue;

                let processminValue = 0;
                if (planprodqtyValue > 0 && operatorValue > 0) {
                    processminValue = Math.floor((stAveNew / operatorValue) * planprodqtyValue);
                }

                let targetValue = Math.floor((60 * 8) / (stAveNew / operatorValue)); // Calculate target based on operator and stAveNew

                return {
                    ...row,
                    [key]: value,
                    target: targetValue,
                    processmin: processminValue,
                    totprocessmin: processminValue + prepminValue
                    // totprocessmin: processminValue + (row.prepmin || 0)
                };
            }

            if (key === 'prepmin') {
                const prepminValue = parseInt(value, 10);
                const totprocessminValue = (row.processmin || 0) + prepminValue;

                return {
                    ...row,
                    [key]: value,
                    totprocessmin: totprocessminValue
                };
            }

            return { ...row, [key]: value };
          }
          return row;
        });
        setPlanDetail(updatedRows);
      };

      const handlePriorityChange = (id, value,) => {
        console.log('ini id', id);
        console.log('ini id', value);
        const updatedRows = planDetail.map(row => {
          if (row.id === id) {
            return { ...row, priority: value };
          }
          return row;
        });
        setPlanDetail(updatedRows);
      };
    
    useEffect(() => {
        getPlanProdData(id);
        getPlanProdDataDetail(id)
        getMetaValue();
    }, []);
    
    const options = [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' },
        { value: 'F', label: 'F' },
        { value: 'G', label: 'G' },
      ];

     const handleChange = (selectOption) => {
    console.log('ini selected option', selectOption);
            setSelectedOption(selectOption);
            const prodgroup = selectOption.map(a => a.value).join(",");
            setPlanProdData({...planProdData, prodgroup });
            const newSelectedOption = prodgroup.split(",").map(value => {
                return options.find(option => option.value === value);
            });
            setSelectedOption(newSelectedOption);
     };

     const handleChangePlanDetail = (selectOption, id) => {
        console.log('ini selected option', selectOption);
        setSelectedOptionDetail(selectOption);
        const group = selectOption.map(a => a.value).join(",");
        setPlanDetail(prevDetails => prevDetails.map(detail => {
            if (detail.id === id) {  // Assuming each detail has a unique `id`
                return {
                    ...detail,
                    prodgroup: group,
                    // selectedOptionDetail: group
                };
            }
            return detail;
        }));
        const newSelectedOption = group.split(",").map(value => {
            return options.find(option => option.value === value);
        });
        setSelectedOptionDetail(newSelectedOption);
 };
     const calculateTarget = (e) => {
        if(e){
            setPlanProdData({...planProdData, target : e});
        }
        
     }


    useEffect(() => {
        if (planProdData.prodgroup) {
            const valueArray = planProdData.prodgroup.split(",");
            const newSelectedOption = valueArray.map(value => {
                return options.find(option => option.value === value);
            });
            setSelectedOption(newSelectedOption);
        }
    }, [planProdData.prodgroup]);

    useEffect(() => {
        // Map over planDetail to potentially update each item's selectedOptionDetail
        const updatedPlanDetails = planDetail.map(detail => {
            if (detail.prodgroup) {
                const valueArray = detail.prodgroup.split(",");
                const newSelectedOptions = valueArray.map(value => {
                    return options.find(option => option.value === value);
                });
    
                // Check if there's a need to update selectedOptionDetail based on a deep comparison
                if (!detail.selectedOptionDetail || 
                    newSelectedOptions.length !== detail.selectedOptionDetail.length ||
                    newSelectedOptions.some((option, index) => !option || option.value !== detail.selectedOptionDetail[index]?.value)) {
                    return { ...detail, selectedOptionDetail: newSelectedOptions };
                }
            }
            return detail;
        });
    
        // Only update state if there's an actual change in any detail's selectedOptionDetail
        if (updatedPlanDetails.some((detail, index) => detail.selectedOptionDetail !== planDetail[index].selectedOptionDetail)) {
            setPlanDetail(updatedPlanDetails);
        }
    }, [planDetail, options]); // Dependency array includes both planDetail and options to cover all cases of updates
    

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Detail Planning Production MFI</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Planning Production</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> View Planning Production</div>
                        </div>
                        <div className='card-body' style={{ overflowX : "auto" }}>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Status QC</label>
                                        <input type="text" className='form-control' value={planProdData.statusqc} readOnly />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Lot Order</label>
                                        <input type="text" className='form-control' value={planProdData.lotorder} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Item Description</label>
                                        <input type="text" className='form-control' value={planProdData.itemdesc} readOnly />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Item Code</label>
                                        <input type="text" className='form-control' value={planProdData.itemcode} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Destination Warehouse</label>
                                        <input type="text" className='form-control' value={planProdData.destinationwhs} readOnly />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Model</label>
                                        <input type="text" className='form-control' value={planProdData.itemname} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Warehouse Stock</label>
                                        <input type="text" className='form-control' value={planProdData.itemstock == 0 ? 0 :planProdData.itemstock.instock} readOnly />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">KBT QTY</label>
                                        <input type="text" className='form-control' value={planProdData.prodqty} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Open QTY</label>
                                        <input type="text" className='form-control' value={planProdData.openQty} readOnly />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname" className='requiredd'>ST AVE</label>
                                        <input type="number" className='form-control' value={planProdData.st_ave} readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='container-fluid'> {/* Using container-fluid for full width */}
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <div className="table-responsive"> {/* Making table scrollable on small devices */}
                                            <table className="table table-striped">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Planning Priority</th>
                                                        <th>Production Date</th>
                                                        <th>Plan Production Quantity</th>
                                                        <th>Group</th>
                                                        <th>Operator</th>
                                                        <th>Target / Unit</th>
                                                        <th>Process Time / Min</th>
                                                        <th>Preparation Time / Min</th>
                                                        <th>Total Process Time / Min</th>
                                                        <th>Status Approval</th>
                                                        <th>Status Production SAP</th>
                                                        <th>Actual Production Quantity</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {planDetail.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <select style={{ width: '150px' }}
                                                            value={row.planpriority}
                                                            onChange={(e) => handleRowChange(row.id, e.target.value, 'planpriority')}
                                                            // onChange={(e) => setPlanDetail({...planDetail, priority: e.target.value})}
                                                            className="form-control"
                                                            disabled={row.statusapprove === 'COMPLETED'}
                                                        >
                                                            <option value="">Select Priority</option>
                                                            <option value="Urgent">Urgent</option>
                                                            <option value="Recommended">Recommended</option>
                                                        </select>
                                                    </td>
                                                    <input style={{ textAlign: "center", width: "100%" }} type="date" className='form-control mt-2' value={formattedDate(row.productiondate)}
                                                    onChange={(e) => handleRowChange(row.id, e.target.value, 'productiondate', 'date')}
                                                    disabled={row.statusapprove === 'COMPLETED'}
                                                     />
                                                    <td>
                                                        <input min="0" style={{ width : '80%', textAlign: "center" }} type='number' value={row.planprodqty}
                                                        onChange={(e) => handleRowChange(row.id, e.target.value, 'planprodqty')}
                                                        disabled={row.statusapprove === 'COMPLETED'}
                                                        ></input>
                                                    </td>
                                                    <Select
                                                        key={row.id}
                                                        isMulti
                                                        name="colors"
                                                        options={options}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        onChange={(selectedOptions) => handleChangePlanDetail(selectedOptions, row.id)}
                                                        value={row.selectedOptionDetail}
                                                    />
                                                    <td>
                                                        <input min="0" style={{ width : '80%', textAlign: "center" }} type='number' value={row.operator} onChange={(e) => handleRowChange(row.id, e.target.value, 'operator')} disabled={row.statusapprove === 'COMPLETED'}></input>
                                                    </td>
                                                    <td>
                                                        <input style={{ width: '100%', textAlign: "center" }} type='number' value={row.target} onChange={(e) => handleRowChange(row.id, e.target.value, 'target')} disabled></input>
                                                    </td>
                                                    <td>
                                                        <input style={{ width: '100%', textAlign: "center"}} type='number' value={row.processmin} onChange={(e) => handleRowChange(row.id, e.target.value, 'processmin')} disabled></input>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <input min="0" style={{ width: '50%', textAlign: "center"}} type='number' value={row.prepmin || 30} onChange={(e) => handleRowChange(row.id, e.target.value, 'prepmin')} disabled={row.statusapprove === 'COMPLETED'}></input>
                                                    </td>
                                                    <td>
                                                        <input style={{ width: '100%', textAlign: "center"}} type='number' value={row.totprocessmin} onChange={(e) => handleRowChange(row.id, e.target.value, 'totprocessmin')} disabled></input>
                                                    </td>
                                                    <td>
                                                        <input style={{ width: '120px', textAlign: "center"}} type='text' value={row.statusapprove} readOnly disabled></input>
                                                    </td>
                                                    <td>
                                                        <input style={{ width: '100%', textAlign: "center"}} type='text' value={row.statusprodsap }readOnly disabled></input>
                                                    </td>
                                                    <td>
                                                        <input style={{ width: '100%', textAlign: "center"}} type='text' value={row.actualprodqty} readOnly disabled></input>
                                                    </td>
                                                    <td>
                                                        {row.statusapprove !== 'COMPLETED' && row.statusapprove !== 'ONPROGRESS' && (
                                                            <button onClick={() => deleteRow(row.id)} className="btn btn-danger">
                                                                Delete
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {!shouldHideAddRowButton() && (
                                            <button onClick={addRow} id="add-row-btn" className="btn btn-primary mt-2">Add Row</button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                    <div className='col-12 mt-2'>
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                                <button type='submit' className='btn btn-dark' style={{ width: 150 }} onClick={() => onSubmit()}><i className='fa fa-save' /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default PlanProdEditScreen;