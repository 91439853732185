import { updateObject } from "../../Utils/Utils";
import { ACTION_LOGOUT, AUTH_SUCCESS } from "../Action/AuthAction";

const init = {
    isLoggedIn: false,
    userInfo: {}
}

const AuthReducer = (state = init, action) => {
    switch (action.type) {
        case AUTH_SUCCESS:
            const {userInfo} = action;
            return updateObject(state, {
                isLoggedIn: true,
                userInfo
            });
        case ACTION_LOGOUT: 
            return { ...state, isLoggedIn: false, userInfo: {} }
        default:
            return state;
    }
}

export default AuthReducer;