import Switch from '@mui/material/Switch';
import React, { useState, useRef, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Overlay from '../../Components/Overlay/Overlay';
import { getRoleAccessAll } from '../../Service/RoleAccessService';
import { createUser, getUserById, updateUser } from '../../Service/UserService';

const { $ } = window;
const randomPassword = parseInt(Math.random() * 1000000);
let procesingId = -1;

const UserDetailScreen = () => {
    const { pageState, username } = useParams();
    const [state, setState] = useState({
        user: { roles_id: -1 },
        roles: [],
        processing: false
    });

    const { register, handleSubmit, watch, control, setValue, formState: { errors }, reset } = useForm({ defaultValues: { roles_id: -1 } });
    const roles_id = watch('roles_id');
    const [isDisabled, setIsDisabled] = useState(false);
    const password = useRef({});
    password.current = watch("password", "");

    useEffect(() => {

        const requests = [getRoleAccessAll({ perpage: 100 }).then(res => res.data.data)];
        switch (pageState) {
            case 'add':
                Promise.all(requests).then(results => {
                    const [_roles] = results;
                    setState({ ...state, roles: _roles, user: {} });
                });
                break;
            case 'edit':
            case 'view':
                requests.push(
                    getUserById(username)
                        .then(res => res.data)
                        .catch(({ response: { data: { message } } }) => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error when getting user',
                                text: message
                            }).then(() => { navigate(-1) });
                        })
                );
                Promise.all(requests).then(results => {
                    const [_roles, _user] = results;

                     // Tambahkan logika untuk memeriksa vendor_code
                    if (_user.vendor_code === "CJ") {
                        setIsDisabled(true);  // Disable field jika vendor_code adalah CJ
                    } else {
                        setIsDisabled(false); // Enable field jika vendor_code bukan CJ
                    }
                    _user.image = _user.image_name;
                    _user.password = randomPassword;
                    _user.retypePassword = randomPassword;
                    setState({ ...state, roles: _roles, user: _user });
                    reset(_user);
                });
                break;
            default:
                break;
        }

    }, []);

    const navigate = useNavigate();

    const onBack = () => { navigate(-1); }

    const onSelectImage = () => {
        $('#user-image').click();
    }

    const onuserImageChange = e => {
        const [file] = e.target.files;
        if (file) {
            const _user = { ...user, image: URL.createObjectURL(file), file };
            setState({ ...state, user: _user });
        }
    }

    const startProcessing = () => {
        procesingId = setTimeout(() => {
            setState({ ...state, processing: true });
        }, 150);
    }

    const stopProcessing = () => {
        clearInterval(procesingId);
        setState({ ...state, processing: false });
    }

    const onSubmit = (data) => {
        // data.image = user.file;
        const formData = new FormData();
        for (var key in data) {
            if (key == 'vendor_code' && roles_id == '3') {
                formData.append(key, data[key]);
            } else if (key != 'vendor_code') {
                formData.append(key, data[key]);
            }
        }
        // formData.delete("retypePassword");
        // if (pageState.toLowerCase() == 'edit' && data.password == randomPassword) {
        //     formData.delete("password");
        // }
        startProcessing();
        const response = pageState.toLowerCase() == 'add' ? createUser(formData) : updateUser(username, formData);
        response.then(res => {
            stopProcessing();
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Save data success',
                    text: 'Data has been saved!'
                }).then(r => { onBack(); })
            }
        }).catch(({ response: { data } }) => {
            stopProcessing();
            const [key] = Object.keys(data.errors || {});
            const message = data.errors[key];
            if (message) {
                console.log('error data', data);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: message[0]
                });
            }
        });
    }

    const { user, roles, processing } = state;

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage User</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">User</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-12 '>
                            <div className='card'>
                                <Overlay display={processing} />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='card-header'>
                                        <div className='card-title'><i className='fa fa-user-tag' /> {pageState && pageState.charAt(0).toUpperCase() + pageState.slice(1)} User </div>
                                    </div>
                                    <div className='card-body'>
                                        {/* <div className='row mt-3 mb-3'>
                                            <div className='col-md-9 d-flex flex-column align-items-center'>
                                                <div className='mb-4' >
                                                    <img src={user.image || "../../images/pp_default.jpg"} alt='A' className='img-circle elevation-2' style={{ width: 160, height: 160 }} />
                                                </div>
                                                <div className='mb-3'>
                                                    <button type='button' className='btn btn-outline-dark' onClick={onSelectImage}>
                                                        <i className='fa fa-image' /> Select Image
                                                    </button>
                                                    <input id="user-image" type="file" accept="image/png, image/jpg, image/jpeg"
                                                        {...register("image", { required: { value: pageState.toLowerCase() == 'add', message: 'Image is required' } })} className='d-none' onChange={onuserImageChange} />
                                                    {errors.image && <span className='text-danger d-block'>{errors.image.message}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-4'>
                                            <div className='col-md-9'>
                                                <div className="dropdown-divider" />
                                            </div>
                                        </div> */}
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='form-group'>
                                                    <label htmlFor='email'>Email</label>
                                                    <input id="email" disabled={isDisabled} {...register("email", { email: { value: true, message: 'Invalid email!' }, required: 'Email is required!' })} className='form-control' type="email" />
                                                    {errors.email && <span className='text-danger'>{errors.email.message}</span>}
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='fullname'>Full Name</label>
                                                    <input id="fullname" disabled={isDisabled} {...register("full_name", { required: 'Fullname is required!' })} className='form-control' />
                                                    {errors.full_name && <span className='text-danger'>{errors.full_name.message}</span>}
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='phone'>Phone</label>
                                                    <input id="phone" disabled={isDisabled} {...register("phone")} className='form-control' />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='active'>Active</label>
                                                    <div>
                                                        <Controller name="is_active" control={control} render={({ field }) => { return (<Switch color="warning" {...field} checked={field.checked} />) }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-2' />
                                            <div className='col-md-5'>
                                                <div className='form-group'>
                                                    <label htmlFor='username'>User Name</label>
                                                    <input id="username" disabled={isDisabled} {...register("username", { required: 'Username is required!' })} className='form-control' />
                                                    {errors.username && <span className='text-danger'>{errors.username.message}</span>}
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='nik'>NIK</label>
                                                    <input id="nik" {...register("nik", { 
                                                        validate: value => roles_id == '3' || (value && value.length > 0) || 'NIK is required!', 
                                                        pattern: {value: /[0-9][0-9][0-9][0-9].[0-9][0-9][0-9][0-9]$/, 
                                                        message: 'Please enter a valid NIK (----.----)'} })} className='form-control' disabled={roles_id == '3'} />
                                                    {errors.nik && <span className='text-danger'>{errors.nik.message}</span>}
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='role'>Role</label>
                                                    <select id="role" className='form-control' disabled={isDisabled} {...register("roles_id", { required: 'Role is required!', validate: val => val >= 1 || 'Role is required!', onChange: (e) => {if(e.target.value == '3') setValue('nik', '')} })}>
                                                        <option value="-1" >Select Role</option>
                                                        {roles.map((item, i) => <option key={`item-role-${i}`} value={item.id} selected={user.roles_id == item.id ? true : false}>{item.roles_name}</option>)}
                                                    </select>
                                                    {errors.roles_id && <span className='text-danger'>{errors.roles_id.message}</span>}
                                                </div>
                                                {roles_id === '3' && (
                                                    <div className='form-group'>
                                                        <label htmlFor='vendor_code'>Vendor Code</label>
                                                        <input 
                                                            id="vendor_code" 
                                                            {...register("vendor_code", {
                                                                required: 'Vendor Code is required!',
                                                                validate: value => 
                                                                    roles_id !== '3' || (value && value.length > 0) || 'Vendor Code is required!'
                                                            })} 
                                                            className='form-control' 
                                                        />
                                                        {errors.vendor_code && <span className='text-danger'>{errors.vendor_code.message}</span>}
                                                        {errors.roles_id && <span className='text-danger'>{errors.roles_id.message}</span>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-footer'>
                                        <div className='row'>
                                            <div className='col-md-9'>
                                                <div className='d-flex' style={{ justifyContent: 'flex-end' }}>
                                                    <button type='button' className='btn btn-outline-dark mr-3' style={{ width: 100 }} onClick={onBack}><i className='fa fa-reply' /> Back</button>
                                                    <button type='submit' className='btn btn-dark' style={{ width: 100 }}><i className='fa fa-save' /> Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default UserDetailScreen;