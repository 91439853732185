import React, { Component, useEffect, useState } from 'react';
import { Uploader, Loader } from 'rsuite';
import ImageIcon from '@rsuite/icons/Image';
import { previewFile } from '../../Function/Function';
import { useNavigate, useParams } from 'react-router-dom';
import { getLSBCById, updateLSBC } from '../../Service/LSBCService';
import { getPlanProdById, updatePlanProd } from '../../Service/PlanProdService';
import Swal from 'sweetalert2';
import { getMetaValueAll } from '../../Service/MetaValueService';
import '../../../src/App.css';
import Select from 'react-select';
import { set } from 'react-hook-form';
const { $ } = window;
const localState = {};

const PlanProdEditScreen = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [ LSBCData, setLSBCData ] = useState({
        id: 0,
        docnumber: null,
        docdate: null,
        docsource: null,
        doctype: null,
        grade: null,
        lotorder: null,
        itemcode: null,
        itemname: null,
        itemdesc: null,
        serialno: null,
        whfrom: null,
        inspdate: null,
        inspdocnum: null,
        inspgrade: null,
        inspwh: null,
        inspstatus: null,
        created_by: null,
        created_on: null,
        updated_by: null,
        updated_on: null,
        ipaddress: null,
        data: []
    });

    const [ planProdData, setPlanProdData ] = useState({
        id: 1,
        productiondate: null,
        ponumber: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        itemmodel: "",
        lotorder: "",
        transferqty: "",
        assytype: "",
        planpriority: "",
        statusqc: "",
        destinationwhs: "",
        statusititr: "",
        companycode: "",
        st_ave: 0,
        operator: 0,
        target: 0,
        prodqty: 0,
        prodgroup: "",
        processmin: 0,
        prepmin: 0,
        totprocessmin: 0,
        statusapproval: "",
        itemstock: {}
    });
    
    const [selectedOption, setSelectedOption] = useState([]);
    
    const [ defectName, setDefectName ] = useState([{
        meta_value: null,
        meta_description: null,
    }]);
    const [ defectLocation, setDefectLocation ] = useState([{
        meta_value: null,
        meta_description: null,
    }]);
    const [ grade, setGrade ] = useState([{
        meta_value: null,
        meta_description: null,
    }]);

    const onGoback = () => {
        navigate(-1);
    }

    const getPlanProdData = (id) => {
        getPlanProdById(id).then(res => {
            setPlanProdData(res.data);
        });
    }

    const addRowDetail = () => {
        let array = LSBCData.data.slice();
        array.push({
            defsource: "",
            defname: "",
            deflocation: "",
            defnotes: "",
            defcorrection: "",
        });
        const newObj = { ...LSBCData, data: array };
        setLSBCData(newObj);
    }

    const updateItemDetail = (index, key, value) => {
        let array = LSBCData.data.slice();
        array[index][key] = value;
        const newObj = { ...LSBCData, data: array };
        setLSBCData(newObj);
    }

    const removeRowDetail = index => {
        const temp = [...LSBCData.data];
        
        temp.splice(index, 1);
        setLSBCData({...LSBCData, data: temp});
    }

    const onSubmit = () => {
        var flag = true;
        for (var key in planProdData.data) {
            if(planProdData.data[key].itemcode == "" || planProdData.data[key].st_ave == "" || planProdData.data[key].prodgroup == (selectedOption.length < 0) || planProdData.data[key].operator == "" || 
                planProdData.data[key].prepmin == "" || planProdData.data[key].processmin == ""){
                flag = false;
            }
        }
        if(flag) {
            const response = updatePlanProd(id, planProdData);
            response.then(res => {
                if (res.status == 200 || res.status == 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Save data success',
                        text: 'Data has been saved!'
                    });

                    navigate("/planprod");
                }
            }).catch(({response: { data } }) => {
                if(data.status == 422){
                    const obj = Object.keys(data.errors);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.errors[obj[0]][0]
                    });
                }else
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Save data error!'
                    });
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill all defect data!'
            });
        }
    }

    const getMetaValue = () => {
        getMetaValueAll({"filter":"meta_function:defect_type", "perpage": 1000}).then(res => {
            setDefectName(res.data.data);
        });
        getMetaValueAll({"filter":"meta_function:defect_area", "perpage": 1000}).then(res => {
            setDefectLocation(res.data.data);
        });
        getMetaValueAll({"filter":"meta_function:grade:", "perpage": 1000}).then(res => {
            setGrade(res.data.data);
        });
    }
    
    useEffect(() => {
        getPlanProdData(id);
        getMetaValue();
    }, []);
    
    const options = [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' },
        { value: 'F', label: 'F' },
        { value: 'G', label: 'G' },
      ];
     const handleChange = (selectOption) => {
            setSelectedOption(selectOption);
            const prodgroup = selectOption.map(a => a.value).join(",");
            setPlanProdData({...planProdData, prodgroup });
            const newSelectedOption = prodgroup.split(",").map(value => {
                return options.find(option => option.value === value);
            });
            setSelectedOption(newSelectedOption);
     };

     const calculateTarget = (e) => {
        if(e){
            setPlanProdData({...planProdData, target : e});
        }
        
     }


    useEffect(() => {
        if (planProdData.prodgroup) {
            const valueArray = planProdData.prodgroup.split(",");
            const newSelectedOption = valueArray.map(value => {
                return options.find(option => option.value === value);
            });
            setSelectedOption(newSelectedOption);
        }
    }, [planProdData.prodgroup]);

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Detail Planning Production</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Planning Production</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> View Planning Production</div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname" className='requiredd'>Production Date</label>
                                        <input type="date" className='form-control' value={planProdData.productiondate} onChange={(e) => setPlanProdData({...planProdData, productiondate: e.target.value})} />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Lot Order</label>
                                        <input type="text" className='form-control' value={planProdData.lotorder} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Status QC</label>
                                        <input type="text" className='form-control' value={planProdData.statusqc} readOnly />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Destination Warehouse</label>
                                        <input type="text" className='form-control' value={planProdData.destinationwhs} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Planning Priority</label>
                                        {/* <input type="text" className='form-control' value={planProdData.planpriority} readOnly /> */}
                                        <select className='form-control' value={planProdData.planpriority} onChange={(e) => setPlanProdData({...planProdData, planpriority: e.target.value})}>
                                            <option value='' disabled>-- Select Priority --</option>
                                            <option value='Recommended'>Recommended by System</option>  
                                            <option value='Urgent'>Urgent</option>
                                        </select>  
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Item Code</label>
                                        <input type="text" className='form-control' value={planProdData.itemcode} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Item Description</label>
                                        <input type="text" className='form-control' value={planProdData.itemdesc} readOnly />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Model</label>
                                        <input type="text" className='form-control' value={planProdData.itemname} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Warehouse Stock</label>
                                        <input type="text" className='form-control' value={planProdData.itemstock== 0 ? 0 :planProdData.itemstock.instock} readOnly />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label className='requiredd' htmlFor="target">Target</label>
                                        <input type="number" step="any" className='form-control' 
                                        // value={planProdData.target ? planProdData.target : ""}
                                        value={planProdData.target === Infinity ? 0 : (planProdData.target ? planProdData.target : "")} 
                                        onChange={(e) => setPlanProdData({...planProdData, target: e.target.value})} readOnly/>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Production QTY</label>
                                        <input type="text" className='form-control' value={planProdData.prodqty} readOnly />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname" className='requiredd'>ST AVE</label>
                                        <input type="number" className='form-control' value={planProdData.st_ave} disabled
                                        // onChange={(e) => {
                                        //     const newStAve = e.target.value;
                                        //     const newTarget = (8 * 60) / (parseFloat(newStAve) / parseFloat(planProdData.operator));
                                        //     console.log('newTarget', newTarget);
                                        //     setPlanProdData({...planProdData, st_ave: newStAve, target: Math.floor(newTarget)});
                                        // }} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname" className='requiredd'>Group </label>
                                        {/* <input type="text" className='form-control' value={planProdData.prodgroup} readOnly /> */}
                                        <Select
                                                isMulti
                                                name="colors"
                                                options={options}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={handleChange}
                                                value={selectedOption}
                                            />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname" className='requiredd'>Operator</label>
                                        <input type="number" className='form-control' value={planProdData.operator} 
                                        onChange={(e) => {
                                            const newOperator = e.target.value;
                                            const newTarget = (8 * 60) / (parseFloat(planProdData.st_ave) / parseFloat(newOperator));
                                            setPlanProdData({...planProdData, operator: newOperator, target: Math.floor(newTarget)});
                                        }}  />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname" className='requiredd'>Process Time / Min</label>
                                        <input type="number" className='form-control' value={planProdData.processmin} onChange={(e) => {
                                            const newProcMin = e.target.value;
                                            setPlanProdData({
                                                ...planProdData, 
                                                processmin: newProcMin, 
                                                totprocessmin: (parseInt(newProcMin) + parseInt(planProdData.prepmin))
                                              })
                                        }} 
                                        />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname" className='requiredd'>Preparation / Min</label>
                                        <input type="number" className='form-control' value={planProdData.prepmin} onChange={(e) => {
                                            const newPrepMin = e.target.value;
                                            setPlanProdData({
                                                ...planProdData, 
                                                prepmin: newPrepMin, 
                                                totprocessmin: (parseInt(newPrepMin) + parseInt(planProdData.processmin))
                                              })
                                        }} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Total Process Time / Min</label>
                                        <input type="text" className='form-control' value={planProdData.prepmin && planProdData.processmin ? planProdData.totprocessmin : ""} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                    <div className='col-12 mt-2'>
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                                <button type='submit' className='btn btn-dark' style={{ width: 150 }} onClick={() => onSubmit()}><i className='fa fa-save' /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default PlanProdEditScreen;