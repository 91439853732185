import axios from 'axios';
import { handleResponse } from './HelperService';

const createITR = (id, pp_id, checking_type, payload, onSuccess, onError) => {
  const response = axios.post(`v1/admin/iqc-report/itr/${id}/${pp_id}/${checking_type}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const getPlanProdAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/planprod/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getPlanProdById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/planprod/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const updatePlanProd = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/planprod/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const updatePlanProdDetail = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/planprod/updateDetail/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const createPlanProdDetail = (payload, onSuccess, onError) => {
  const response = axios.post ('v1/admin/planprod/createplanProdDetail', payload);
  return handleResponse(response, onSuccess, onError);
};

const deletePlanProdDetail = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/planprod/deletePPD/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const changePlanProdStatus = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/planprod/change-status/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const changePlanProdDetailStatus = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/planprod/change-status-ppd/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};


export {getPlanProdAll, getPlanProdById, updatePlanProd, changePlanProdStatus, createITR, updatePlanProdDetail, createPlanProdDetail, deletePlanProdDetail, changePlanProdDetailStatus};
