import React from "react";

export const toastSuccess = (message) => {
    window.$.toast({
        text: `${message}`,
        showHideTransition: 'slide',
        bgColor: '#2ecc71',
        textColor: 'white',
        hideAfter: 2000, 
        allowToastClose: false,
        position: 'bottom-right'
    })
}
export const toastError = (message) => {
    window.$.toast({
        text: `${message}`,
        showHideTransition: 'slide',
        bgColor: '#e74c3c',
        textColor: 'white',
        hideAfter: 3000, 
        allowToastClose: false,
        position: 'bottom-right'
    })
}
export const toastWarning = (message) => {
    window.$.toast({
        text: `${message}`,
        showHideTransition: 'slide',
        bgColor: '#f1c40f',
        textColor: 'white',
        hideAfter: 2000, 
        allowToastClose: false,
        position: 'bottom-right'
    })
}
export const toastInfo = (message) => {
    window.$.toast({
        text: `${message}`,
        showHideTransition: 'slide',
        bgColor: '#3498db',
        textColor: 'white',
        hideAfter: 2000, 
        allowToastClose: false,
        position: 'bottom-right'
    })
}