import React, { Component, useEffect, useState } from 'react';
import { Uploader, Loader } from 'rsuite';
import ImageIcon from '@rsuite/icons/Image';
import { previewFile } from '../../Function/Function';
import { useNavigate, useParams } from 'react-router-dom';
import { getQualityIssueById, updateQualityIssue } from '../../Service/QualityIssueService';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { getSupplierList } from '../../Service/SapService';
const { $ } = window;
const localState = {};

const QualityIssueEditScreen = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [cardCodes, setCardCodes] = useState([]);
    const [ qualityIssueData, setQualityIssueData ] = useState({
        id: 0,
        docnumber: null,
        report_date: null,
        defect_date: null,
        defect_type: null,
        defect: null,
        defect_area: null,
        cause: null,
        serial_number: null,
        cardcode: null,
        cardname: null,
        lotorder: null,
        itemcode: null,
        location: null,
        model: null,
        defqty: 0,
        defrate: 0,
        status: 0,
        created_by: null,
        created_on: null,
        updated_by: null,
        updated_on: null,
        ipaddress: null,
        action: [],
        images: []
    });
    const [oldImage, setOldImage] = useState([
        {
            image_path: null,
            image_preview: null,
            image_name: null,
            image_id: null
        },
        {
            image_path: null,
            image_preview: null,
            image_name: null,
            image_id: null
        },
        {
            image_path: null,
            image_preview: null,
            image_name: null,
            image_id: null
        }
    ]);

    const onGoback = () => {
        navigate(-1);
    }

    const getQualityIssueData = (id) => {
        getQualityIssueById(id).then(res => {
            setQualityIssueData(res.data);
            res.data.images.map((image, key) => {
                oldImage[key] = {
                    image_path: null,
                    image_preview: image.file_url,
                    image_name: image.file_name,
                    image_id: image.id
                };
            });
        });
    }

    const addRowAction = () => {
        let array = qualityIssueData.action.slice();
        array.push({correction_action: ""});
        const newObj = { ...qualityIssueData, action: array };
        setQualityIssueData(newObj);
    }

    const updateItemAction = (index, value) => {
        let array = qualityIssueData.action.slice();
        array[index] = {correction_action: value};
        const newObj = { ...qualityIssueData, action: array };
        setQualityIssueData(newObj);
    }

    const onSubmit = data => {
        const formData = new FormData();
        var flag = true;
        for (var key in qualityIssueData) {
            if(key != "action" && key != "images") formData.append(key, qualityIssueData[key]);
        }
        for (var key in oldImage) {
            if(oldImage[key].image_path != null){
                formData.append("image_path["+key+"]", oldImage[key].image_path);
                formData.append("image_name["+key+"]", oldImage[key].image_name);
                formData.append("image_id["+key+"]", oldImage[key].image_id);
            }
        }
        for (var key in qualityIssueData.action) {
            if(qualityIssueData.action[key].correction_action != "") formData.append("action["+key+"]", qualityIssueData.action[key].correction_action);
            else flag = false;
        }
        var name = "";
        for (var key in cardCodes) {
            if(cardCodes[key].value == qualityIssueData.cardcode) {
                name = cardCodes[key].label;
                break;
            }
        }
        formData.append("cardname", name);
        if(flag) {
            const response = updateQualityIssue(formData);
            response.then(res => {
                if (res.status == 200 || res.status == 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Save data success',
                        text: 'Data has been saved!'
                    });

                    navigate("/quality-issue");
                }
            }).catch(({response: { data } }) => {
                if(data.status == 422){
                    const obj = Object.keys(data.errors);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.errors[obj[0]][0]
                    });
                }else
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Save data error!'
                    });
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill all action data!'
            });
        }
    }

    const getCardCode = () => {
        getSupplierList().then(res => {
            setCardCodes(res.data);
        });
    }
    
    useEffect(() => {
        getCardCode();
        getQualityIssueData(id);
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Quality Issue</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Quality Issue</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> View Quality Issue</div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td width={'20%'}>DOCUMENT NO.</td>
                                                    <td colSpan={3}><b>{qualityIssueData.docnumber}</b></td>
                                                </tr>
                                                <tr>
                                                    <td width={'20%'}>MODEL</td>
                                                    <td width={'30%'}>{qualityIssueData.model}</td>
                                                    <td width={'20%'}>CAUSE</td>
                                                    <td width={'30%'}><input className='form-control' type="text" value={qualityIssueData.cause !== null ? qualityIssueData.cause : ""} onChange={(e) => setQualityIssueData({...qualityIssueData, cause: e.target.value})} /></td>
                                                </tr>
                                                <tr>
                                                    <td>SUPPLIER</td>
                                                    <td>
                                                        <Dropdown value={qualityIssueData.cardcode} options={cardCodes} 
                                                            onChange={(e) => setQualityIssueData({...qualityIssueData, cardcode: e.target.value})}
                                                            optionLabel="label" filter filterBy="label" style={{width: '100%', height: '37px'}}/>
                                                    </td>
                                                    <td>ISSUE DATE</td>
                                                    <td>{qualityIssueData.defect_date}</td>
                                                </tr>
                                                <tr>
                                                    <td>LOT ORDER</td>
                                                    <td>{qualityIssueData.lotorder}</td>
                                                    <td>QTY DEFECT</td>
                                                    <td><input className='form-control' type="number" min="0" value={qualityIssueData.defqty} onChange={(e) => setQualityIssueData({...qualityIssueData, defqty: e.target.value})} /></td>
                                                </tr>
                                                <tr>
                                                    <td>REPORT DATE</td>
                                                    <td>{qualityIssueData.report_date}</td>
                                                    <td>NG RATE</td>
                                                    <td><input className='form-control' type="number" min="0.00" step={0.01} value={qualityIssueData.defrate} onChange={(e) => setQualityIssueData({...qualityIssueData, defrate: e.target.value})} /></td>
                                                </tr>
                                                <tr>
                                                    <td>LOCATION</td>
                                                    <td>{qualityIssueData.whfrom}</td>
                                                    <td>SERIAL NUMBER</td>
                                                    <td><input className='form-control' type="text" value={qualityIssueData.serial_number} onChange={(e) => setQualityIssueData({...qualityIssueData, serial_number: e.target.value})} /></td>
                                                </tr>
                                                <tr style={{height: '100px'}}>
                                                    <td>PROBLEM</td>
                                                    <td colSpan={3}><textarea className='form-control' rows={3} value={qualityIssueData.defect} onChange={(e) => setQualityIssueData({...qualityIssueData, defect: e.target.value})} /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} align="center">PICTURE</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <table className="table table-bordered">
                                                            <tbody>
                                                                <tr>
                                                                    <td width={'33%'}>
                                                                        <Uploader
                                                                            fileListVisible={false}
                                                                            listType="picture"
                                                                            onUpload={file => {
                                                                                previewFile(file.blobFile, value => {
                                                                                    const temp = [...oldImage];
                                                                                    temp[0] = {
                                                                                        image_path: file.blobFile,
                                                                                        image_preview: value,
                                                                                        image_name: temp[0].image_name,
                                                                                        image_id: temp[0].image_id
                                                                                    };
                                                                                    setOldImage(temp);
                                                                                });
                                                                            }}
                                                                        >
                                                                            <button style={{width: '100%', height: '100%', border: 'unset'}}>
                                                                                {oldImage[0].image_preview ? <img src={oldImage[0].image_preview} width={`100%`} height={`100%`} /> : <ImageIcon style={{ fontSize: 20 }} />}
                                                                            </button>
                                                                        </Uploader>
                                                                    </td>
                                                                    <td width={'33%'}>
                                                                        <Uploader
                                                                            fileListVisible={false}
                                                                            listType="picture"
                                                                            onUpload={file => {
                                                                                previewFile(file.blobFile, value => {
                                                                                    const temp = [...oldImage];
                                                                                    temp[1] = {
                                                                                        image_path: file.blobFile,
                                                                                        image_preview: value,
                                                                                        image_name: temp[1].image_name,
                                                                                        image_id: temp[1].image_id
                                                                                    };
                                                                                    setOldImage(temp);
                                                                                });
                                                                            }}
                                                                        >
                                                                            <button style={{width: '100%', height: '100%', border: 'unset'}}>
                                                                                {oldImage[1].image_preview ? <img src={oldImage[1].image_preview} width={`100%`} height={`100%`} /> : <ImageIcon style={{ fontSize: 20 }} />}
                                                                            </button>
                                                                        </Uploader>
                                                                    </td><td width={'33%'}>
                                                                        <Uploader
                                                                            fileListVisible={false}
                                                                            listType="picture"
                                                                            onUpload={file => {
                                                                                previewFile(file.blobFile, value => {
                                                                                    const temp = [...oldImage];
                                                                                    temp[2] = {
                                                                                        image_path: file.blobFile,
                                                                                        image_preview: value,
                                                                                        image_name: temp[2].image_name,
                                                                                        image_id: temp[2].image_id
                                                                                    };
                                                                                    setOldImage(temp);
                                                                                });
                                                                            }}
                                                                        >
                                                                            <button style={{width: '100%', height: '100%', border: 'unset'}}>
                                                                                {oldImage[2].image_preview ? <img src={oldImage[2].image_preview} width={`100%`} height={`100%`} /> : <ImageIcon style={{ fontSize: 20 }} />}
                                                                            </button>
                                                                        </Uploader>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} align="center">CORRECTIVE ACTION</td>
                                                </tr>
                                                {qualityIssueData.action.map((action, key) => (
                                                    <tr>
                                                        <td colSpan={4}>{key + 1}. <input className='form-control' type="text" style={{float: 'right', width: '97%'}} value={action.correction_action} onChange={(e) => updateItemAction(key, e.target.value)} /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={9}><button className='form-control' onClick={addRowAction}><i className="fa fa-plus" aria-hidden="true"></i></button></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    {/* <div className='form-group'>
                                        <div className='d-flex float-right'>
                                            <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='row'>
                                    <div className='col-12'>
                                        <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                                <button type='submit' className='btn btn-dark' style={{ width: 150 }} onClick={() => onSubmit()}><i className='fa fa-save' /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default QualityIssueEditScreen;