import axios from 'axios';
import { handleResponse } from './HelperService';

const getInspAqlAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/inspect-aql/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getInspAqlById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/inspect-aql/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createInspAql = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/inspect-aql/create`, payload);
  return handleResponse(response, onSuccess, onError);
};

const updateInspAql = (payload, onSuccess, onError) => {
  const id = payload.get('id');
  const response = axios.put (`v1/admin/inspect-aql/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteInspAql = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/inspect-aql/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getInspAqlAll, getInspAqlById, createInspAql, updateInspAql, deleteInspAql};
