import React from 'react';

const Modal = ({ children, id, title, onClose, onAction, btnActionName, btnActionDisabled = false, modalSize, isLoading = false }) => {
    return (
        <div className="modal fade" id={id.replace('#', '')} data-bs-backdrop="static" data-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className={`modal-dialog ${modalSize ? modalSize : ''}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onClose}> Close</button>
                        {
                            onAction && <button type="button" className="btn btn-warning" disabled={isLoading || btnActionDisabled} onClick={onAction}>{btnActionName}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;