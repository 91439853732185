import axios from 'axios';
import { handleResponse } from './HelperService';

const getMetaValueAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/metavalue/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getMetaValueById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/metavalue/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createMetaValue = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/metavalue/create`, payload);
  return handleResponse(response, onSuccess, onError);
};

const updateMetaValue = (payload, onSuccess, onError) => {
  const id = payload.get('id');
  const response = axios.put (`v1/admin/metavalue/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const changeStatusMetaValue = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/metavalue/delete/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteMetaValue = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/metavalue/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getMetaValueAll, getMetaValueById, createMetaValue, updateMetaValue, changeStatusMetaValue, deleteMetaValue};
