import React, { Component, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { useNavigate, useParams } from 'react-router-dom';
import { changeIpqcStatus, getIpqcById, updateIpqc } from '../../Service/IpqcService';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { useSelector } from 'react-redux';
import PageOverlay from '../../Components/Overlay/PageOverlay';
import { getMetaValueAll } from '../../Service/MetaValueService';

const IncomingDetailScreen = (props) => {
    const navigate = useNavigate();
    const { id, pageState } = useParams();
    let { userInfo } = useSelector(state => state.auth);
    const [processing, setProcessing] = useState(false);
    const [show, setShow] = useState(false);
    const [descriptions, setDescriptions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [grades, setGrades] = useState([]);
    const [comment, setComment] = useState("");
    const [action, setAction] = useState(1);
    const isCompany = localStorage.getItem('selectedCompany');
    const [ ipqcData, setIpqcData ] = useState({
        id: 0,
        id_iqc: 0,
        docnumber: null,
        inspdate: null,
        itemcode: null,
        itemname: null,
        itemdesc: null,
        lotorder: null,
        prodqty: 0,
        sampqty: 0,
        assyproc: null,
        insprule: null,
        stdcheck: null,
        docentry: 0,
        inspstatus: 0,
        created_by: null,
        created_on: null,
        updated_by: null,
        updated_on: null,
        ipaddress: null,
        status: null,
        review_on: null,
        review_by: null,
        approve_on: null,
        approve_by: null,
        reject_on: null,
        reject_by: null,
        ipqc_serial: [],
        ipqc_grade: [],
        incident_id: "",
        incident_status: ""
    });

    const onGoback = () => {
        navigate(-1);
    }

    const onFormSubmit = (data) => {
        data.updated_on = new Date();
        data.updated_by = userInfo && userInfo.fullname;
        updateIpqc(id, data).then(res => {
            if(res.data.status == 200){
                Swal.fire({
                    icon: 'success',
                    title: 'Data has been saved!',
                    text: 'Data has been saved!'
                });
                navigate(-1);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Data can not be saved!',
                    text: 'Data can not be saved!'
                });
            }
        }).catch(({response: { data } }) => {
            Swal.fire({
                icon: 'error',
                title: 'Data can not be saved!',
                text: 'Data can not be saved! (' + data.message + ')'
            });
        });
    }

    const judgementTemplate = (options) => {
        return <span>{options.judgement == 'OK' ? `OK` : `NG`}</span>
    }

    const headerTemplate = (data) => {
        return (
            <div style={{display: 'flex', background: '#f8f9fa'}}>
                <h6 style={{paddingLeft: '8px', marginTop: '8px'}}>{data.inspcategname}</h6>
            </div>
        );
    }

    const renderField = (row, column, index, type="text", values=[]) => {
        if(type == "text")
            return <input className="form-control" type={type} name={column.field} value={row[column.field]} onChange={(e) => onChangeSerialResultDetail(e, index, column.rowIndex)} required/>;
        else if(type == "select" && values.length> 0)
            return <select name={column.field} className="form-control" onChange={(e) => onChangeSerialResultDetail(e, index, column.rowIndex)} required>
                {values.map((value, index) => {
                    return <option value={value.value} selected={value.value == row[column.field]}>{value.label}</option>
                }
                    
                )}
            </select>
    }

    const renderGradeField = (row, column, type="text", values=[]) => {
        const isDisabled = values == "ONREVISED" ? true : false;
        if(type == "text")
            return <input className="form-control" minLength={1} type={type} name={column.field} value={row[column.field]} onChange={(e) => onChangeSerialGradeDetail(e, column.rowIndex)} required/>;
        else if(type == "select" && values.length> 0){
            let param = column.field;
            if(column.field == "description") param = "code";
            return <select name={param}  className="form-control" onChange={(e) => onChangeSerialGradeDetail(e, column.rowIndex)} required>
                {values.map((value, index) => {
                    return <option value={value.value} selected={value.value == row[param]}>{value.label}</option>
                }
                    
                )}
            </select>
        } else if(type == "number")
        return <input disabled={isDisabled} className="form-control" type={type} min={0} name={column.field} value={row[column.field]} onChange={(e) => onChangeSerialGradeDetail(e, column.rowIndex)} required/>;
    }

    let headerColumnGroup = <ColumnGroup>
        <Row>
            <Column field="no" header="No" alignHeader="center" style={{ width: '5%' }}/>
            <Column field="name" header="Item Pemeriksaan" alignHeader="center"/>
            <Column field="specification" header="Spesifikasi" alignHeader="center"/>
            <Column field="standard" header="Standard" alignHeader="center"/>
            <Column header="Judge" align="center" alignHeader="center" field="judgement" style={{ width: '10%', minWidth: '85px' }}/>
        </Row>
    </ColumnGroup>;

    const onChangeStatus = (e, id, docnumber, incident_id) => {
        e.preventDefault();
        const companycode = ipqcData.company_code;
        const vendorCode = ipqcData.vendor_code;
        setShow(false);
        if (companycode !== isCompany) {
            Swal.fire({
                icon: 'error',
                title: 'Process Cancelled',
                text: 'Please refresh page and reselect company MI or MFI!',
                confirmButtonText: 'OK'
            });
            return;
        }
        Swal.fire({
            title: action == 1 ? 'Are you sure want to approve data ?' : 'Are you sure want to revise data ?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setProcessing(true);
                const body = {
                    "incident_id": incident_id,
                    "action": action,
                    "name": userInfo && userInfo.fullname,
                    "nik" : userInfo && userInfo.nik,
                    "url" : window.location.href,
                    "docnumber": docnumber,
                    "comment": comment,
                    "role": userInfo && userInfo.role,
                    "vendor_code": vendorCode,
                    
                };
                changeIpqcStatus(id, body).then(res => {
                    setProcessing(false);
                    if (res.status == 200 || res.status == 201) {
                        Swal.fire({
                            icon: 'success',
                            title: action == 1 ? 'Approve data success !' : 'Reject data success !',
                            text: 'Data has been changed!'
                        });
                        
                        navigate("/inprocess");
                    }
                }).catch(({response: { data } }) => {
                    setProcessing(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Approval action fail',
                        text: 'Data can not be changed! (' + data.message + ')'
                    });
                });
            }
        });
    }

    const renderFooter = () => {
        return (
            <div>
                <button 
                    type='button' className='btn btn-danger' title='Cancel'
                    style={{ whiteSpace: 'nowrap' }} 
                    onClick={(e) => setShow(false)}>
                        <i className='fa fa-ban' /> Cancel
                </button>
                <button 
                    type='button' className='btn btn-primary' title='Submit'
                    style={{ marginRight: 10, whiteSpace: 'nowrap' }} 
                    onClick={(e) => onChangeStatus(e, ipqcData.id, ipqcData.docnumber, ipqcData.incident_id)}>
                        <i className='fa fa-check' /> Submit
                </button>
            </div>
        );
    }
    
    useEffect(() => {
        setProcessing(true);
        getIpqcById(id).then(res => {
            setIpqcData(res.data);
            setProcessing(false);
        });
        if(pageState == "edit"){
            getMetaValueAll({filter:"meta_function:deskripsi"}).then(res => {
                if(res.status == 200){
                    const description = [];
                    res.data.data.forEach((item) => {
                            description.push({value: item.meta_value, label: item.meta_description});
                    })

                    setDescriptions(description);
                }
            })

            getMetaValueAll({filter:"meta_function:grade"}).then(res => {
                if(res.status == 200){
                    const grade = [];
                    res.data.data.forEach((item) => {  
                            grade.push({value: item.meta_value, label: item.meta_description});
                    })

                    setGrades(grade);
                }
            })

            getMetaValueAll({'filter':'meta_function:lokasi', 'perpage':50}).then(res => {
                if(res.status == 200){
                    const locations = [];
                    res.data.data.forEach((item) => {
                            locations.push({value: item.meta_value, label: item.meta_description});
                    })

                    setLocations(locations);
                }
            })
            
        }
      
    }, []);

    const onChangeSerialDetail = (e, index) => {
        let serial = [...ipqcData.ipqc_serial];
        serial[index][e.target.name] = e.target.value;
        setIpqcData({ ...ipqcData, ipqc_serial: serial });
    }
    const onChangeSerialResultDetail = (e, index, child_index) => {
        let serial = [...ipqcData.ipqc_serial];
        serial[index]['result'][child_index][e.target.name] = e.target.value;
        setIpqcData({ ...ipqcData, ipqc_serial: serial });
    }

    const onChangeSerialGradeDetail = (e,child_index) => {
        let grade = [...ipqcData.ipqc_grade];
        let val = e.target.name;
        if(e.target.name == "description") val = "code";
        grade[child_index][val] = e.target.value;
        grade[child_index]["updated_on"] = new Date();
        grade[child_index]["updated_by"] = userInfo && userInfo.fullname;
        grade[child_index]["ipaddress"] = "not available";
        setIpqcData({ ...ipqcData, ipqc_grade: grade });
    }
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage IPQC</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">IPQC</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <PageOverlay display={processing} />
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title w-100'>
                                <i className='fa fa-tools' /> View IPQC
                                {pageState == "view" && (
                                    <div className='d-flex float-right'>
                                    { (ipqcData.incident_status != 'COMPLETED') &&
                                            <>
                                                { (ipqcData.incident_status == null && ipqcData.incident_id == null) &&
                                                    <>
                                                        <button 
                                                            type='button' className='btn btn-primary' 
                                                            style={{ width: '100%', marginLeft: 20, whiteSpace: 'nowrap'}} 
                                                            onClick={(e) => {setAction(1); setShow(true);}}>
                                                                <i className='fa fa-check' /> Review Inspector
                                                        </button>
                                                        {/* <button 
                                                            type='button' className='btn btn-danger' 
                                                            style={{ width: '100%', marginLeft: 10, whiteSpace: 'nowrap'}} 
                                                            onClick={(e) => {setAction(0); setShow(true);}}>
                                                                <i className='fa fa-times' /> Revise
                                                        </button> */}
                                                    </>
                                                }
                                                { ((ipqcData.incident_status == 'ONPROGRESS' || ipqcData.incident_status == 'ONREVISED') && ipqcData.next_approval_nik == userInfo.nik) &&
                                                    <>
                                                        <button 
                                                            type='button' className='btn btn-primary' 
                                                            style={{ width: '100%', marginLeft: 20, whiteSpace: 'nowrap'}} 
                                                            onClick={(e) => {setAction(1); setShow(true);}}>
                                                                <i className='fa fa-check' />{` Review ` + ipqcData.next_approval_step}
                                                        </button>
                                                        { ipqcData.incident_status == 'ONPROGRESS' &&
                                                            <button 
                                                                type='button' className='btn btn-danger' 
                                                                style={{ width: '100%', marginLeft: 10, whiteSpace: 'nowrap'}} 
                                                                onClick={(e) => {setAction(2); setShow(true);}}>
                                                                    <i className='fa fa-times' /> Revise
                                                            </button>
                                                        }
                                                    </>
                                                }
                                                {/* { ((ipqcData.incident_status == 'ONPROGRESS' || ipqcData.incident_status == 'ONREVISED') && ipqcData.next_approval_nik != userInfo.nik) &&
                                                    <>
                                                        <button 
                                                            type='button' className='btn btn-primary' 
                                                            style={{ width: '100%', marginLeft: 20, whiteSpace: 'nowrap'}} 
                                                            onClick={(e) => {setAction(1); setShow(true);}}>
                                                                <i className='fa fa-check' />{` Review ` + ipqcData.next_approval_step}
                                                        </button>
                                                    </>
                                                } */}
                                            </>
                                        }
                                    </div>

                                )}
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label>KBT No</label>
                                        <div>
                                            <span>{ipqcData.docnumber}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label>Inspection Date</label>
                                        <div>
                                            <span>{ipqcData.inspdate}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label>Model</label>
                                        <div>
                                            <span>{ipqcData.itemname}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label htmlFor='lot-order'>Lot Order</label>
                                        <div>
                                            <span>{ipqcData.lotorder}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label>Assy. Process</label>
                                        <div>
                                            <span>{ipqcData.assyproc}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label htmlFor='tanggal-uji'>Tanggal Uji</label>
                                        <div>
                                            <span>{ipqcData.inspdate}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title'><i className='fa fa-tools' /> List Report</div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group pb-1'>
                                            {ipqcData.ipqc_serial.map((serial, index) => (
                                                <>
                                                    {pageState == "view" && <p className='mt-2'>Serial Number: <b>{serial.serialno}</b></p>}
                                                    {pageState == "edit" && <><span>Serial Number:</span> &nbsp;<input name="serialno" type="text" value={serial.serialno} onChange={(e) => onChangeSerialDetail(e, index)} required/></>}
                                                    <div className='mt-4 mb-4'>
                                                        <DataTable value={serial.result} size="small" responsiveLayout="scroll" showGridlines
                                                            headerColumnGroup={headerColumnGroup} alignHeader="center" align="center"
                                                            rowGroupMode="subheader" groupRowsBy="inspcategname" rowGroupHeaderTemplate={headerTemplate}>
                                                            <Column field="no" body={(data, options) => options.rowIndex + 1} align="center"/>
                                                            <Column field="name" />
                                                            <Column field="specification" />
                                                            <Column field="standard" />
                                                            <Column field="judgement" align="center"body={pageState == "edit" ? (a,b) => renderField(a,b,index, "select",[
                                                                {value: 'OK', label: 'OK'},
                                                                {value: 'NG', label: 'NG'}
                                                            ]): judgementTemplate} />
                                                        </DataTable>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title'><i className='fa fa-tools' /> List Grade</div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <div>
                                                <DataTable value={ipqcData.ipqc_grade} size="small" responsiveLayout="scroll" showGridlines>
                                                    <Column field="no" body={(data, options) => options.rowIndex + 1} align="center" header="No." />
                                                    <Column field="description" header="Deskripsi" body={
                                                        pageState == "edit" ? (a,b) => renderGradeField(a,b, "select", descriptions) : null
                                                    } />
                                                    <Column field="grade" align="center" header="Grade" body={
                                                        pageState == "edit" ? (a,b) => renderGradeField(a,b, "select", grades) : null
                                                    }/>
                                                    <Column disabled field="quantity" align="center" header="Kuantitas" body={
                                                        pageState == "edit" ? (a,b) => renderGradeField(a,b, "number", ipqcData.incident_status) : null
                                                    }/>
                                                    <Column field="whfrom" align="center" header="WH Asal" body={
                                                        pageState == "edit" ? (a,b) => renderGradeField(a,b, "select", locations) : null
                                                    }/>
                                                    <Column field="whto" align="center" header="WH Tujuan" body={
                                                        pageState == "edit" ? (a,b) => renderGradeField(a,b, "select", locations) : null
                                                    }/>
                                                    <Column field="notes" header="Keterangan" body={
                                                        pageState == "edit" ? (a,b) => renderGradeField(a,b, "text") : null
                                                    }/>
                                                </DataTable>
                                            </div>
                                        </div>
                                        <div className='form-group mt-4'>
                                                {pageState == "edit" && (
                                                    <div className='d-flex float-right'>
                                                            <button type='button' className='btn btn-outline-dark' style={{ width: 150, marginRight: 20, whiteSpace: 'nowrap',padding:"0.5rem 0.375rem"}} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                                            <button type='submit' className='btn btn-dark' onClick={() => onFormSubmit(ipqcData)} style={{ width: 150,padding:"0.375rem 0.75rem"}}><i className='fa fa-save' /> Save </button>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Dialog header={`Approval Comments`} visible={show} resizable={false} onHide={() => setShow(false)} footer={renderFooter()} style={{width: '500px'}}>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <InputTextarea className='form-control' placeholder="Comment" value={comment} onChange={(e) => setComment(e.target.value)} rows={4} />
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </section>
            </div>
    );
}

export default IncomingDetailScreen;