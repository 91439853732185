import axios from 'axios';
import { getMultipartOptions, handleResponse } from './HelperService';

const getIqcAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/iqc-report/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getIqcById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/iqc-report/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createIqc = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/iqc-report/create`, payload);
  return handleResponse(response, onSuccess, onError);
};

const updateIqc = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/iqc-report/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteIqc = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/iqc-report/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const changeIqcStatus = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/iqc-report/change-status/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

export {getIqcAll, getIqcById, createIqc, updateIqc, deleteIqc, changeIqcStatus};
