import axios from 'axios';
import { getMultipartOptions, handleResponse } from './HelperService';

const getIqcPartAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/iqc-part/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getIqcPartById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/iqc-part/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const getIqcPartByIqc = (id_iqc, onSuccess, onError) => {
  const response = axios.get (`v1/admin/iqc-part/get-iqc/${id_iqc}`);
  return handleResponse(response, onSuccess, onError);
};

const createIqcPart = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/iqc-part/create`, payload, getMultipartOptions(axios));
  return handleResponse(response, onSuccess, onError);
};

const updateIqcPart = (payload, onSuccess, onError) => {
  const id = payload.get('id');
  const response = axios.put (`v1/admin/iqc-part/update/${id}`, payload, getMultipartOptions(axios));
  return handleResponse(response, onSuccess, onError);
};

const deleteIqcPart = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/iqc-part/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getIqcPartAll, getIqcPartById, getIqcPartByIqc, createIqcPart, updateIqcPart, deleteIqcPart};
