import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeScreen from '../../Screen/Home/HomeScreen';
import CategoryScreen from '../../Screen/ManageMaster/Category/CategoryScreen';
import InspCategoryScreen from '../../Screen/ManageMaster/InspCategory/InspCategoryScreen';
import InspItemScreen from '../../Screen/ManageMaster/InspItem/InspItemScreen';
import InspReportScreen from '../../Screen/ManageMaster/InspReport/InspReportScreen';
import UserScreen from '../../Screen/User/UserScreen';
import RoleAccessScreen from '../../Screen/RoleAccess/RoleAccessScreen';
import LayoutScreen from '../../Screen/Layout/LayoutScreen';
import UserDetailScreen from '../../Screen/User/UserDetailScreen';
import RoleAccessDetailScreen from '../../Screen/RoleAccess/RoleAccessDetailScreen';
import InspAqlScreen from '../../Screen/ManageMaster/InspAql/InspAqlScreen';
import InspFfrScreen from '../../Screen/ManageMaster/InspFfr/InspFfrScreen';
import InspSopScreen from '../../Screen/ManageMaster/InspSop/InspSopScreen';
import IncomingScreen from '../../Screen/Incoming/IncomingScreen';
import IncomingAddScreen from '../../Screen/Incoming/IncomingAddScreen';
import IncomingViewScreen from '../../Screen/Incoming/IncomingViewScreen';
import InprocessScreen from '../../Screen/Inprocess/InprocessScreen';
import InprocessAddScreen from '../../Screen/Inprocess/InprocessAddScreen';
import InprocessViewScreen from '../../Screen/Inprocess/InprocessViewScreen';
import IncomingPartScreen from '../../Screen/Incoming/IncomingPartScreen';
import IncomingEditScreen from '../../Screen/Incoming/IncomingEditScreen';
import MetaValueScreen from '../../Screen/ManageMaster/MetaValue/MetaValue';
import QualityIssueScreen from '../../Screen/QualityIssue/QualityIssueScreen';
import QualityIssueViewScreen from '../../Screen/QualityIssue/QualityIssueViewScreen';
import QualityIssueEditScreen from '../../Screen/QualityIssue/QualityIssueEditScreen';
import LSBCScreen from '../../Screen/LSBC/LSBCScreen';
import LSBCAddScreen from '../../Screen/LSBC/LSBCAddScreen';
import LSBCViewScreen from '../../Screen/LSBC/LSBCViewScreen';
import LSBCEditScreen from '../../Screen/LSBC/LSBCEditScreen';
import PlanProdScreen from '../../Screen/PlanProd/PlanProdScreen';
import PlanProdViewScreen from '../../Screen/PlanProd/PlanProdViewScreen';
import PlanProdEditScreen from '../../Screen/PlanProd/PlanProdEditScreen';
import QualityIssueSummaryScreen from '../../Screen/QualityIssue/QualityIssueSummaryScreen';
import MastStaScreen from '../../Screen/ManageMaster/MastSta/MastStaScreen';
import PlanProdEditMFIScreen from '../../Screen/PlanProd/PlanProdEditMFIScreen';
import PlanProdViewMFIScreen from '../../Screen/PlanProd/PlanProdViewMFIScreen';

const ToHome = () => {
  // const refreshPath = useSelector(({ auth: { refreshPath } }) => refreshPath);
  // const path = refreshPath.replace('login', 'dashboard').split('/').slice(0, 4).join('/');
  // return <Navigate to={path} replace={true} />;
  return <Navigate to="/home" replace={true} />;
};

const ScreenNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutScreen />}>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/home" element={<HomeScreen />} />

          <Route path="/incoming" element={<IncomingScreen />} />
          <Route path="/incoming/add" element={<IncomingAddScreen />} />
          <Route path="/incoming/part-image" element={<IncomingPartScreen />} />
          <Route path="/incoming/edit/:id" element={<IncomingEditScreen />} />
          <Route path="/incoming/part-image/:id" element={<IncomingPartScreen />} />
          <Route path="/incoming/view/:id" element={<IncomingViewScreen />} />

          <Route path="/inprocess" element={<InprocessScreen />} />
          <Route path="/inprocess/add" element={<InprocessAddScreen />} />
          <Route path="/inprocess/:pageState/:id" element={<InprocessViewScreen />} />

          <Route path="/quality-issue" element={<QualityIssueScreen />} />
          <Route path="/quality-issue/edit/:id" element={<QualityIssueEditScreen />} />
          <Route path="/quality-issue/view/:id" element={<QualityIssueViewScreen />} />
          <Route path="/quality-issue/summary/:id_iqc" element={<QualityIssueSummaryScreen />} />

          <Route path="/planprod" element={<PlanProdScreen />} />
          {/* <Route path="/planprod/add" element={<LSBCAddScreen />} /> */}
          <Route path="/planprod/edit/:id" element={<PlanProdEditScreen />} />
          <Route path="/planprod/editMFI/:id" element={<PlanProdEditMFIScreen />} />
          <Route path="/planprod/view/:id" element={<PlanProdViewScreen />} /> 
          <Route path="/planprod/viewMFI/:id" element={<PlanProdViewMFIScreen />} /> 

          <Route path="/lsbc" element={<LSBCScreen />} />
          <Route path="/lsbc/add" element={<LSBCAddScreen />} />
          <Route path="/lsbc/edit/:id" element={<LSBCEditScreen />} />
          <Route path="/lsbc/view/:id" element={<LSBCViewScreen />} />  
          
          <Route path="/metavalue" element={<MetaValueScreen />} />
          <Route path="/insp-aql" element={<InspAqlScreen />} />
          <Route path="/category" element={<CategoryScreen />} />
          <Route path="/insp-category" element={<InspCategoryScreen />} />
          <Route path="/insp-item" element={<InspItemScreen />} />
          <Route path="/insp-report" element={<InspReportScreen />} />
          <Route path="/insp-ffr" element={<InspFfrScreen />} />
          <Route path="/insp-sop" element={<InspSopScreen />} />
          <Route path="/mast-sta" element={<MastStaScreen />} />

          <Route path="/user" element={<UserScreen />} />
          <Route path="/user/:pageState/:username" element={<UserDetailScreen />} />
          <Route path="/roleaccess" element={<RoleAccessScreen />} />
          <Route path="/roleaccess/:pageState/:roleid" element={<RoleAccessDetailScreen />} />
          <Route path="/*" element={<ToHome />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
};

export default ScreenNavigation;
