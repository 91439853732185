import React, { Component, useEffect, useState } from 'react';
import { Uploader, Loader } from 'rsuite';
import ImageIcon from '@rsuite/icons/Image';
import { previewFile } from '../../Function/Function';
import { useNavigate, useParams } from 'react-router-dom';
import { getLSBCById, updateLSBC } from '../../Service/LSBCService';
import Swal from 'sweetalert2';
import { getMetaValueAll } from '../../Service/MetaValueService';
const { $ } = window;
const localState = {};

const LSBCEditScreen = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [ LSBCData, setLSBCData ] = useState({
        id: 0,
        docnumber: null,
        docdate: null,
        docsource: null,
        doctype: null,
        grade: null,
        lotorder: null,
        itemcode: null,
        itemname: null,
        itemdesc: null,
        serialno: null,
        whfrom: null,
        inspdate: null,
        inspdocnum: null,
        inspgrade: null,
        inspwh: null,
        inspstatus: null,
        created_by: null,
        created_on: null,
        updated_by: null,
        updated_on: null,
        ipaddress: null,
        data: []
    });
    const [ defectName, setDefectName ] = useState([{
        meta_value: null,
        meta_description: null,
    }]);
    const [ defectLocation, setDefectLocation ] = useState([{
        meta_value: null,
        meta_description: null,
    }]);
    const [ grade, setGrade ] = useState([{
        meta_value: null,
        meta_description: null,
    }]);

    const onGoback = () => {
        navigate(-1);
    }

    const getLSBCData = (id) => {
        getLSBCById(id).then(res => {
            setLSBCData(res.data);
        });
    }

    const addRowDetail = () => {
        let array = LSBCData.data.slice();
        array.push({
            defsource: "",
            defname: "",
            deflocation: "",
            defnotes: "",
            defcorrection: "",
        });
        const newObj = { ...LSBCData, data: array };
        setLSBCData(newObj);
    }

    const updateItemDetail = (index, key, value) => {
        let array = LSBCData.data.slice();
        array[index][key] = value;
        const newObj = { ...LSBCData, data: array };
        setLSBCData(newObj);
    }

    const removeRowDetail = index => {
        const temp = [...LSBCData.data];
        
        temp.splice(index, 1);
        setLSBCData({...LSBCData, data: temp});
    }

    const onSubmit = () => {
        var flag = true;
        for (var key in LSBCData.data) {
            if(LSBCData.data[key].itemcode == "" || LSBCData.data[key].itemdesc == "" || LSBCData.data[key].quantity == 0 || LSBCData.data[key].defsource == "" || 
                LSBCData.data[key].defname == "" || LSBCData.data[key].deflocation == "" || LSBCData.data[key].defnotes == "" || LSBCData.data[key].defcorrection == ""){
                flag = false;
            }
        }
        if(flag) {
            const response = updateLSBC(id, LSBCData);
            response.then(res => {
                if (res.status == 200 || res.status == 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Save data success',
                        text: 'Data has been saved!'
                    });

                    navigate("/lsbc");
                }
            }).catch(({response: { data } }) => {
                if(data.status == 422){
                    const obj = Object.keys(data.errors);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.errors[obj[0]][0]
                    });
                }else
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Save data error!'
                    });
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill all defect data!'
            });
        }
    }

    const getMetaValue = () => {
        getMetaValueAll({"filter":"meta_function:defect_type", "perpage": 1000}).then(res => {
            setDefectName(res.data.data);
        });
        getMetaValueAll({"filter":"meta_function:defect_area", "perpage": 1000}).then(res => {
            setDefectLocation(res.data.data);
        });
        getMetaValueAll({"filter":"meta_function:grade:", "perpage": 1000}).then(res => {
            setGrade(res.data.data);
        });
    }
    
    useEffect(() => {
        getLSBCData(id);
        getMetaValue();
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage LSBC</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">LSBC</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> Edit LSBC</div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td width={'20%'}>DOCUMENT NO.</td>
                                                    <td colSpan={3}><b>{LSBCData.docnumber}</b></td>
                                                </tr>
                                                <tr>
                                                    <td width={'20%'}>DOC. DATE</td>
                                                    <td width={'30%'}>{LSBCData.docdate}</td>
                                                    <td width={'20%'}>DOC. SOURCE</td>
                                                    <td width={'30%'}>{LSBCData.docsource}</td>
                                                </tr>
                                                <tr>
                                                    <td>DOC. TYPE</td>
                                                    <td>{LSBCData.doctype}</td>
                                                    <td>GRADE</td>
                                                    <td>{LSBCData.grade}</td>
                                                </tr>
                                                <tr>
                                                    <td>LOT ORDER</td>
                                                    <td>{LSBCData.lotorder}</td>
                                                    <td>ITEM CODE</td>
                                                    <td>{LSBCData.itemcode}</td>
                                                </tr>
                                                <tr>
                                                    <td>ITEM NAME</td>
                                                    <td>{LSBCData.itemdesc}</td>
                                                    <td>WH LOCATION</td>
                                                    <td>{LSBCData.whfrom}</td>
                                                </tr>
                                                <tr>
                                                    <td>SERIAL NUMBER</td>
                                                    <td><input className='form-control' type="text" value={LSBCData.serialno} onChange={(e) => setLSBCData({...LSBCData, serialno: e.target.value})} /></td>
                                                    <td>INSPECTION DATE</td>
                                                    <td><input className='form-control' type="date" value={LSBCData.inspdate} onChange={(e) => setLSBCData({...LSBCData, inspdate: e.target.value})} /></td>
                                                </tr>
                                                <tr>
                                                    <td>INSPECTION DOC. NUMBER</td>
                                                    <td><input className='form-control' type="text" value={LSBCData.inspdocnum} onChange={(e) => setLSBCData({...LSBCData, inspdocnum: e.target.value})} /></td>
                                                    <td>INSPECTION GRADE</td>
                                                    <td>
                                                        <select className='form-control' value={LSBCData.inspgrade} onChange={(e) => setLSBCData({...LSBCData, inspgrade: e.target.value})} >
                                                            <option value=""></option>
                                                            {grade.map((grade) => (
                                                                <option value={grade.meta_value}>{grade.meta_description}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>INSPECTION WH</td>
                                                    <td><input className='form-control' type="text" value={LSBCData.inspwh} onChange={(e) => setLSBCData({...LSBCData, inspwh: e.target.value})} /></td>
                                                    <td>INSPECTION STATUS</td>
                                                    <td><input className='form-control' type="text" value={LSBCData.inspstatus} onChange={(e) => setLSBCData({...LSBCData, inspstatus: e.target.value})} /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} align="center">DETAIL DEFECT</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{textAlign: "center"}}>Item Code</th>
                                                                    <th style={{textAlign: "center"}}>Item Desc</th>
                                                                    <th style={{textAlign: "center"}}>Quantity</th>
                                                                    <th style={{textAlign: "center"}}>Defect Source</th>
                                                                    <th style={{textAlign: "center"}}>Defect Name</th>
                                                                    <th style={{textAlign: "center"}}>Defect Location</th>
                                                                    <th style={{textAlign: "center"}}>Defect Notes</th>
                                                                    <th style={{textAlign: "center"}}>Defect Correction</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {LSBCData.data.map((data, key) => (
                                                                    <tr>
                                                                        <td><input className='form-control' type="text" style={{float: 'right', width: '97%'}} value={data.itemcode} onChange={(e) => updateItemDetail(key, "itemcode", e.target.value)} /></td>
                                                                        <td><input className='form-control' type="text" style={{float: 'right', width: '97%'}} value={data.itemdesc} onChange={(e) => updateItemDetail(key, "itemdesc", e.target.value)} /></td>
                                                                        <td width={`10%`}><input className='form-control' type="number" min="1" style={{float: 'right', width: '97%'}} value={data.quantity} onChange={(e) => updateItemDetail(key, "quantity", e.target.value)} /></td>
                                                                        <td>
                                                                            <select className='form-control' style={{float: 'right', width: '97%'}} value={data.defsource} onChange={(e) => updateItemDetail(key, "defsource", e.target.value)}>
                                                                                <option value=""></option>
                                                                                <option value="Supplier">Supplier</option>
                                                                                <option value="Production">Production</option>
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <select className='form-control' style={{float: 'right', width: '97%'}} value={data.defname} onChange={(e) => updateItemDetail(key, "defname", e.target.value)} >
                                                                                <option value=""></option>
                                                                                {defectName.map((name) => (
                                                                                    <option value={name.meta_value}>{name.meta_description}</option>
                                                                                ))}
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <select className='form-control' style={{float: 'right', width: '97%'}} value={data.deflocation} onChange={(e) => updateItemDetail(key, "deflocation", e.target.value)} >
                                                                                <option value=""></option>
                                                                                {defectLocation.map((location) => (
                                                                                    <option value={location.meta_value}>{location.meta_description}</option>
                                                                                ))}
                                                                            </select>
                                                                        </td>
                                                                        <td><textarea className='form-control' rows={2} style={{float: 'right', width: '97%'}} value={data.defnotes} onChange={(e) => updateItemDetail(key, "defnotes", e.target.value)} /></td>
                                                                        <td><textarea className='form-control' rows={2} style={{float: 'right', width: '97%'}} value={data.defcorrection} onChange={(e) => updateItemDetail(key, "defcorrection", e.target.value)} /></td>
                                                                        <td>{LSBCData.data.length > 1 && <button className='form-control btn-danger btn-xs' onClick={() => removeRowDetail(key)}><i className="fa fa-times" aria-hidden="true"></i></button>}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colSpan={9}><button className='form-control' onClick={addRowDetail}><i className="fa fa-plus" aria-hidden="true"></i></button></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className='form-group'>
                                        <div className='d-flex float-right'>
                                            <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='row'>
                                    <div className='col-12 mt-2'>
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                                <button type='submit' className='btn btn-dark' style={{ width: 150 }} onClick={() => onSubmit()}><i className='fa fa-save' /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default LSBCEditScreen;