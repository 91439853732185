import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Overlay from '../Overlay/Overlay';
import { excel } from '../../Images';
import { getFileError, deleteFileById } from '../../Service/FileService';
import { toastError, toastSuccess } from '../Toasts/Toast';

const { $ } = window;
let processingId = -1;

const ErrorFileUpload = ({ refresh, source="ffr_master" }) => {
    const [state, setState] = useState({
        processing: false,
        filesError: []
    });

    const { processing, filesError } = state;

    const startProcessing = () => {
        processingId = setTimeout(() => {
            setState({ ...state, processing: true });
        }, 150);
    };

    const stopProcessing = () => {
        clearTimeout(processingId);
        setState({ ...state, processing: false });
    };

    useEffect(() => {
        if (refresh.files) {
            startProcessing();
            getFileError(source)
            .then(res => {
                setTimeout(() => {
                    setState({ ...state, filesError: res.data })
                }, 150);
            }).catch(({ response: { data } }) => {
                setTimeout(() => {
                    setState({ ...state, fileExport: null, files: [] });
                }, 100);
            }).finally(_ => stopProcessing());
        }
    }, [refresh]);

    const onDeleteFileError = (id, index) => {
        startProcessing();
        const response = deleteFileById(id);
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                toastSuccess(res.data.message);
                filesError.splice(index, 1);
                setTimeout(() => {
                    setState({ ...state, filesError });
                }, 150);
            }
        }).catch(({ response: { data } }) => {
            toastError(data.message);
        }).finally(_ => stopProcessing());
    }

    return (
        filesError.length > 0
            ? <div className='card'>
                <div className="card-header">
                    <h3 className="card-title" style={{ color: "red" }}>
                        <i className="fas fa-file mr-1" style={{ color: "red" }} />
                        Error File Upload
                    </h3>
                </div>
                <div className="card-body">
                    <div className="card-body d-flex flex-row justify-content-start">
                        <Overlay display={processing} />
                        {
                            filesError.map((item, i) => {
                                return (
                                    <div key={i} className="card d-flex flex-column align-items-center p-2" style={i > 0 ? { border: '1px solid #ccc', borderRadius: 5, marginLeft: 20 } : { border: '1px solid #ccc', borderRadius: 5 }}>
                                        <div className="" style={{ position: 'absolute', top: -10, right: -10 }}>
                                            <Button type='button' className='p-button-rounded p-button-danger' icon="pi pi-times" style={{ width: 30, height: 30 }} onClick={() => onDeleteFileError(item.id, i)} />
                                        </div>
                                        <img src={excel} alt="excel" style={{ width: 80, height: 80 }} />
                                        <p style={{ wordBreak: 'break-word' }}>{item.file_name}</p>
                                        <div className="d-flex">
                                            <a href={item.file_url} download className="btn btn-warning btn-xs mr-2">Download</a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            : ""
    )
}

export default ErrorFileUpload;