import React, { Component, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Switch from '@mui/material/Switch';
import { useNavigate, useParams } from 'react-router-dom';
import { Uploader, Loader } from 'rsuite';
import ImageIcon from '@rsuite/icons/Image';
import { previewFile } from '../../Function/Function';
import { createIqcPart, updateIqcPart, deleteIqcPart, getIqcPartByIqc } from '../../Service/IqcPartService';
import Swal from 'sweetalert2';
const { $ } = window;
const localState = {};

const IncomingPartScreen = () => {
    const navigate = useNavigate(); 
    const { id } = useParams();
    const [uploading, setUploading] = useState(false);
    const [oldImage, setOldImage] = useState([]);
    const [newImage, setNewImage] = useState([]);
    const [oldImageSpec, setOldImageSpec] = useState([]);
    const [newImageSpec, setNewImageSpec] = useState([]);
    const [comparation, setComparation] = useState([]);
    const [specification, setSpecification] = useState([]);
    const [deletedComparation, setDeletedComparation] = useState([]);
    const [deletedSpecification, setDeletedSpecification] = useState([]);

    const addRowComparation = e => {
        const compare = [...comparation];
        compare.push({
            part_name: "", 
            part_type: "part comparation",
            old_image: [],
            new_image: [],
            remarks: "",
            type: "new"
        });
        setComparation(compare);
    }

    const removeRowComparation = index => {
        const temp = [...comparation];
        
        if(temp[index].id != null && temp[index].id != undefined && temp[index].type == 'old')
            setDeletedComparation([...deletedComparation, temp[index].id]);
        if(oldImage[index] != "" && oldImage[index] != undefined)
            oldImage.splice(index, 1);
        if(newImage[index] != "" && newImage[index] != undefined)
            newImage.splice(index, 1);
        
        temp.splice(index, 1);
        setComparation(temp);
    }

    const updateItemComparation = (index, itemAttributes, value) => {
        const temp = [...comparation];
        temp[index][itemAttributes] = value;
        setComparation(temp);
    }

    const addRowSpecification = e => {
        const spec = [...specification];
        spec.push({
            part_name: "",
            part_type: "part specification",
            old_image: [],
            new_image: [],
            old_image_spec: "",
            new_image_spec: "",
            is_changed: false,
            part_sub_name: "",
            remarks: "",
            type: "new"
        });
        setSpecification(spec);
    }

    const removeRowSpecification = index => {
        const temp = [...specification];
        
        if(temp[index].id != null && temp[index].id != undefined && temp[index].type == 'old'){}
            setDeletedSpecification([...deletedSpecification, temp[index].id]);
        if(oldImageSpec[index] != "" && oldImageSpec[index] != undefined)
            oldImageSpec.splice(index, 1);
        if(newImageSpec[index] != "" && newImageSpec[index] != undefined)
            newImageSpec.splice(index, 1);

        temp.splice(index, 1);
        setSpecification(temp);
        console.log(deletedSpecification);
    }

    const updateItemSpecification = (index, itemAttributes, value) => {
        const temp = [...specification];
        temp[index][itemAttributes] = value;
        setSpecification(temp);
    }

    const submitComp = () => {
        return Promise.all(comparation.map((item) => {
            if (item.type === 'new') {
                const formData = new FormData();
                formData.append("id_iqc", id);
                for (var key in item) {
                    if (key === "old_image")
                        formData.append("image_path[0]", item[key]);
                    else if (key === "new_image")
                        formData.append("image_path[1]", item[key]);
                    else
                        formData.append(key, item[key]);
                }
                return createIqcPart(formData)
                    .then(res => {
                        console.log('success submitcomp', res);
                    })
                    .catch(error => {
                        console.error('Error in submitComp new:', error.message);
                        throw error;
                    });
            } else if (item.type === 'old') {
                const formData = new FormData();
                for (var key in item) {
                    if (key === "old_image")
                        formData.append("image_path[0]", item[key]);
                    else if (key === "new_image")
                        formData.append("image_path[1]", item[key]);
                    else if (key === "old_image_id")
                        formData.append("image_id[0]", item[key]);
                    else if (key === "new_image_id")
                        formData.append("image_id[1]", item[key]);
                    else if (key === "old_image_name")
                        formData.append("image_name[0]", item[key]);
                    else if (key === "new_image_name")
                        formData.append("image_name[1]", item[key]);
                    else
                        formData.append(key, item[key]);
                }
                return updateIqcPart(formData)
                    .then(res => {
                        console.log('success submitcomp', res);
                    })
                    .catch(error => {
                        console.error('Error in submitComp old:', error.message);
                        throw error;
                    });
            }
        }));
    };
    
    const submitSpec = () => {
        return Promise.all(specification.map((item) => {
            if (item.type === 'new') {
                const formData = new FormData();
                formData.append("id_iqc", id);
                for (var key in item) {
                    if (key === "old_image")
                        formData.append("image_path[0]", item[key]);
                    else if (key === "new_image")
                        formData.append("image_path[1]", item[key]);
                    else if (key === "old_image_spec")
                        formData.append("image_spec[0]", item[key]);
                    else if (key === "new_image_spec")
                        formData.append("image_spec[1]", item[key]);
                    else
                        formData.append(key, item[key]);
                }
                return createIqcPart(formData)
                    .then(res => {
                        console.log('success submit spec', res);
                    })
                    .catch(error => {
                        console.error('Error in submitSpec new:', error.message);
                        throw error;
                    });
            } else if (item.type === 'old') {
                const formData = new FormData();
                for (var key in item) {
                    if (key === "old_image")
                        formData.append("image_path[0]", item[key]);
                    else if (key === "new_image")
                        formData.append("image_path[1]", item[key]);
                    else if (key === "old_image_id")
                        formData.append("image_id[0]", item[key]);
                    else if (key === "new_image_id")
                        formData.append("image_id[1]", item[key]);
                    else if (key === "old_image_name")
                        formData.append("image_name[0]", item[key]);
                    else if (key === "new_image_name")
                        formData.append("image_name[1]", item[key]);
                    else if (key === "old_image_spec")
                        formData.append("image_spec[0]", item[key]);
                    else if (key === "new_image_spec")
                        formData.append("image_spec[1]", item[key]);
                    else
                        formData.append(key, item[key]);
                }
                return updateIqcPart(formData)
                    .then(res => {
                        console.log('success submit spec', res);
                    })
                    .catch(error => {
                        console.error('Error in submitSpec old:', error.message);
                        throw error;
                    });
            }
        }));
    };

    const submitDelete = () => {
        deletedComparation.map((item) => {
            const response = deleteIqcPart(item);
            response.then(res => {
                // if (res.status == 200 || res.status == 201)
                //     ++countSuccess;
                // else
                //     ++countFail;
            }).catch(({response: { data } }) => {
                // ++countFail;
            });
        });

        deletedSpecification.map((item) => {
            const response = deleteIqcPart(item);
            response.then(res => {
                // if (res.status == 200 || res.status == 201)
                //     ++countSuccess;
                // else
                //     ++countFail;
            }).catch(({response: { data } }) => {
                // ++countFail;
            });
        });
    }

    /* Old Code Submit */
    // const onSubmit = () => {
    //     submitComp();
    //     console.log('masuk submit', submitComp());
    //     submitSpec();
    //     submitDelete();
        
    //     Swal.fire({
    //         icon: 'success',
    //         title: 'Save image data success',
    //         text: 'Data has been saved!'
    //     });

    //     // navigate("/incoming");
    // }

    /* New Code Submit */
    const onSubmit = () => {
        Promise.all([
            submitComp(),
            submitSpec(),
            submitDelete()
        ])
        .then(() => {
            Swal.fire({
                icon: 'success',
                title: 'Save image data success',
                text: 'Data has been saved!'
            });
    
            navigate("/incoming");
        })
        .catch(error => {
            console.error('Submission failed:', error.response.data.errors);

            // Extract error messages
        const errorResponse = error.response.data;
        console.log('ini errorResponse', errorResponse);
        let errorMessages = [];

        for (let key in errorResponse) {
            if (Array.isArray(errorResponse[key])) {
                errorResponse[key].forEach(message => {
                    errorMessages.push(message);
                });
            } else if (typeof errorResponse[key] === 'object') {
                for (let subKey in errorResponse[key]) {
                    if (Array.isArray(errorResponse[key][subKey])) {
                        errorResponse[key][subKey].forEach(message => {
                            errorMessages.push(message);
                        });
                    } else {
                        errorMessages.push(errorResponse[key][subKey]);
                    }
                }
            } else {
                errorMessages.push(errorResponse[key]);
            }
        }

        Swal.fire({
            icon: 'error',
            title: 'Submission Failed',
            text: `Error ${errorMessages.join('\n')}`
        });
        });
    };

    const getOldPartData = async () => {
        getIqcPartByIqc(id).then(res => {
            let compare = [];
            let spec = [];

            if(res.data.length != 0){
                res.data.map((item) => {
                    if(item.part_type.includes('comparation')){
                        item.type = "old";
                        let countOld = 0;
                        let countNew = 0;
                        item.images.map((image) => {
                            if(image.file_type.includes('new') && !countNew){
                                newImage.push(image.file_url);
                                item.new_image_id = image.id;
                                item.new_image_name = image.file_name;
                                item.new_image = "exist";
                                countNew = 1;
                            }
                        });

                        if(item.images.length == 0) {
                            newImage.push("");
                            item.new_image_id = null;
                            item.new_image_name = null;
                            item.new_image = "exist";
                        }
                        
                        compare.push(item);
                    }else{
                        item.type = "old";
                        let countOld = 0;
                        let countNew = 0;
                        item.images.map((image) => {
                            if(image.file_type.includes('old') && !countOld){
                                oldImageSpec.push(image.file_url);
                                item.old_image_id = image.id;
                                item.old_image_name = image.file_name;
                                item.old_image = "exist";
                                item.old_image_spec = image.media_desc;
                                countOld = 1;
                            }else if(image.file_type.includes('new') && !countNew){
                                newImageSpec.push(image.file_url);
                                item.new_image_id = image.id;
                                item.new_image_name = image.file_name;
                                item.new_image = "exist";
                                item.new_image_spec = image.media_desc;
                                countNew = 1;
                            }
                        });

                        if(item.images.length == 0) {
                            oldImageSpec.push("");
                            item.old_image_id = null;
                            item.old_image_name = null;
                            item.old_image = "exist";
                            item.old_image_spec = "";
                            newImageSpec.push("");
                            item.new_image_id = null;
                            item.new_image_name = null;
                            item.new_image = "exist";
                            item.new_image_spec = "";
                        }
                        
                        spec.push(item);
                    }
                });
            }else{
                compare.push({
                    part_name: "",
                    part_type: "part comparation",
                    old_image: [],
                    new_image: [],
                    remarks: "",
                    type: "new"
                });
                spec.push({
                    part_name: "",
                    part_type: "part specification",
                    old_image: [],
                    new_image: [],
                    old_image_spec: "",
                    new_image_spec: "",
                    is_changed: false,
                    part_sub_name: "",
                    remarks: "",
                    type: "new"
                });
            }

            setComparation(compare);
            setSpecification(spec);
        });
    }
    
    useEffect(() => {
        getOldPartData();
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage IQC</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">IQC</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> Printing Check</div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} rowSpan={2} width={'30%'}>Label</th>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} colSpan={1}>Gambar Lot Baru</th>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} rowSpan={2}>Keterangan</th>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} rowSpan={2} width={'4%'}></th>
                                                </tr>
                                                <tr>
                                                    {/* <th style={{background: '#f8f9fa', textAlign: 'center'}}>Lot Lama</th> 
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}}>Lot Baru</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {comparation.map((compare, index) => (
                                                    <tr>
                                                        <td><input className='form-control' type="text" value={comparation[index].part_name} onChange={(e) => updateItemComparation(index, "part_name", e.target.value)} /></td>
                                                        {/* <td width={150}>
                                                            <Uploader
                                                                fileListVisible={false}
                                                                listType="picture"
                                                                onUpload={file => {
                                                                    updateItemComparation(index, "old_image", file.blobFile);
                                                                    previewFile(file.blobFile, value => {
                                                                        const temp = [...oldImage];
                                                                        temp[index] = value;
                                                                        setOldImage(temp);
                                                                    });
                                                                }}
                                                            >
                                                                <button style={{width: 150, height: 150, border: 'unset'}}>
                                                                    {uploading && <Loader backdrop center />}
                                                                    {oldImage[index] ? <img src={oldImage[index]} width={`100%`} height={`100%`} /> : <ImageIcon style={{ fontSize: 20 }} />}
                                                                </button>
                                                            </Uploader>
                                                        </td> */}
                                                        <td width={150}>
                                                            <Uploader
                                                                fileListVisible={false}
                                                                listType="picture"
                                                                onUpload={file => {
                                                                    updateItemComparation(index, "new_image", file.blobFile);
                                                                    previewFile(file.blobFile, value => {
                                                                        const temp = [...newImage];
                                                                        temp[index] = value;
                                                                        setNewImage(temp);
                                                                    });
                                                                }}
                                                            >
                                                                <button style={{width: 150, height: 150, border: 'unset'}}>
                                                                    {uploading && <Loader backdrop center />}
                                                                    {newImage[index] ? <img src={newImage[index]} width={`100%`} height={`100%`} /> : <ImageIcon style={{ fontSize: 20 }} />}
                                                                </button>
                                                            </Uploader>
                                                        </td>
                                                        <td><textarea className='form-control' rows={3} value={comparation[index].remarks} onChange={(e) => updateItemComparation(index, "remarks", e.target.value)} /></td>
                                                        <td>{comparation.length > 1 && <button className='form-control btn-danger btn-xs' onClick={() => removeRowComparation(index)}><i className="fa fa-times" aria-hidden="true"></i></button>}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={5}><button className='form-control' onClick={addRowComparation}><i className="fa fa-plus" aria-hidden="true"></i></button></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    {/* <div className='form-group'>
                                        <div className='d-flex float-right'>
                                            <button type='button' className='btn btn-dark' style={{ width: 100 }} onClick={() => submitComp()}><i className='fa fa-save' /> Save</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> Spesifikasi</div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} rowSpan={2} width={'20%'}>Nama Part</th>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} colSpan={4}>Spesifikasi & Gambar</th>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} rowSpan={2}>Perubahan<br></br>(No/Yes)</th>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} rowSpan={2}>Subtitusi</th>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} rowSpan={2}>Remark</th>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} rowSpan={2} width={'4%'}></th>
                                                </tr>
                                                <tr>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} colSpan={2} width={'15%'}>Lot Lama</th>
                                                    <th style={{background: '#f8f9fa', textAlign: 'center'}} colSpan={2} width={'15%'}>Lot Baru</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {specification.map((spec, index) => (
                                                    <>
                                                        <tr>
                                                            <td rowSpan={2}><input className='form-control' type="text" value={specification[index].part_name} onChange={(e) => updateItemSpecification(index, "part_name", e.target.value)} /></td>
                                                            <td width={150} colSpan={2} style={{textAlign: 'center'}}>
                                                                <Uploader
                                                                    fileListVisible={false}
                                                                    listType="picture"
                                                                    onUpload={file => {
                                                                        updateItemSpecification(index, "old_image", file.blobFile);
                                                                        previewFile(file.blobFile, value => {
                                                                            const temp = [...oldImageSpec];
                                                                            temp[index] = value;
                                                                            setOldImageSpec(temp);
                                                                        });
                                                                    }}
                                                                >
                                                                    <button style={{width: 150, height: 150, border: 'unset'}}>
                                                                        {uploading && <Loader backdrop center />}
                                                                        {oldImageSpec[index] ? <img src={oldImageSpec[index]} width={`100%`} height={`100%`} /> : <ImageIcon style={{ fontSize: 20 }} />}
                                                                    </button>
                                                                </Uploader>
                                                            </td>
                                                            <td width={150} colSpan={2} style={{textAlign: 'center'}}>
                                                                <Uploader
                                                                    fileListVisible={false}
                                                                    listType="picture"
                                                                    onUpload={file => {
                                                                        updateItemSpecification(index, "new_image", file.blobFile);
                                                                        previewFile(file.blobFile, value => {
                                                                            const temp = [...newImageSpec];
                                                                            temp[index] = value;
                                                                            setNewImageSpec(temp);
                                                                        });
                                                                    }}
                                                                >
                                                                    <button style={{width: 150, height: 150, border: 'unset'}}>
                                                                        {uploading && <Loader backdrop center />}
                                                                        {newImageSpec[index] ? <img src={newImageSpec[index]} width={`100%`} height={`100%`} /> : <ImageIcon style={{ fontSize: 20 }} />}
                                                                    </button>
                                                                </Uploader>
                                                            </td>
                                                            <td rowSpan={2} style={{textAlign: 'center'}}><Switch color="warning" checked={specification[index].is_changed} onChange={(e) => updateItemSpecification(index, "is_changed", e.target.checked)} /></td>
                                                            <td rowSpan={2}><input className='form-control' type="text" value={specification[index].part_sub_name} onChange={(e) => updateItemSpecification(index, "part_sub_name", e.target.value)} /></td>
                                                            <td rowSpan={2}><textarea className='form-control' rows={3} value={specification[index].remarks} onChange={(e) => updateItemSpecification(index, "remarks", e.target.value)} /></td>
                                                            <td rowSpan={2}>{specification.length > 1 && <button className='form-control btn-danger btn-sm' onClick={() => removeRowSpecification(index)}><i className="fa fa-times" aria-hidden="true"></i></button>}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'1%'}>Spec:</td>
                                                            <td style={{textAlign: 'center'}}><input className='form-control' type="text" value={specification[index].old_image_spec} onChange={(e) => updateItemSpecification(index, "old_image_spec", e.target.value)} /></td>
                                                            <td width={'1%'}>Spec:</td>
                                                            <td style={{textAlign: 'center'}}><input className='form-control' type="text" value={specification[index].new_image_spec} onChange={(e) => updateItemSpecification(index, "new_image_spec", e.target.value)} /></td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={9}><button className='form-control' onClick={addRowSpecification}><i className="fa fa-plus" aria-hidden="true"></i></button></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div className='form-group'>
                                        <div className='d-flex float-right'>
                                            <button type='button' className='btn btn-dark' style={{ width: 100 }} onClick={() => onSubmit()}><i className='fa fa-save' /> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default IncomingPartScreen;