import React, { Component, useEffect, useState, useRef } from 'react';
import { changeIqcStatus, deleteIqc, getIqcAll } from '../../Service/IqcService';
import { useForm, Controller, handleSubmit } from 'react-hook-form';
import Swal from 'sweetalert2';
import MTable from '../../Components/MTable/MTable';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'rsuite';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { getDiagram, getHistories } from '../../Service/WorkflowService';
const { $ } = window;
const localState = {};

const IncomingScreen = () => {
    const navigate = useNavigate();
    const mTable = useRef();

    let { userInfo } = useSelector(state => state.auth);
    const [show, setShow] = useState(false);
    const [histories, setHistories] = useState([]);

    if (!userInfo) {
        userInfo = {}
    }

    useEffect(() => {}, []); 

    const onView = item => () => {
        navigate("view/" + item.id);
    };

    const onEdit = item => () => {
        navigate("edit/" + item.id);
    };

    const onEditImage = item => () => {
        navigate("part-image/" + item.id);
    };

    const onRemove = item => () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(item.id);
            }
        });
    }

    const removeData = id => {
        deleteIqc(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

    const openApproval = (item) => {
        if(item.incident_id != null) {
            getHistories(item.incident_id).then(res => {
                setHistories(res.data.histories);
            });

            getDiagram(item.incident_id).then(res => {
                document.getElementById('approval-iframe').srcdoc = res.data;
            });
        }

        setShow(true);
    }

    const columns = [
        { id: 1, title: 'Dokumen', field: 'docnumber', sortable: true },
        { id: 2, title: 'Tgl. Inspeksi', field: 'inspdate', sortable: true },
        { id: 3, title: 'Lot Order', field: 'lotorder', sortable: true },
        { id: 4, title: 'Model', field: 'itemname', sortable: true },
        { id: 5, title: 'Serial No', field: 'serialno', sortable: true },
        {
            id: 6,
            title: 'Perubahan',
            sortable: true,
            field: 'is_changed',
            render: item => {
                return (
                    item.is_changed == 0 ? `Ada` : (item.is_changed == 1 ? `Tidak` : `Baru`)
                );
            },
        },
        { id: 7, title: 'Inspeksi', field: 'insprulename', sortable: true },
        { id: 8, title: 'Sampling Code', field: 'inspcodename', sortable: true },
        { id: 9, title: 'Qty Inspeksi', field: 'inspqty', sortable: true },
        {
            id: 10,
            title: 'Status Approval',
            sortable: true,
            field: 'incident_status',
            render: item => {
                return (
                    item.incident_status ? item.incident_status : `-`
                );
            },
        },
        { 
            id: 11, 
            title: 'Inspektor', 
            sortable: true,
            field: 'created_by',
            render: item => {
                return (
                    item.created_by != "system" ? item.created_by : `-`
                );
            },
        },
        {
            id: 12,
            title: 'Action',
            render: item => {
                return (
                    <div className='row'>
                        <div>
                            { (item.incident_id != null) &&
                                <a
                                    onClick={(e) => openApproval(item)}
                                    style={{
                                        cursor: 'pointer',
                                        color: 'green',
                                        display: 'inline-block',
                                        marginRight: 20
                                    }}
                                >
                                    <i className="far fa-list-alt" title='View Approval' />
                                </a>
                            }
                            <a
                                onClick={onView(item)}
                                style={{
                                    cursor: 'pointer',
                                    color: 'orange',
                                    display: 'inline-block',
                                    marginRight: 20
                                }}
                            >
                                <i className="fas fa-eye" title='View' />
                            </a>
                            { (item.incident_id == null || item.incident_status == 'ONREVISED') &&
                                <>
                                    <a
                                        onClick={onEdit(item)}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'green',
                                            display: 'inline-block',
                                            marginRight: 20
                                        }}
                                    >
                                        <i className="fas fa-edit" title='Edit' />
                                    </a>
                                    <a
                                        onClick={onEditImage(item)}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'green',
                                            display: 'inline-block',
                                            marginRight: 20
                                        }}
                                    >
                                        <i className="far fa-images" title='Edit Image' />
                                    </a>
                                    <a
                                        onClick={onRemove(item)}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'maroon',
                                            display: 'inline-block',
                                            marginRight: 20
                                        }}
                                    >
                                        <i className="fas fa-trash" title='Remove' />
                                    </a>
                                </>
                            }
                        </div>
                    </div>
                );
            },
        },
        // {
        //     id: 9,
        //     title: 'Approval',
        //     render: item => {
        //         return (
        //             <div className='row'>
        //                 {/* { (item.status != 3) &&
        //                     <div>
        //                         { item.status == 0 &&
        //                             <a
        //                                 onClick={(e) => onChangeStatus(e, item.id, item.docnumber, "review_user")}
        //                                 style={{
        //                                     cursor: 'pointer',
        //                                     color: 'green',
        //                                     display: 'inline-block',
        //                                     marginRight: 20,
        //                                     marginLeft: 10
        //                                 }}
        //                             >
        //                                 <i className="fas fa-check" title='Review' />
        //                             </a>
        //                         }
        //                         { (item.status == 1 && item.next_approval_nik == userInfo.nik) &&
        //                             <>
        //                                 <a
        //                                     onClick={(e) => onChangeStatus(e, item.id, item.docnumber, "review_spv")}
        //                                     style={{
        //                                         cursor: 'pointer',
        //                                         color: 'green',
        //                                         display: 'inline-block',
        //                                         marginRight: 20,
        //                                         marginLeft: 10
        //                                     }}
        //                                 >
        //                                     <i className="fas fa-check" title='Approve' />
        //                                 </a>
        //                                 <a
        //                                     onClick={(e) => onChangeStatus(e, item.id, item.docnumber, "reject")}
        //                                     style={{
        //                                         cursor: 'pointer',
        //                                         color: 'maroon',
        //                                         display: 'inline-block',
        //                                         marginRight: 20,
        //                                         marginLeft: 5
        //                                     }}
        //                                 >
        //                                     <i className="fas fa-times" title='Reject' />
        //                                 </a>
        //                             </>
        //                         }
        //                         { (item.status == 2 && item.next_approval_nik == userInfo.nik) &&
        //                             <>
        //                                 <a
        //                                     onClick={(e) => onChangeStatus(e, item.id, item.docnumber, "approve")}
        //                                     style={{
        //                                         cursor: 'pointer',
        //                                         color: 'green',
        //                                         display: 'inline-block',
        //                                         marginRight: 20,
        //                                         marginLeft: 10
        //                                     }}
        //                                 >
        //                                     <i className="fas fa-check" title='Approve' />
        //                                 </a>
        //                                 <a
        //                                     onClick={(e) => onChangeStatus(e, item.id, item.docnumber, "reject")}
        //                                     style={{
        //                                         cursor: 'pointer',
        //                                         color: 'maroon',
        //                                         display: 'inline-block',
        //                                         marginRight: 20,
        //                                         marginLeft: 5
        //                                     }}
        //                                 >
        //                                     <i className="fas fa-times" title='Reject' />
        //                                 </a>
        //                             </>
        //                         }
        //                     </div>
        //                 } */}
        //             </div>
        //         );
        //     },
        // },
    ];

    const onAddData = () => {
        navigate("add");
    }

    const  propsTable = { order: 'id', direction: 'desc', columns, getData: getIqcAll };

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage IQC</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">IQC</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='card-title'><i className='fa fa-tools' /> IQC List</div>
                                </div>
                                <div className='card-body' style={{overflowX: "auto"}}>
                                    <MTable ref={mTable} {...propsTable} showIndex={true} showAddButton={true} onAddData={onAddData}/>
                                </div>
                                <div className='card-footer'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Dialog header="Approval Detail" visible={show} resizable={false} onHide={() => setShow(false)} breakpoints={{'960px': '1115px'}} style={{width: '1115px'}}>
                <iframe id="approval-iframe" style={{width: '100%', height: '610px', border: 'none'}}></iframe>
                <div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'20%'}>Approval</th>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'15%'}>Action</th>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'20%'}>Name</th>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'15%'}>Time</th>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'30%'}>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {histories.map(history => (
                                <tr>
                                    <td>{history.task_name}</td>
                                    <td>{history.action_name}</td>
                                    <td>{history.task_username}</td>
                                    <td style={{whiteSpace: 'nowrap'}}>{history.action_date}</td>
                                    <td>{history.note == null ? `-` : history.note}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Dialog>
        </div>
    );
}

export default IncomingScreen;