import React, { Component, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import MTable from '../../Components/MTable/MTable';
import Switch from '@mui/material/Switch';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { getItemByPo, getPoAll } from '../../Service/SapService';
import { getInspReportAll } from '../../Service/InspReportService';
const { $ } = window;
const localState = {};

const LSBCAddScreen = () => {
    const navigate = useNavigate();
    const { pageState, dataid } = useParams();
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const [ poNumber, setPoNumber ] = useState(null);
    const [ poList, setPoList ] = useState([]);
    const [ itemCodes, setItemCodes ] = useState([]);
    const [ inspReport, setInspReport ] = useState([]);
    const [ iqcData, setIqcData ] = useState({
        id_aql: 0,
        docnumber: "",
        inspdate: "",
        title: "",
        ponumber: "",
        grnumber: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        lotorder: "",
        lotqty: 0,
        serialno: "",
        assyproc: "",
        insprule: "",
        inspqty: 0,
        objtype: 0,
        docentry: 0,
        linenum: 0,
        whfrom: "",
        whdest: "",
        is_changed: false,
        changenote: "",
        conclusion: "",
        inspstatus: 0
    });

    const onSubmit = data => {

    }

    const onGoback = () => {
        navigate(-1);
    }

    const getPoList = () => {
        getPoAll().then(res => {
            setPoList(res.data);
        });
    }

    const getItemCodeByPo = (ponumber) => {
        getItemByPo(ponumber).then(res => {
            setItemCodes(res.data);
        });
        reset();
    }

    const getDataByItemCode = (itemcode) => {
        if(poNumber != null){
            getItemByPo(poNumber, itemcode).then(res => {
                setIqcData({...iqcData, 
                    inspdate: res.data[0].CreateDate.split(" ")[0],
                    itemcode: res.data[0].itemcode,
                    itemname: res.data[0].model,
                    itemdesc: res.data[0].Dscription,
                    lotorder: res.data[0].LotNumber,
                    lotqty: res.data[0].PO_Qty.split(".")[0],
                    objtype: res.data[0].ObjType,
                    docentry: res.data[0].DocEntry,
                    linenum: res.data[0].LineNum,
                    whfrom: res.data[0].WhsCode
                });
            });
        }
    }

    const reset = () => {
        setIqcData({
            id_aql: 0,
            docnumber: "",
            inspdate: "",
            title: "",
            ponumber: "",
            grnumber: "",
            itemcode: "",
            itemname: "",
            itemdesc: "",
            lotorder: "",
            lotqty: 0,
            serialno: "",
            assyproc: "",
            insprule: "",
            inspqty: 0,
            objtype: 0,
            docentry: 0,
            linenum: 0,
            whfrom: "",
            whdest: "",
            is_changed: false,
            changenote: "",
            conclusion: "",
            inspstatus: 0
        });
    }

    const judgementTemplate = (options) => {
        return <select className={`form-control judge_`+options.inspcategcode} onChange={(e) => {
            inspReport.filter(insp => insp.id == options.id).map((insp) => {
                insp.judgement = e.target.value;
            });
        }}>
            <option value="" selected={options.judgement == "" ? true : false}>-</option>
            <option value="OK" selected={options.judgement == 'OK' ? true : false}>OK</option>
            <option value="NG" selected={options.judgement == 'NG' ? true : false}>NG</option>
        </select>;
    }

    const aktualTemplate = (options) => {
        return <input onChange={(e) => {
            inspReport.filter(insp => insp.id == options.id).map((insp) => {
                insp.standard = e.target.value;
            })}} className='form-control' value={options.standard}/>
    }

    const getInspReportByItem = (itemcode) => {
        getInspReportAll({'filter':'code:'+itemcode}).then(res => {
            res.data.data.map((value) => {
                value.judgement = "";
            });

            setInspReport(res.data.data);
        });
    }

    const headerTemplate = (data) => {
        return (
            <div style={{display: 'flex'}}>
                <h6 style={{marginTop: '8px'}}>{data.inspcategname}</h6>
                <select className='form-control' onChange={(e) => {
                    inspReport.filter(insp => insp.inspcategname == data.inspcategname).map((insp) => {
                        insp.judgement = e.target.value;
                    });
                    var judges = document.getElementsByClassName('judge_'+data.inspcategcode);
                    for (var i = 0; i < judges.length; ++i) {
                        judges[i].value = e.target.value;
                    }
                }} style={{ width: '8.5%', marginLeft: 'auto', minWidth: '69px' }}>
                    <option value="">-</option>
                    <option value="OK">OK</option>
                    <option value="NG">NG</option>
                </select>
            </div>
        );
    }

    let headerColumnGroup = <ColumnGroup>
        <Row>
            <Column field="no" header="No" alignHeader="center" style={{ width: '5%' }} rowSpan={2}/>
            <Column field="name" header="Item Pemeriksaan" alignHeader="center" rowSpan={2}/>
            <Column field="specification" header="Spesifikasi" alignHeader="center" body={inspReport.itemcode} rowSpan={2}/>
            <Column header="Hasil" alignHeader="center" colSpan={2} />
        </Row>
        <Row>
            <Column header="Aktual" align="center" alignHeader="center" field="standard" style={{ width: '20%' }}/>
            <Column header="Judge" align="center" alignHeader="center" field="judgement" style={{ width: '10%', minWidth: '85px' }}/>
        </Row>
    </ColumnGroup>;
    
    useEffect(() => {
        getPoList();
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Iqc</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Iqc</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title'><i className='fa fa-tools' /> {pageState && pageState.charAt(0).toUpperCase() + pageState.slice(1)} Add Iqc</div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>GR - PO</label>
                                            <div>
                                                <Dropdown value={poNumber} options={poList} {...register("poNumber", { required: 'Ponumber is required!' })} 
                                                    onChange={(e) => {setPoNumber(e.target.value); getItemCodeByPo(e.target.value);}} optionLabel="ponumber" optionValue="ponumber" filter filterBy="ponumber" 
                                                    style={{width: '100%', height: '37px'}} />
                                                {errors.poNumber && (<span className='text-danger'>{errors.poNumber.message}</span>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Model</label>
                                            <select className='form-control' {...register("itemcode", { required: 'Item code is required!' })} onChange={e => {setIqcData({...iqcData, itemcode: e.target.value}); getDataByItemCode(e.target.value); getInspReportByItem(e.target.value);}} >
                                                <option value="0" selected={iqcData.itemcode == 0 ? true : false}></option>
                                                {(itemCodes || []).map((e, i) => <option key={'key_itemcode_' + i} value={e.itemcode} selected={iqcData.itemcode == e.itemcode ? true : false}>{e.itemcode + ` (`+e.model+`)`}</option>)}
                                            </select>
                                            {errors.id_category && (<span className='text-danger'>{errors.id_category.message}</span>)}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-order'>Serial</label>
                                            <input id="lot-order" className='form-control' value={iqcData.serialno} onChange={(e) => setIqcData({...iqcData, serialno: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Assy. Process</label>
                                            <select className='form-control' {...register("assyproc", { required: 'Assy. Process is required!' })} onChange={e => setIqcData({...iqcData, assyproc: e.target.value})} >
                                                <option value="0" selected={iqcData.assyproc == 0 ? true : false}></option>
                                                <option value="CBU" selected={iqcData.assyproc == 'CBU' ? true : false}>CBU</option>
                                                <option value="CKD" selected={iqcData.assyproc == 'CKD' ? true : false}>CKD</option>
                                                <option value="SKD" selected={iqcData.assyproc == 'SKD' ? true : false}>SKD</option>
                                            </select>
                                            {errors.id_category && (<span className='text-danger'>{errors.id_category.message}</span>)}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='tanggal-uji'>Tanggal Uji</label>
                                            <input type="date" id="tanggal-uji" className='form-control' value={iqcData.inspdate} onChange={(e) => setIqcData({...iqcData, inspdate: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='tanggal-tiba'>Tanggal Tiba</label>
                                            <input type="date" id="tanggal-tiba" className='form-control' placeholder='Iqcing Name' value={iqcData.inspdate} onChange={(e) => setIqcData({...iqcData, inspdate: e.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-order'>Lot Order</label>
                                            <input id="lot-order" className='form-control' value={iqcData.lotorder} onChange={(e) => setIqcData({...iqcData, lotorder: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-qty'>Lot Qty</label>
                                            <input type="number" min="0" id="lot-qty" className='form-control' value={iqcData.lotqty} onChange={(e) => setIqcData({...iqcData, lotqty: e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>WH Prod</label>
                                            <select className='form-control' {...register("whfrom", { required: 'WH Prod is required!' })} onChange={e => setIqcData({...iqcData, whfrom: e.target.value})} >
                                                <option value="0" selected={iqcData.whfrom == 0 ? true : false}></option>
                                                <option value="WH-KWPRD" selected={iqcData.whfrom == 'WH-KWPRD' ? true : false}>[WH-KWPRD] Warehouse Karawaci</option>
                                                <option value="WH-TGPRD" selected={iqcData.whfrom == 'WH-TGPRD' ? true : false}>[WH-TGPRD] Warehouse Tangerang</option>
                                            </select>
                                            {errors.id_category && (<span className='text-danger'>{errors.id_category.message}</span>)}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>WH FG</label>
                                            <select className='form-control' {...register("whdest", { required: 'WH FG is required!' })} onChange={e => setIqcData({...iqcData, whdest: e.target.value})} >
                                                <option value="0" selected={iqcData.whdest == 0 ? true : false}></option>
                                                <option value="WH-KWFGA" selected={iqcData.whdest == 'WH-KWFGA' ? true : false}>[WH-KWFGA] Warehouse Finished Goods Grade A Karawaci</option>
                                                <option value="WH-TGFGA" selected={iqcData.whdest == 'WH-TGFGA' ? true : false}>[WH-TGFGA] Warehouse Finished Goods Grade A Tangerang</option>
                                            </select>
                                            {errors.id_category && (<span className='text-danger'>{errors.id_category.message}</span>)}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <label>Perubahan</label>
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="ada" name="perubahan" value="true" onChange={(e) => setIqcData({...iqcData, is_changed: true})} checked={iqcData.is_changed == true} />
                                                <label htmlFor="ada">Ada</label>
                                            </div>
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="tidak" name="perubahan" value="false" onChange={(e) => setIqcData({...iqcData, is_changed: false})} checked={iqcData.is_changed == false} />
                                                <label htmlFor="tidak">Tidak</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-order'>Informasi</label>
                                            <textarea id="lot-order" className='form-control' value={iqcData.changenote} onChange={(e) => setIqcData({...iqcData, changenote: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-qty'>Kesimpulan</label>
                                            <textarea id="lot-qty" className='form-control' value={iqcData.conclusion} onChange={(e) => setIqcData({...iqcData, conclusion: e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                        <div className='form-group'>
                                            <label htmlFor='is-active'>List Report</label>
                                            <div>
                                                <DataTable value={inspReport} size="small" responsiveLayout="scroll"
                                                    headerColumnGroup={headerColumnGroup} alignHeader="center" align="center"
                                                    rowGroupMode="subheader" groupRowsBy="inspcategname" rowGroupHeaderTemplate={headerTemplate}>
                                                    <Column field="no" body={(data, options) => options.rowIndex + 1} align="center"/>
                                                    <Column field="specification" align="center"/>
                                                    <Column field="standard" align="center"/>
                                                    <Column field="standard" align="center" body={aktualTemplate}/>
                                                    <Column field="judgment" align="center" body={judgementTemplate}/>
                                                </DataTable>
                                            </div>
                                        </div>
                                        <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                                <button type='submit' className='btn btn-dark' style={{ width: 100 }}><i className='fa fa-save' /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer'></div>
                        </div>
                    </form>
                </div>

            </section>
        </div>
    );
}

export default LSBCAddScreen;