import axios from 'axios';
import { handleResponse } from './HelperService';

//test
const getInspItemAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/inspect-item/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getInspItemById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/inspect-item/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createInspItem = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/inspect-item/create`, payload);
  return handleResponse(response, onSuccess, onError);
};

const updateInspItem = (payload, onSuccess, onError) => {
  const id = payload.get('id');
  const response = axios.put (`v1/admin/inspect-item/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const changeStatusInspItem = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/inspect-item/delete/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteInspItem = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/inspect-item/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getInspItemAll, getInspItemById, createInspItem, updateInspItem, changeStatusInspItem, deleteInspItem};
