import axios from 'axios';
import { handleResponse } from "../Utils/Utils";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const msAuthUser = (payload, onSuccess, onError) => {
  const response = axios.post("v1/mslogin", payload, config);
  return handleResponse(response, onSuccess, onError);
};