import axios from 'axios';
import { handleResponse } from './HelperService';

const getInspFfrAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/ffr/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getInspFfrById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/ffr/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createInspFfr = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/ffr/create`, payload);
  return handleResponse(response, onSuccess, onError);
};

const updateInspFfr = (payload, onSuccess, onError) => {
  const id = payload.id;
  const response = axios.put (`v1/admin/ffr/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const changeStatusInspFfr = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/ffr/delete/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteInspFfr = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/ffr/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getInspFfrAll, getInspFfrById, createInspFfr, updateInspFfr, changeStatusInspFfr, deleteInspFfr};
