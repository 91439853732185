import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../../Redux/Action/AuthAction';
import { Dropdown } from 'primereact/dropdown';
import { getAllCompanies } from '../../Service/UserService';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
const NavBar = () => {
    let { userInfo } = useSelector(state => state.auth);
    const isMFI = localStorage.getItem('selectedCompany');

    if (!userInfo) {
        userInfo = {}
    }

    const dispatch = useDispatch();

    const signOut = () => {
        console.log('signOut invoked');
        dispatch(logout());
    }

    const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('selectedCompany') || null);
    const [companies, setCompanies] = useState([]);
    const [visible, setVisible] = useState(false);

    const getCompanies = () => {
        getAllCompanies().then(res => {
            setCompanies(res.data.data);
        });
    }

    const handleChange = (e) => {
        const company = e.target.value.code;
        setSelectedCompany(e.value);
        localStorage.setItem('selectedCompany', company);
        setVisible(false);
        window.location.href = '/';
    }

    useEffect(() => {
        const checkCompany = localStorage.getItem('selectedCompany');
        if (checkCompany) {
            getCompanies();
        } else {
            setVisible(true);
            getCompanies();
        }
    }, [selectedCompany])

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ zIndex: 100 }}>
            <div className="card flex justify-content-center">
                <Dialog header="Select Company" visible={visible} style={{ width: '30vw', height: '20vw' }} onHide={() => setVisible(false)} closable={false}>
                    <div className="card flex justify-content-center">
                        <Dropdown value={selectedCompany} onChange={handleChange} options={companies} optionLabel="name" 
                            placeholder="Select a Company" className="w-full md:w-14rem" />
                    </div>
                </Dialog>
            </div>
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Notifications Dropdown Menu */}
                <li className="nav-item dropdown">
                    <div className='row' style={{ gap:20 }}>
                        <div className="card flex justify-content-center">
                            <Dropdown value={selectedCompany} onChange={handleChange} options={companies} optionLabel="name" 
                                placeholder={isMFI == 'MFI' ? 'Modena Forma Indonesia' : 'Modena Indonesia'} className="w-full md:w-14rem"/>
                        </div>

                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-user mr-2" />
                            <span className="">{userInfo && userInfo.fullname}</span>
                        </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <div className='d-flex flex-column align-items-center mt-2 mb-2' >
                            <div className='mt-3 mb-3'>
                                <img src="dist/img/avatar5.png" alt='A' className='img-circle elevation-2' style={{ width: 80, height: 80 }} />
                            </div>
                            <div style={{ fontWeight: 600 }}>{userInfo && userInfo.fullname}</div>
                            <div style={{ fontSize: 12, margin: '0 24px', textAlign: 'center' }}>{userInfo.role}</div>
                            <div className='mb-3' style={{ fontSize: 14, margin: '0 24px', textAlign: 'center', fontWeight: 600 }}>{`Office - ${userInfo.office}`}</div>
                        </div>
                        <div className="dropdown-divider" />
                        <div className='d-flex justify-content-center mt-2 mb-2'>
                            <button type='button' className='btn btn-sm mr-2' style={{ width: 120 }}><i className='fa fa-user' /> Profile</button>
                            <div style={{ width: 1, background: '#ccc', height: 30 }} />
                            <button type='button' className='btn btn-sm' style={{ width: 120 }} onClick={signOut}><i className='fas fa-sign-out-alt' /> Sign Out</button>
                        </div>
                    </div>
                    </div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                        <i className="fas fa-expand-arrows-alt" />
                    </a>
                </li>
            </ul>
        </nav>
    )

}

export default NavBar;
