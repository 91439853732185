import React, { Component, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPlanProdById, changePlanProdStatus } from '../../Service/PlanProdService';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import '../../../src/App.css';
const { $ } = window;
const localState = {};

const PlanProdViewScreen = (props) => {
    // console.log(props);
    const navigate = useNavigate();
    const { id } = useParams();
    const [show, setShow] = useState(false);
    let { userInfo } = useSelector(state => state.auth);
    const [comment, setComment] = useState("");
    const [action, setAction] = useState(1);
    const [processing, setProcessing] = useState(false);

    const [ planProdData, setplanProdData ] = useState({
        id: 1,
        productiondate: null,
        ponumber: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        itemmodel: "",
        lotorder: "",
        transferqty: "",
        assytype: "",
        planpriority: "",
        statusqc: "",
        destinationwhs: "",
        statusititr: "",
        companycode: "",
        st_ave: 0,
        operator: 0,
        target: 0,
        prodqty: 0,
        prodgroup: "",
        processmin: 0,
        prepmin: 0,
        totprocessmin: 0,
        statusapproval: "",
        itemstock: {}
    });

    const onGoback = () => {
        navigate(-1);
    }

    const getPlanProdData = (id) => {
        getPlanProdById(id).then(res => {
            setplanProdData(res.data);
        });
    }
    
    
// console.log(planProdData.incident_id, "ini planProdData");
    const renderFooter = () => {
        return (
            <div>
                <button 
                    type='button' className='btn btn-danger' title='Cancel'
                    style={{ whiteSpace: 'nowrap' }} 
                    onClick={(e) => setShow(false)}>
                        <i className='fa fa-ban' /> Cancel
                </button>
                <button 
                    type='button' className='btn btn-primary' title='Submit'
                    style={{ marginRight: 10, whiteSpace: 'nowrap' }} 
                    onClick={(e) => onChangeStatus(e, planProdData.id, planProdData.incident_id)}
                    >
                        <i className='fa fa-check' /> Submit
                </button>
            </div>
        );
    }
    
    const onChangeStatus = (e, id, incident_id) => {
        e.preventDefault();
        setShow(false);
        Swal.fire({
            title: action == 1 ? 'Are you sure want to approve data ?' : 'Are you sure want to revise data ?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setProcessing(true);
                const body = {
                    "incident_id": incident_id,
                    "action": action,
                    "name": userInfo && userInfo.fullname,
                    "nik" : userInfo && userInfo.nik,
                    "url" : window.location.href,
                    // "docnumber": docnumber,
                    "comment": comment
                };
               
                changePlanProdStatus(id, body).then(res => {
                    setProcessing(false);
                    if (res.status == 200 || res.status == 201) {
                        Swal.fire({
                            icon: 'success',
                            title: action == 1 ? 'Approve data success !' : 'Reject data success !',
                            text: 'Data has been changed!'
                        });
                        
                        navigate("/planprod");
                    }
                }).catch(({response: { data } }) => {
                    setProcessing(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Approval action fail',
                        text: 'Data can not be changed! (' + data.message + ')'
                    });
                });
            }
        });
    }

    

    useEffect(() => {
        getPlanProdData(id);
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Planning Production</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Planning Production</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                                <div className='card-title w-100'>
                                <i className='fa fa-tools' /> View Detail Planning Production
                                <div className='d-flex float-right' style={{gap: 20}}>
                                    {planProdData.assytype === "CBU" ? (
                                        <></>
                                    ) : (!planProdData.statusapproval || planProdData.statusapproval == 'ONREVISED' ? (
                                        <button type="button" class="btn px-4" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#41C9E2"}}
                                        onClick={(e) => {setAction(1); setShow(true);}}
                                        >Request Approval</button>
                                    ) : ((planProdData.statusapproval == 'ONPROGRESS' || planProdData.statusapproval == 'ONREVISED') && planProdData.active_task_nik == userInfo.nik) ? (
                                        <>
                                        <button type="button" class="btn btn-success px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#54B435"}} onClick={(e) => {setAction(1); setShow(true);}}>Approve</button>
                                        <button type="button" class="btn btn-danger px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#E72929"}} onClick={(e) => {setAction(2); setShow(true);}}>Revise</button>
                                        </>
                                    ) : (<></>)
                                )
                                }
                                    {/* <button type="button" class="btn px-4" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#41C9E2"}}>Request Approval</button>
                                    <button type="button" class="btn btn-success px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#54B435"}}>Approve</button>
                                    <button type="button" class="btn btn-danger px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#E72929"}}>Revise</button> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='d-flex float-right'>
                                
                                </div>
                            </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Production Date</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.productiondate}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Status QC</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.statusqc}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Planning Priority</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.planpriority}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Item Description</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.itemdesc}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Warehouse Stock</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.itemstock == null ? 0 : planProdData.itemstock.instock}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Production QTY</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.prodqty}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Group</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.prodgroup}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Process Time / Min</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.processmin}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Total Process Time / Min</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.totprocessmin}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Lot Order</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.lotorder}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Destination Warehouse</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.destinationwhs}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Item Code</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.itemcode}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Model</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.itemname}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Target</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.target}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>ST AVE</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.st_ave}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Operator</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.operator}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Preparation Time / Min</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.prepmin}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                        </div>
                            
                            <div className='row'>
                                    <div className='col-12 mt-2'>
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <Dialog header={`Approval Comments`} visible={show} resizable={false} onHide={() => setShow(false)} footer={renderFooter()} style={{width: '500px'}}>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <InputTextarea className='form-control' placeholder="Comment" value={comment} onChange={(e) => setComment(e.target.value)} rows={4} />
                                </div>
                            </div>
                        </div>
                    </Dialog>   
                </div>

            </section>
        </div>
    );
}

export default PlanProdViewScreen;