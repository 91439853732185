import axios from 'axios';
import { handleResponse } from './HelperService';

const getIpqcAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/ipqc-report/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getIpqcById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/ipqc-report/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createIpqc = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/ipqc-report/create`, payload);
  return handleResponse(response, onSuccess, onError);
};

const updateIpqc = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/ipqc-report/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteIpqc = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/ipqc-report/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const changeIpqcStatus = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/ipqc-report/change-status/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

export {getIpqcAll, getIpqcById, createIpqc, updateIpqc, changeIpqcStatus, deleteIpqc};
