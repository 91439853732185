import axios from 'axios';
import { handleResponse } from './HelperService';

const getSupplierList = (onSuccess, onError) => {
  const response = axios.get('v1/admin/sap/get-supplier');
  return handleResponse(response, onSuccess, onError);
};

const getItemCodeAll = (onSuccess, onError) => {
  const response = axios.get('v1/admin/sap/get-item');
  return handleResponse(response, onSuccess, onError);
};

const getPartSAPAll = (params, onSuccess, onError) => {
  const response = axios.get('v1/admin/sap/get-part-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getPoAll = (onSuccess, onError) => {
  const response = axios.get('v1/admin/sap/get-po');
  return handleResponse(response, onSuccess, onError);
};

const getModelAll = (onSuccess, onError) => {
  const response = axios.get('v1/admin/sap/get-model');
  return handleResponse(response, onSuccess, onError);
};

const getItemByPo = (ponumber, itemcode = null, lotorder = null, onSuccess, onError) => {
  const response = axios.get(`v1/admin/sap/get-item-po?ponumber=${ponumber}&itemcode=${itemcode}&lotorder=${lotorder}`);
  return handleResponse(response, onSuccess, onError);
};

const getPoByModel = (ponumber, lotorder, onSuccess, onError) => {
  const response = axios.get(`v1/admin/sap/get-po-mfi?model=${ponumber}&lotorder=${lotorder}`);
  return handleResponse(response, onSuccess, onError);
}

export {getSupplierList, getItemCodeAll, getPoAll, getItemByPo, getPartSAPAll, getModelAll, getPoByModel};
