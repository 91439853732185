import axios from 'axios';
import { getMultipartOptions, handleResponse } from './HelperService';

const getInspSopAll = (params, onSuccess, onError) => {
  const response = axios.get (`v1/admin/sop/get-all`, {params});
  return handleResponse(response, onSuccess, onError);
};

const getInspSopById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/sop/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createInspSop = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/sop/create`, payload, getMultipartOptions(axios));
  return handleResponse(response, onSuccess, onError);
};

const updateInspSop = (payload, onSuccess, onError) => {
  const id = payload.get('id');
  const response = axios.put (`v1/admin/sop/update/${id}`, payload, getMultipartOptions(axios));
  return handleResponse(response, onSuccess, onError);
};

const changeStatusInspSop = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/sop/delete/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteInspSop = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/sop/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getInspSopAll, getInspSopById, createInspSop, updateInspSop, changeStatusInspSop, deleteInspSop};
