import axios from 'axios';
import { handleResponse } from './HelperService';

const getInspReportAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/inspect-report/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getInspReportAllMFI = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/inspect-report/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};
const getInspReportById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/inspect-report/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createInspReport = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/inspect-report/create`, payload, axios);
  return handleResponse(response, onSuccess, onError);
};

const updateInspReport = (payload, onSuccess, onError) => {
  const id = payload.get('id');
  const response = axios.put (`v1/admin/inspect-report/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const changeStatusInspReport = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/inspect-report/delete/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteInspReport = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/inspect-report/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getInspReportAll, getInspReportById, createInspReport, updateInspReport, changeStatusInspReport, deleteInspReport, getInspReportAllMFI};
