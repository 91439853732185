import React, { Component, useEffect, useState, useRef } from 'react';
import { changeStatusInspSop, createInspSop, deleteInspSop, getInspSopAll, updateInspSop } from '../../../Service/InspSopService';
import { getItemCodeAll } from '../../../Service/SapService';
import { useForm, Controller, handleSubmit } from 'react-hook-form';
import Swal from 'sweetalert2';
import MTable from '../../../Components/MTable/MTable';
import Switch from '@mui/material/Switch';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Uploader } from 'rsuite';
const { $ } = window;
let processingId = -1;

const InspSopScreen = () => {
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm();
    const mTable = useRef();
    const [state, setState] = useState({
        processing: false
    });
    const [inspSop, setInspSop] = useState({
        id: null,
        appcode: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        description: "",
        sop_file: [],
        is_active: true
    });
    const [itemCodes, setItemCodes] = useState([]);
    const [showDialog, setShowDialog] = useState(false);

    const columns = [
        { id: 1, title: 'App', field: 'appcode', sortable: true },
        { id: 2, title: 'Item Code', field: 'itemcode', sortable: true },
        { id: 3, title: 'Item Desc', field: 'itemdesc', sortable: true },
        { id: 4, title: 'Description', field: 'description', sortable: true },
        {
            id: 5,
            title: 'Status',
            sortable: true,
            render: item => {
                return (
                    <Switch color="warning" checked={item.is_active} onChange={(e) => changeStatus(item.id, e.target.checked)} />
                );
            },
        },
        {
            id: 6,
            title: 'File',
            sortable: true,
            render: item => {
                return (
                    !item.file_url.endsWith('/') ?
                    <div style={{marginLeft: '7px', fontSize: '20px'}}>
                        <a href={item.file_url} target="_blank" style={{color: 'black'}} title="File Download">
                            <i className="fas fa-file-download"></i>
                        </a>
                    </div> : ``);
            },
        },
        {
            id: 7,
            title: '',
            sortable: true,
            render: item => {
                return (
                    <div>
                        <a onClick={onEdit(item)} style={{ cursor: 'pointer', color: 'green', display: 'inline-block', marginRight: 15 }}>
                            <i className="fas fa-edit" />
                            <span style={{ marginLeft: 10 }}>Edit</span>
                        </a>
                        {/* <a onClick={onRemove(item)} style={{ cursor: 'pointer', color: 'maroon', display: 'inline-block' }}>
                            <i className="fas fa-trash" />
                            <span style={{ marginLeft: 10 }}>Remove</span>
                        </a> */}
                    </div>);
            },
        },
    ];

    const propsTable = { columns, getData: getInspSopAll };

    const removeData = id => {
        deleteInspSop(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

    const changeStatus = (id, status) => {
        const body = {
            is_active: status
        }

        changeStatusInspSop(id, body).then(res => {
            console.log(res);
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Change status success',
                    text: 'Data has been changed!'
                }).then(res => { mTable.current.refresh(); });
            }
        }).catch((err) => {
            console.log(err);
            if (err.response.status == 400) {
                Swal.fire({
                    icon: 'error',
                    title: 'Change status fail',
                    text: err.response.data.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Change status fail',
                    text: 'Data can not be changed!'
                });
            }
        });
    }

    const onEdit = item => () => {
        reset(item);
        setInspSop(item);
        if(itemCodes.length == 0) getItemCode(); 
        setShowDialog(true);
    }

    const onRemove = item => () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(item.id);
            }
        });
    }

    const startProcessing = () => {
        processingId = setTimeout(() => {
            setState({...state, processing: true});
        }, 150);
    }

    const stopProcessing = () => {
        clearTimeout(processingId);
        setState({...state, processing: false});
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        for (var key in inspSop) {
            if(key != "created_by" && key != "created_on" && key != "updated_by" && key != "updated_on")
                formData.append(key, inspSop[key]);
        }
        startProcessing();
        const response = inspSop.id ? updateInspSop(formData) : createInspSop(formData);
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                setShowDialog(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Save data success',
                    text: 'Data has been saved!'
                }).then(r => { mTable.current.refresh(); onReset() })
            }

            stopProcessing();
        }).catch((err) => {
            if (err.response.status == 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: err.response.data.message
                });
            } else if (err.response.status == 400) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Save data error!'
                });
            }
            stopProcessing();
        });

        setShowDialog(false);
    }

    const onReset = () => {
        reset({
            id: null,
            appcode: "",
            itemcode: "",
            itemname: "",
            itemdesc: "",
            description: "",
            sop_file: [],
            is_active: true
        });

        setInspSop({
            id: null,
            appcode: "",
            itemcode: "",
            itemname: "",
            itemdesc: "",
            description: "",
            sop_file: [],
            is_active: true
        });
    }

    const getItemCode = () => {
        getItemCodeAll().then(res => {
            setItemCodes(res.data);
        });
    }

    const onAddData = () => {
        if(inspSop.id && inspSop.id != null) onReset();
        if(itemCodes.length == 0) getItemCode(); 
        setShowDialog(true);
    }

    const { processing } = state;

    useEffect(() => {
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Inspection SOP </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Inspection SOP Page</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-tags mr-1" />
                                        List Inspection SOP
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <MTable ref={mTable} {...propsTable} showIndex={true} showAddButton={true} onAddData={onAddData} />
                                </div>
                            </div>
                        </div>
                        <Dialog header={inspSop.id && inspSop.id != null ? 'Edit Inspection SOP' : 'New Inspection SOP'} visible={showDialog} onHide={() => setShowDialog(false)} breakpoints={{'960px': '120vw'}} style={{width: '60vw'}} >
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label>Category</label>
                                        <select className='form-control' value={inspSop.appcode} onChange={e => setInspSop({...inspSop, appcode: e.target.value})} >
                                            <option value=""></option>
                                            <option value="IQC">IQC</option>
                                            <option value="IPQC">IPQC</option>
                                        </select>
                                        {errors.appcode && (<span className='text-danger'>{errors.appcode.message}</span>)}
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label>Item Code</label>
                                    <div>
                                        <Dropdown value={inspSop.itemcode} options={itemCodes} 
                                            onChange={(e) => {
                                                itemCodes.filter((item) => item.value == e.target.value).map(filter_item => (
                                                    setInspSop({...inspSop, itemcode: e.target.value, itemname: filter_item.model, itemdesc: filter_item.label})
                                                ));
                                            }}
                                            optionLabel="label" filter filterBy="label" style={{width: '100%', height: '37px'}} />
                                        {errors.code && (<span className='text-danger'>{errors.code.message}</span>)}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Item Name</label>
                                        <input id="itemname" value={inspSop.itemname} onChange={(e) => setInspSop({...inspSop, itemname: e.target.value})} className='form-control' />
                                        {errors.itemname && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.itemname.message}</span>)}
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemdesc">Item Desc</label>
                                        <input id="itemdesc" value={inspSop.itemdesc} onChange={(e) => setInspSop({...inspSop, itemdesc: e.target.value})} className='form-control' />
                                        {errors.itemdesc && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.itemdesc.message}</span>)}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="description">Description</label>
                                        <textarea id="description" value={inspSop.description} onChange={(e) => setInspSop({...inspSop, description: e.target.value})} className='form-control' />
                                        {errors.description && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.description.message}</span>)}
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label>{inspSop.id && inspSop.id != null ? 'File (isi jika ingin mengganti file)' : 'File'}</label>
                                        <Uploader
                                            autoUpload={false}
                                            listType='text'
                                            onChange={file => {
                                                if(file[0]){
                                                    setInspSop({...inspSop, sop_file: file[0].blobFile});
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* <div className='col-12 col-md-2'>
                                    <div className='form-group'>
                                        <label htmlFor="is_active">Active</label>
                                        <div>
                                            <Switch color="warning" checked={inspSop.is_active} onChange={(e) => setInspSop({...inspSop, is_active: e.target.value})} />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className='row'>
                                <div className='col-12'>
                                    <div className='form-group'>
                                        <label>{inspSop.id && inspSop.id != null ? 'File (isi jika ingin mengganti file)' : 'File'}</label>
                                        <Uploader
                                            autoUpload={false}
                                            listType='text'
                                            onChange={file => {
                                                setInspSop({...inspSop, sop_file: file[0].blobFile});
                                            }}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-12">
                                    <div className='form-group'>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                            <div style={{ width: 150, marginRight: 10 }}><button type="button" className="btn btn-block btn-outline-danger" onClick={onReset}><i className='fa fa-times'></i>  Clear</button></div>
                                            <div style={{ width: 150 }}><button type="submit" onClick={(e) => {e.preventDefault(); onSubmit();}} className="btn btn-block btn-outline-dark"><i className='fa fa-save'></i> Save</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default InspSopScreen;