import axios from 'axios';
import { getMultipartOptions, handleResponse } from './HelperService';

const getQualityIssueAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/quality/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getQualityIssueById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/quality/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const getQualityIssueSummaryByIqc = (id_iqc, onSuccess, onError) => {
  const response = axios.get (`v1/admin/quality/get-summary/${id_iqc}`);
  return handleResponse(response, onSuccess, onError);
};

const getQualityIssueByIqc = (id_iqc, onSuccess, onError) => {
  const response = axios.get (`v1/admin/quality/get-iqc/${id_iqc}`);
  return handleResponse(response, onSuccess, onError);
};

const createQualityIssue = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/quality/create`, payload, getMultipartOptions(axios));
  return handleResponse(response, onSuccess, onError);
};

const updateQualityIssue = (payload, onSuccess, onError) => {
  const id = payload.get('id');
  const response = axios.put (`v1/admin/quality/update/${id}`, payload, getMultipartOptions(axios));
  return handleResponse(response, onSuccess, onError);
};

const deleteQualityIssue = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/quality/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getQualityIssueAll, getQualityIssueById, getQualityIssueSummaryByIqc, getQualityIssueByIqc, createQualityIssue, updateQualityIssue, deleteQualityIssue};
