import Swal from 'sweetalert2';

export const updateObject = (currentObject, updatedProperties) => {
    return {
        ...currentObject,
        ...updatedProperties,
    };
};

export const updateArray = (currentArray, updatedProperties) => {
    return [...currentArray, updatedProperties];
};

export const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};


export const setFilterAnd = (params, key, value) => {
    if (params.filterAnd) {
        const filters = params.filterAnd.split(',');
        if (filters.indexOf(`${key}:${value}`) == -1) {
            filters.push(`${key}:${value}`);
            params.filterAnd = filters.join(',');
        }
    } else {
        params.filterAnd = `${key}:${value}`;
    }
    return params;
}

export const handleResponse = (response, onSuccess, onError = err => { }) => {
    if (onSuccess) {
        response.then(onSuccess).catch(onError);
    }
    return response;
}

export const handlerFormError = (data) => {
    if (data.errors) {
        const [key] = Object.keys(data.errors || {});
        const message = data.errors[key];
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: message[0]
        });
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: data.message
        });
    }
}

export const sizeOfArray = function (array) {

    let size = 0;

    for (let key in array) {
        if (array.hasOwnProperty(key)) {
            size++;
        }
    }

    return size;
}

export const getMultipartOptions = axios => {
    const headers = axios.defaults.headers;
    return { headers: { ...headers, 'Content-Type': 'multipart/form-data' } };
}

export const isValidUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        console.error(e);
        return false;
    }
    return true;
};


export const importProductAlert = (data) => {
    return `
    <div>
        <div style="text-align:left">
            <table>
                <tr>
                    <td style='padding: 5px'>Created</td>
                    <td style='padding: 5px'>:</td>
                    <td style='padding: 5px'>${data.total_created || 0}</td>
                </tr>
                <tr>
                    <td style='padding: 5px'>Updated</td>
                    <td style='padding: 5px'>:</td>
                    <td style='padding: 5px'>${data.total_updated || 0}</td>
                </tr>
                <tr>
                    <td style='padding: 5px'>Category Not Found</td>
                    <td style='padding: 5px'>:</td>
                    <td style='padding: 5px'>${data.error?.category_not_found?.length || 0}</td>
                </tr>
                <tr>
                    <td style='padding: 5px'>Model Not Found</td>
                    <td style='padding: 5px'>:</td>
                    <td style='padding: 5px'>${data.error?.model_not_found?.length || 0}</td>
                </tr>
                <tr>
                    <td style='padding: 5px'>Item Not Found</td>
                    <td style='padding: 5px'>:</td>
                    <td style='padding: 5px'>${data.error?.item_not_found?.length || 0}</td>
                </tr>
            </table>
        </div>
    </div>`;
}

export const previewFile = async (file, callback) => {
  const reader = new FileReader();

  reader.onload = function (e) {
    const dataURL = e.target.result;
    callback(file, dataURL);
  };

  reader.readAsDataURL(file);
}