import axios from 'axios';
import { getMultipartOptions, handleResponse } from './HelperService';

export const genFfrExport = (_params, onSuccess, onError) => {
    const response = axios.get('v1/admin/download/ffr-master', { params: _params });
    return handleResponse(response, onSuccess, onError);
};

export const genMstExport = (_params, onSuccess, onError) => {
    const response = axios.get('v1/admin/download/mst-master', { params: _params });
    return handleResponse(response, onSuccess, onError);
};

export const getExportFile = (fileType, onSuccess, onError) => {
    const response = axios.get(`v1/admin/file/export/${fileType}`);
    return handleResponse(response, onSuccess, onError);
};

export const getImportFile = (fileType, onSuccess, onError) => {
    const response = axios.get(`v1/admin/file/import/${fileType}`);
    return handleResponse(response, onSuccess, onError);
};

export const getFileError = (fileType, onSuccess, onError) => {
    const response = axios.get(`v1/admin/file/import_error/${fileType}`);
    return handleResponse(response, onSuccess, onError);
};

export const deleteExportFile = (fileType, onSuccess, onError) => {
    const response = axios.delete(`v1/admin/file/export/${fileType}`);
    return handleResponse(response, onSuccess, onError);
};

export const deleteFileById = (id, onSuccess, onError) => {
    const response = axios.delete(`v1/admin/file/${id}`);
    return handleResponse(response, onSuccess, onError);
};

export const uploadFile = (fileType, payload, onSuccess, onError) => {
    const response = axios.post(`v1/admin/file/import/${fileType}`, payload, getMultipartOptions(axios));
    return handleResponse(response, onSuccess, onError);
};

export const executeFile = (id, action, onSuccess, onError) => {
    const response = axios.put(`v1/admin/file/${id}/${action}`);
    return handleResponse(response, onSuccess, onError);
};
