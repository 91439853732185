import React, { Component, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPlanProdById, changePlanProdStatus, changePlanProdDetailStatus } from '../../Service/PlanProdService';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import '../../../src/App.css';
import Select from 'react-select';
import { deletePlanProdDetail } from '../../Service/PlanProdService';
import Overlay from '../../Components/Overlay/Overlay';
import { getDiagram, getHistories } from '../../Service/WorkflowService';
import '../../../src/App.css';
const { $ } = window;
const localState = {};

const PlanProdViewScreen = (props) => {
    // console.log(props);
    const navigate = useNavigate();
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    let { userInfo } = useSelector(state => state.auth);
    const [comment, setComment] = useState("");
    const [action, setAction] = useState(1);
    const [processing, setProcessing] = useState(false);
    const [selectedOptionDetail, setSelectedOptionDetail] = useState([]);
    const [add, setAdd] = useState(false);
    const [planDetailID, setPlanDetailID] = useState(0);
    const [ppdIncidentId, setPpdIncidentId] = useState(0);
    const [ppdOperator, setPpdOperator] = useState(0);
    const [ppq, setPpq] = useState(0);
    const [histories, setHistories] = useState([]);
    const [ppdGroup, setPpdGroup] = useState("");
    const [ planProdData, setplanProdData ] = useState({
        id: 1,
        productiondate: null,
        ponumber: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        itemmodel: "",
        lotorder: "",
        transferqty: "",
        assytype: "",
        planpriority: "",
        statusqc: "",
        destinationwhs: "",
        statusititr: "",
        companycode: "",
        st_ave: 0,
        operator: 0,
        target: 0,
        prodqty: 0,
        prodgroup: "",
        processmin: 0,
        prepmin: 0,
        totprocessmin: 0,
        statusapproval: "",
        itemstock: {},
        openQty: 0,
    });

    const [planDetail, setPlanDetail] = useState([
        {
            id: 1,
            planpriority: '',
            productiondate: '',
            planprodqty: '',
            prodgroup: '',
            operator: '',
            target: '',
            processmin: '',
            prepmin: '',
            totprocessmin: '',
            statusapprove: '',
            statusprodsap: '',
            actualprodqty: '',
            // id_planprod: ''
          }
     ]);

    const onGoback = () => {
        navigate(-1);
    }

    const getPlanProdData = (id) => {
        getPlanProdById(id).then(res => {
            setplanProdData(res.data);
        });
    }

    const getPlanProdDataDetail = (id) => {
        getPlanProdById(id).then(res => {
            setPlanDetail(res.data.plandetail);
        });
    }

    const formattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const removeData = id => {
        deletePlanProdDetail(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { 
                    // planDetail.current.refresh();
                    const updatedPlanDetail = planDetail.filter(item => item.id !== id);
                    setPlanDetail(updatedPlanDetail); 
                });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

    const handleRowChange = (id, value, key, type = null) => {
        console.log('ini id', id);
        console.log('ini id', value);
        console.log('ini id', value);
        const updatedRows = planDetail.map(row => {
          if (row.id === id) {
            if(type === 'date'){
                return { ...row, [key]: formattedDate(value) };
            }
            return { ...row, [key]: value };
          }
          return row;
        });
        console.log('ini update', updatedRows);
        setPlanDetail(updatedRows);
      };

      const openApproval = (item) => {
        if(item != null) {
            getHistories(item).then(res => {
                setHistories(res.data.histories);
            });

            getDiagram(item).then(res => {
                document.getElementById('approval-iframe').srcdoc = res.data;
            });
        }

        setShowDetail(true);
    }
    
      const options = [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' },
        { value: 'F', label: 'F' },
      ];

      const handleChangePlanDetail = (selectOption, id) => {
        console.log('ini selected option', selectOption);
        setSelectedOptionDetail(selectOption);
        const group = selectOption.map(a => a.value).join(",");
        setPlanDetail(prevDetails => prevDetails.map(detail => {
            if (detail.id === id) {  // Assuming each detail has a unique `id`
                return {
                    ...detail,
                    prodgroup: group,
                    // selectedOptionDetail: group
                };
            }
            return detail;
        }));
        const newSelectedOption = group.split(",").map(value => {
            return options.find(option => option.value === value);
        });
        setSelectedOptionDetail(newSelectedOption);
    };

    const shouldHideAddRowButton = () => {
        if (planDetail.length === 0) return false; // Return false if no rows exist
        const lastRow = planDetail[planDetail.length - 1];
        return lastRow.statusapprove === 'ONPROGRESS' || lastRow.statusapprove === null;
    };

    const renderFooter = () => {
        return (
            <div>
                <button 
                    type='button' className='btn btn-danger' title='Cancel'
                    style={{marginRight: 10, whiteSpace: 'nowrap' }} 
                    onClick={(e) => setShow(false)}>
                        <i className='fa fa-ban' /> Cancel
                </button>
                <button 
                    type='button' className='btn btn-primary' title='Submit'
                    style={{ whiteSpace: 'nowrap' }} 
                    // onClick={(e) => onChangeStatus(e, planProdData.id, planProdData.incident_id)}
                    onClick={(e) => onChangeStatus(e, planDetailID, ppdIncidentId, ppdOperator, ppdGroup, ppq)}
                    >
                        <i className='fa fa-check' /> Submit
                </button>
            </div>
        );
    }
    
    const onChangeStatus = (e, id, incident_id, ppdOperator, ppdGroup, ppq) => {
        e.preventDefault();
        setShow(false);
        Swal.fire({
            title: action == 1 ? 'Are you sure want to approve data ?' : 'Are you sure want to revise data ?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setProcessing(true);
                const body = {
                    "incident_id": incident_id,
                    "action": action,
                    "name": userInfo && userInfo.fullname,
                    "nik" : userInfo && userInfo.nik,
                    "url" : window.location.href,
                    "role" : userInfo && userInfo.role,
                    // "docnumber": docnumber,
                    "comment": comment,
                    'ItemNo': planProdData.itemcode,
                    'ProductionOrderType': "bopotStandard",
                    'ProductionOrderOrigin': "bopooManual",
                    'Warehouse': planProdData.destinationwhs,
                    'Priority': 100,
                    'U_SOL_NOMOR_LOT': planProdData.lotorder,
                    'U_SOL_MANPOWER': ppdOperator,
                    'U_SOL_PROD_GROUP': ppdGroup,
                    'U_M_DOC_SNC': planProdData.iqc.docsnc,
                    'U_M_LIN_SNC': planProdData.iqc.linesnc,
                    'itemcode': planProdData.iqc.itemcode,
                    'ProductionOrderIssueType': "im_Manual",
                    'ItemType': "pit_item",
                    'ppdId': id,
                    'iqcHeaderId': planProdData.iqc.id,
                    'ppId' : planProdData.id,
                    'ppq' : ppq,
                    "vendor_code": planProdData.vendor_code,
                };
                changePlanProdDetailStatus(id, body).then(res => {
                    setProcessing(false);
                    if (res.status == 200 || res.status == 201) {
                        Swal.fire({
                            icon: 'success',
                            title: action == 1 ? 'Approve data success !' : 'Reject data success !',
                            text: 'Data has been changed!'
                        });
                        
                        navigate("/planprod");
                    }
                }).catch(({response: { data } }) => {
                    setProcessing(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Approval action fail',
                        text: 'Approval Process Failed! (' + data.message + ')'
                    });
                });

                // changePlanProdStatus(id, body).then(res => {
                //     setProcessing(false);
                //     if (res.status == 200 || res.status == 201) {
                //         Swal.fire({
                //             icon: 'success',
                //             title: action == 1 ? 'Approve data success !' : 'Reject data success !',
                //             text: 'Data has been changed!'
                //         });
                        
                //         navigate("/planprod");
                //     }
                // }).catch(({response: { data } }) => {
                //     setProcessing(false);
                //     Swal.fire({
                //         icon: 'error',
                //         title: 'Approval action fail',
                //         text: 'Data can not be changed! (' + data.message + ')'
                //     });
                // });
            }
        });
    }

    useEffect(() => {
        // Map over planDetail to potentially update each item's selectedOptionDetail
        const updatedPlanDetails = planDetail.map(detail => {
            if (detail.prodgroup) {
                const valueArray = detail.prodgroup.split(",");
                const newSelectedOptions = valueArray.map(value => {
                    return options.find(option => option.value === value);
                });
    
                // Check if there's a need to update selectedOptionDetail based on a deep comparison
                if (!detail.selectedOptionDetail || 
                    newSelectedOptions.length !== detail.selectedOptionDetail.length ||
                    newSelectedOptions.some((option, index) => !option || option.value !== detail.selectedOptionDetail[index]?.value)) {
                    return { ...detail, selectedOptionDetail: newSelectedOptions };
                }
            }
            return detail;
        });
    
        // Only update state if there's an actual change in any detail's selectedOptionDetail
        if (updatedPlanDetails.some((detail, index) => detail.selectedOptionDetail !== planDetail[index].selectedOptionDetail)) {
            setPlanDetail(updatedPlanDetails);
        }
    }, [planDetail, options]); // Dependency array includes both planDetail and options to cover all cases of updates

    useEffect(() => {
        getPlanProdDataDetail(id)
        getPlanProdData(id);
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                <Overlay display={processing} />
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Planning Production MFI</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Planning Production</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                                <div className='card-title w-100'>
                                <i className='fa fa-tools' /> View Detail Planning Production
                                {/* <div className='d-flex float-right' style={{gap: 20}}>
                                    {planProdData.assytype === "CBU" ? (
                                        <></>
                                    ) : (!planProdData.statusapproval || planProdData.statusapproval == 'ONREVISED' ? (
                                        <button type="button" class="btn px-4" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#41C9E2"}}
                                        onClick={(e) => {setAction(1); setShow(true);}}
                                        >Request Approval</button>
                                    ) : ((planProdData.statusapproval == 'ONPROGRESS' || planProdData.statusapproval == 'ONREVISED') && planProdData.active_task_nik == userInfo.nik) ? (
                                        <>
                                        <button type="button" class="btn btn-success px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#54B435"}} onClick={(e) => {setAction(1); setShow(true);}}>Approve</button>
                                        <button type="button" class="btn btn-danger px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#E72929"}} onClick={(e) => {setAction(2); setShow(true);}}>Revise</button>
                                        </>
                                    ) : (<></>)
                                )
                                }
                                </div> */}
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='d-flex float-right'>
                                
                                </div>
                            </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    {/* <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Production Date</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.productiondate}</span>
                                        </div>
                                    </div> */}
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Status QC</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.statusqc}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Item Description</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.itemdesc}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Destination Warehouse</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.destinationwhs}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Warehouse Stock</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.itemstock == 0 ? 0 :planProdData.itemstock.instock}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Open Qty</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.openQty}</span>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Planning Priority</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.planpriority}</span>
                                        </div>
                                    </div> */}
                                    {/* <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Group</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.prodgroup}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Process Time / Min</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.processmin}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Total Process Time / Min</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.totprocessmin}</span>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Lot Order</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.lotorder}</span>
                                        </div>
                                    </div>
                                    
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Item Code</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.itemcode}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Model</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.itemname}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>KBT QTY</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.prodqty}</span>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Target</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.target}</span>
                                        </div>
                                    </div> */}
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>ST AVE</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.st_ave}</span>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Operator</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.operator}</span>
                                        </div>
                                    </div> */}
                                    {/* <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Preparation Time / Min</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {planProdData.prepmin}</span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                        </div>

                        <div className='container-fluid'> {/* Using container-fluid for full width */}
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <div className="table-responsive"> {/* Making table scrollable on small devices */}
                                            <table className="table table-striped">
                                                <thead className="table-dark">
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Planning Priority</th>
                                                        <th>Production Date</th>
                                                        <th>Plan Production Quantity</th>
                                                        <th>Group</th>
                                                        <th>Operator</th>
                                                        <th>Target / Unit</th>
                                                        <th>Process Time / Min</th>
                                                        <th>Preparation Time / Min</th>
                                                        <th>Total Process Time / Min</th>
                                                        <th>Status Approval</th>
                                                        <th>Status Production SAP</th>
                                                        <th>Actual Production Quantity</th>
                                                        <th colSpan={3} className='action-center'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {planDetail.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    {/* <td>{row.priority}</td> */}
                                                    {/* <div className='form-group'>
                                                        <select className='form-control' value={planProdData.planpriority} onChange={(e) => setPlanProdData({...planProdData, planpriority: e.target.value})}>
                                                            <option value='' disabled>-- Select Priority --</option>
                                                            <option value='Recommended'>Recommended by System</option>  
                                                            <option value='Urgent'>Urgent</option>
                                                        </select>  
                                                    </div> */}
                                                    <td className='row-table-ppd'>
                                                        <select style={{ width: '150px' }}
                                                            value={row.planpriority}
                                                            onChange={(e) => handleRowChange(row.id, e.target.value, 'planpriority')}
                                                            // onChange={(e) => setPlanDetail({...planDetail, priority: e.target.value})}
                                                            className="form-control"
                                                            disabled
                                                        >
                                                            <option value="">Select Priority</option>
                                                            <option value="Urgent">Urgent</option>
                                                            <option value="Recommended">Recommended</option>
                                                            <option value="Normal">Normal</option>
                                                        </select>
                                                    </td>
                                                    {/* <td>{row.date}</td> */}
                                                    <input style={{ textAlign: "center", width: "100%" }} type="date" className='form-control mt-2' value={formattedDate(row.productiondate)}
                                                    onChange={(e) => handleRowChange(row.id, e.target.value, 'productiondate', 'date')}
                                                    readOnly
                                                    
                                                     />
                                                    {/* <td>{row.quantity}</td> */}
                                                    <td className='row-table-ppd'>
                                                        <input style={{ width : '80%', textAlign: "center" }} type='text' value={row.planprodqty}
                                                        onChange={(e) => handleRowChange(row.id, e.target.value, 'planprodqty')}
                                                        disabled
                                                        ></input>
                                                    </td>
                                                    {/* <td>{row.group}</td> */}
                                                    {/* {} */}
                                                    <Select
                                                        key={row.id}
                                                        isMulti
                                                        name="colors"
                                                        options={options}
                                                        className="basic-multi-select row-table-ppd"
                                                        classNamePrefix="select"
                                                        onChange={(selectedOptions) => handleChangePlanDetail(selectedOptions, row.id)}
                                                        value={row.selectedOptionDetail}
                                                        readOnly
                                                    />
                                                    {/* <td>{row.operator}</td> */}
                                                    <td className='row-table-ppd'>
                                                        <input style={{ width : '80%', textAlign: "center" }} type='number' value={row.operator} onChange={(e) => handleRowChange(row.id, e.target.value, 'operator')} readOnly disabled></input>
                                                    </td>
                                                    <td className='row-table-ppd'>
                                                        <input style={{ width: '100%', textAlign: "center" }} type='number' value={row.target} onChange={(e) => handleRowChange(row.id, e.target.value, 'target')} readOnly disabled></input>
                                                    </td>
                                                    <td className='row-table-ppd'>
                                                        <input style={{ width: '100%', textAlign: "center" }} type='number' value={row.processmin} onChange={(e) => handleRowChange(row.id, e.target.value, 'processmin')} readOnly disabled></input>
                                                    </td>
                                                    <td className='row-table-ppd' style={{ textAlign:"center" }}>
                                                        <input style={{ width: '50%', textAlign: "center"}} type='number' value={row.prepmin} onChange={(e) => handleRowChange(row.id, e.target.value, 'prepmin')} readOnly disabled></input>
                                                    </td>
                                                    <td className='row-table-ppd'>
                                                        <input style={{ width: '100%', textAlign: "center"}} type='number' value={row.totprocessmin} onChange={(e) => handleRowChange(row.id, e.target.value, 'totprocessmin')} readOnly disabled></input>
                                                    </td>
                                                    <td className='row-table-ppd'>
                                                        <input style={{ width: '120px', textAlign: "center"}} type='text' value={row.statusapprove} readOnly disabled></input>
                                                    </td>
                                                    <td className='row-table-ppd'>
                                                        <input style={{ width: '100%', textAlign: "center"}} type='text' value={row.statusprodsap }readOnly disabled></input>
                                                    </td>
                                                    <td className='row-table-ppd'>
                                                    <input style={{ width: '100%', textAlign: "center"}} type='text' value={row.actualprodqty} readOnly disabled></input>
                                                    </td>
                                                    <td>
                                                        {/* <div className='d-flex float-right' style={{gap: 20}}>
                                                            {planProdData.assytype === "CBU" ? (
                                                                <></>
                                                            ) : (!planProdData.statusapproval || planProdData.statusapproval == 'ONREVISED' ? (
                                                                <button type="button" class="btn px-4" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#41C9E2"}}
                                                                onClick={(e) => {setAction(1); setShow(true);}}
                                                                >Request Approval</button>
                                                            ) : ((planProdData.statusapproval == 'ONPROGRESS' || planProdData.statusapproval == 'ONREVISED') && planProdData.active_task_nik == userInfo.nik) ? (
                                                                <>
                                                                <button type="button" class="btn btn-success px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#54B435"}} onClick={(e) => {setAction(1); setShow(true);}}>Approve</button>
                                                                <button type="button" class="btn btn-danger px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#E72929"}} onClick={(e) => {setAction(2); setShow(true);}}>Revise</button>
                                                                </>
                                                            ) : (<></>)
                                                        )
                                                        }
                                                        </div> */}
                                                            {/* {!row.statusapprove || row.statusapprove == 'ONREVISED' ? (
                                                                <button type="button" class="btn px-4" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#41C9E2"}}
                                                                onClick={(e) => {setAction(1); setShow(true);}}
                                                                >Request Approval</button>
                                                            ) : ((row.statusapprove == 'ONPROGRESS' || row.statusapprove == 'ONREVISED') && planProdData.active_task_nik == userInfo.nik) ? (
                                                                <>
                                                                <button type="button" class="btn btn-success px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#54B435"}} onClick={(e) => {setAction(1); setShow(true);}}>Approve</button>
                                                                <button type="button" class="btn btn-danger px-5" style={{paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#E72929"}} onClick={(e) => {setAction(2); setShow(true);}}>Revise</button>
                                                                </>
                                                            )
                                                            } */}

                                                        {!row.statusapprove || row.statusapprove === 'ONREVISED' ? (
                                                                // <button
                                                                //     type="button"
                                                                //     className="btn px-4"
                                                                //     style={{ paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#41C9E2" }}
                                                                //     onClick={(e) => { setAction(1); setShow(true); setPlanDetailID(row.id); setPpdIncidentId(row.incident_id); }}
                                                                // >
                                                                //     Request Approval
                                                                // </button>
                                                                <button 
                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                    onClick={(e) => 
                                                                    { setAction(1); 
                                                                    setShow(true); 
                                                                    setPlanDetailID(row.id); 
                                                                    setPpdIncidentId(row.incident_id); 
                                                                    setPpdOperator(row.operator);
                                                                    setPpdGroup(row.prodgroup);
                                                                    }} className="btn btn-primary ">
                                                                    Request Approval
                                                                </button>
                                                            ) : (row.statusapprove === 'ONPROGRESS' && row.active_task_nik === userInfo.nik) ? (
                                                                // <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap:'10 px !important' }}>
                                                                <div className='d-flex justify-content-between'>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success p-2 mr-2"
                                                                        // style={{ paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#54B435" }}
                                                                        onClick={(e) => { 
                                                                            setAction(1); 
                                                                            setShow(true); 
                                                                            setPlanDetailID(row.id); 
                                                                            setPpdIncidentId(row.incident_id);
                                                                            setPpdGroup(row.prodgroup);
                                                                            setPpq(row.planprodqty);
                                                                            setPpdOperator(row.operator);
                                                                        }}
                                                                    >
                                                                        Approve
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger"
                                                                        // style={{ paddingBottom: 10, paddingTop: 10, border: "1px solid black", color: "black", backgroundColor: "#E72929" }}
                                                                        onClick={(e) => { 
                                                                            setAction(2);
                                                                            setShow(true); 
                                                                            setPlanDetailID(row.id); 
                                                                            setPpdIncidentId(row.incident_id);
                                                                            setPpdGroup(row.prodgroup);
                                                                         }}
                                                                    >
                                                                        Revise
                                                                    </button>
                                                                </div>
                                                            ) : null}
                                                    </td>
                                                    <td>
                                                    { (row.incident_id != null) &&
                                                        <a
                                                            onClick={(e) => openApproval(row.incident_id)}
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: 'green',
                                                                display: 'inline-block',
                                                                marginRight: 20
                                                            }}
                                                        >
                                                            <i className="far fa-list-alt" title='View Approval' />
                                                        </a>
                                                    }
                                                    </td>
                                                </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='row'>
                                    <div className='col-12 mt-2'>
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <Dialog header={`Approval Comments`} visible={show} resizable={false} onHide={() => setShow(false)} footer={renderFooter()} style={{width: '500px'}}>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <InputTextarea className='form-control' placeholder="Comment" value={comment} onChange={(e) => setComment(e.target.value)} rows={4} />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header="Approval Detail" visible={showDetail} resizable={false} onHide={() => setShowDetail(false)} breakpoints={{'960px': '1115px'}} style={{width: '1115px'}}>
                        <iframe id="approval-iframe" style={{width: '100%', height: '610px', border: 'none'}}></iframe>
                        <div>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'20%'}>Approval</th>
                                        <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'15%'}>Action</th>
                                        <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'20%'}>Name</th>
                                        <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'15%'}>Time</th>
                                        <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'30%'}>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {histories.map(history => (
                                        <tr>
                                            <td>{history.task_name}</td>
                                            <td>{history.action_name}</td>
                                            <td>{history.task_username}</td>
                                            <td style={{whiteSpace: 'nowrap'}}>{history.action_date}</td>
                                            <td>{history.note == null ? `-` : history.note}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Dialog>   
                </div>

            </section>
        </div>
    );
}

export default PlanProdViewScreen;