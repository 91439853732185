import {useEffect} from 'react';
import image1 from './1.jpg';
import image2 from './2.jpg';
const { $ } = window;

const HomeScreen = () => {   
    useEffect(() => {
        $("#slideshow > div:gt(0)").hide();

        setInterval(function() {
            $('#slideshow > div:first')
                .fadeOut(2000)
                .next()
                .fadeIn(2000)
                .end()
                .appendTo('#slideshow');
            }, 
        5000);
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-8 offset-2 col-md-6 offset-md-3 mt-5 ">
                            <div id="slideshow" style={{margin: '50px auto', position: 'relative', padding: '10px'}}>
                                <div style={{position: 'absolute', top: '10px', left: '10px', right: '10px', bottom: '10px'}}>
                                    <img src={image1} width={`100%`} />
                                </div>
                                <div>
                                    <img src={image2} width={`100%`} />
                                </div>
                            </div>
                        </div>
                        <div className="col-2 col-md-3">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item active"><a href="#">Home</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row"></div>
                </div>
            </section>
        </div>
    );

};

export default HomeScreen;