import React, { Component, useEffect, useState, useRef } from 'react';
import { changeStatusInspCategory, createInspCategory, deleteInspCategory, getInspCategoryAll, updateInspCategory } from '../../../Service/InspCategoriesService';
import { useForm, Controller, handleSubmit } from 'react-hook-form';
import Swal from 'sweetalert2';
import MTable from '../../../Components/MTable/MTable';
import Switch from '@mui/material/Switch';
import Overlay from '../../../Components/Overlay/Overlay';
const { $ } = window;
let processingId = -1;

const InspCategoryScreen = () => {
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm();
    const mTable = useRef();
    const [state, setState] = useState({category: {}, processing: false}); 

    const columns = [
        { id: 1, title: 'Inspection Category Name', field: 'name', sortable: true },
        {
            id: 2,
            title: 'Status',
            sortable: true,
            render: item => {
                return (
                    <Switch color="warning" checked={item.is_active} onChange={(e) => changeStatus(item.id, e.target.checked)} />
                );
            },
        },
        {
            id: 3,
            title: '',
            sortable: true,
            render: item => {
                return (
                    <div>
                        <a onClick={onEdit(item)} style={{ cursor: 'pointer', color: 'green', display: 'inline-block', marginRight: 15 }}>
                            <i className="fas fa-edit" />
                            <span style={{ marginLeft: 10 }}>Edit</span>
                        </a>
                        {/* <a onClick={onRemove(item)} style={{ cursor: 'pointer', color: 'maroon', display: 'inline-block' }}>
                            <i className="fas fa-trash" />
                            <span style={{ marginLeft: 10 }}>Remove</span>
                        </a> */}
                    </div>);
            },
        },
    ];

    const propsTable = { columns, getData: getInspCategoryAll };

    const removeData = id => {
        deleteInspCategory(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

    const changeStatus = (id, status) => {
        const body = {
            is_active: status
        }

        changeStatusInspCategory(id, body, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Change status success',
                    text: 'Data has been changed!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Change status fail',
                text: 'Data can not be changed!'
            });
        });
    }

    const onEdit = item => () => {
        reset(item);
        setState({...state, category: item});
        console.log('selected ', item);
    }

    const onRemove = item => () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(item.id);
            }
        });
    }

    const onAddData = () => {
        onReset();
    }

    const startProcessing = () => {
        processingId = setTimeout(() => {
            setState({...state, processing: true});
        }, 150);
    }

    const stopProcessing = () => {
        clearTimeout(processingId);
        setState({...state, processing: false});
    }

    const onSubmit = (data) => {
        console.log('submit data', data);
        console.log('brand', category);
        const formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);
        }
        startProcessing();
        const response = category.id ? updateInspCategory(formData) : createInspCategory(formData);
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                stopProcessing();
                Swal.fire({
                    icon: 'success',
                    title: 'Save data success',
                    text: 'Data has been saved!'
                }).then(r => { mTable.current.refresh(); onReset() })
            }
        }).catch((err) => {
            if (err.response.status == 422) {
                const obj = Object.keys(err.response.data.errors);
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: err.response.data.errors[obj[0]][0]
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Save data error!'
                });
            }
            stopProcessing();
        });
    }

    const onReset = () => {
        reset({
            category_id: 0,
            code: '',
            name: '',
            is_active: true
        });
        setState({...state, category: {}});
    }

    const { category, processing } = state;

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Inspection Category </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Inspection Category Page</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-4'>
                            <Overlay display={processing} />
                            <form name="form-detail" onSubmit={handleSubmit(onSubmit)}>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h3 className="card-title">
                                            <i className="fas fa-tag mr-1" />
                                            {category.id ? 'Edit Inspection Category' : 'New Inspection Category'}
                                        </h3>
                                    </div>
                                    <div className='card-body'>
                                        <div className='form-group'>
                                            <label htmlFor="code">Inspection Category Code</label>
                                            <input id="code" {...register("code", { required: { value: true, message: 'Inspection category code is required!' } })} className='form-control' />
                                            {errors.code && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.code.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="name">Inspection Category Name</label>
                                            <input id="name" {...register("name", { required: { value: true, message: 'Inspection category name is required!' } })} className='form-control' />
                                            {errors.name && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.name.message}</span>)}
                                        </div>
                                        {/* <div className='form-group'>
                                            <label htmlFor="brand-name">Active</label>
                                            <div>
                                                <Controller name="is_active" defaultValue={true} control={control} render={({ field }) => { return (<Switch color="warning" {...field} checked={field.value} />) }} />
                                            </div>
                                        </div> */}
                                        <div className='form-group'>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                                <div style={{ width: 150, marginRight: 10 }}><button type="button" className="btn btn-block btn-outline-danger" onClick={onReset}><i className='fa fa-times'></i>  Clear</button></div>
                                                <div style={{ width: 150 }}><button type="submit" className="btn btn-block btn-outline-dark"><i className='fa fa-save'></i> Save</button></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-tags mr-1" />
                                        List Inspection Category
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <MTable ref={mTable} {...propsTable} onAddData={onAddData} showIndex={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default InspCategoryScreen;