import axios from 'axios';
import { handleResponse } from './HelperService';

const getLSBCAll = (params, onSuccess, onError) => {
  const response = axios.get ('v1/admin/defect/get-all', {params});
  return handleResponse(response, onSuccess, onError);
};

const getLSBCById = (id, onSuccess, onError) => {
  const response = axios.get (`v1/admin/defect/get/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const createLSBC = (payload, onSuccess, onError) => { 
  const response = axios.post (`v1/admin/defect/create`, payload);
  return handleResponse(response, onSuccess, onError);
};

const updateLSBC = (id, payload, onSuccess, onError) => {
  const response = axios.put (`v1/admin/defect/update/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteLSBC = (id, onSuccess, onError) => {
  const response = axios.delete (`v1/admin/defect/delete/${id}`);
  return handleResponse(response, onSuccess, onError);
};

export {getLSBCAll, getLSBCById, createLSBC, updateLSBC, deleteLSBC};
