import React, { Component, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getQualityIssueSummaryByIqc } from '../../Service/QualityIssueService';
import { useDownloadExcel } from 'react-export-table-to-excel';
import JsPDF from 'jspdf';
const { $ } = window;
const localState = {};

const QualityIssueSummaryScreen = (props) => {
    const navigate = useNavigate();
    const { id_iqc } = useParams();
    const tableRef = useRef(null);
    const [ qualityIssueData, setQualityIssueData ] = useState({
        serial_number: [],
        problem: []
    });

    const generatePDF = () => {

        const report = new JsPDF('portrait','px','a3');
        report.html(document.querySelector('#report'), {
            callback: function (report) {
                report.save(qualityIssueData.docnumber + '.pdf');
            },
            html2canvas: { scale: 0.35 }
        });

    };

    const onGoback = () => {
        navigate(-1);
    }

    const getQualityIssueData = (id_iqc) => {
        getQualityIssueSummaryByIqc(id_iqc).then(res => {
            setQualityIssueData(res.data);
        });
    }

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: qualityIssueData.docnumber,
        sheet: qualityIssueData.docnumber
    })
    
    useEffect(() => {
        getQualityIssueData(id_iqc);
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Quality Issue Summary</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Quality Issue</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> View Quality Issue Summary</div>
                            <button type='button' className='btn btn-outline-dark' style={{ float: 'right', width: 100, marginRight: 20 }} onClick={onDownload}><i className="fas fa-file-excel"></i></button>
                            <button type='button' className='btn btn-outline-dark' title='Export PDF' style={{ float: 'right', width: 100, marginRight: 20 }} onClick={generatePDF}><i className="fas fa-file-pdf"></i></button>
                        </div>
                        <div className='card-body' id='report'>
                            {/* <div className='row'>
                                <div className='col-md-12 mb-4'>
                                    <h6>PT. MODENA FORMA INDONESIA</h6>
                                </div>
                            </div> */}
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <table className="table table-bordered" ref={tableRef}>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}><h6>PT. MODENA FORMA INDONESIA</h6></td>
                                                </tr>
                                                <tr>
                                                    <td width={'20%'} style={{letterSpacing: '0.5px'}}>MODEL</td>
                                                    <td width={'30%'}>{qualityIssueData.model}</td>
                                                    <td style={{letterSpacing: '0.5px'}}>ISSUE DATE</td>
                                                    <td>{qualityIssueData.defect_date}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{letterSpacing: '0.5px'}}>SUPPLIER</td>
                                                    <td>{qualityIssueData.cardname}</td>
                                                    <td style={{letterSpacing: '0.5px'}}>QTY DEFECT</td>
                                                    <td>{qualityIssueData.qty_defect}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{letterSpacing: '0.5px'}}>LOT ORDER</td>
                                                    <td>{qualityIssueData.lotorder}</td>
                                                    <td style={{letterSpacing: '0.5px'}}>NG RATE</td>
                                                    <td>{qualityIssueData.ng_rate}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{letterSpacing: '0.5px'}} rowSpan={qualityIssueData.serial_number.length}>REPORT DATE</td>
                                                    <td rowSpan={qualityIssueData.serial_number.length}>{qualityIssueData.report_date}</td>
                                                    <td style={{letterSpacing: '0.5px'}} rowSpan={qualityIssueData.serial_number.length}>SERIAL NUMBER</td>
                                                    <td>
                                                        {qualityIssueData.serial_number[0] && qualityIssueData.serial_number[0].serial_number}
                                                    </td>
                                                </tr>
                                                {qualityIssueData.serial_number.map((serial, key) => (
                                                    <>
                                                    {key > 0 &&
                                                        <tr>
                                                            <td>{serial.serial_number}</td>
                                                        </tr>
                                                    }
                                                    </>
                                                ))}
                                                <tr>
                                                    <td colSpan={4} align="center">PROBLEMS</td>
                                                </tr>
                                                {qualityIssueData.problem.map((problem, key) => (
                                                    <>
                                                        <tr style={{height: '100px'}}>
                                                            <td style={{letterSpacing: '0.5px'}}>DEFECT</td>
                                                            <td colSpan={3}>[{problem.defect_type} - {problem.defect_area}]  {problem.defect}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} align="center">PICTURE</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <table className="table table-bordered">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{problem.images[0] === undefined ? '' : <img src={problem.images[0].file_url} width={'100%'} />}</td>
                                                                            <td>{problem.images[1] === undefined ? '' : <img src={problem.images[1].file_url} width={'100%'} />}</td>
                                                                            <td>{problem.images[2] === undefined ? '' : <img src={problem.images[2].file_url} width={'100%'} />}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='form-group'>
                                <div className='d-flex float-right'>
                                    <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default QualityIssueSummaryScreen;