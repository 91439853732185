import React, { Component, useEffect, useState, useRef } from 'react';
import { changeStatusInspItem, createInspItem, deleteInspItem, getInspItemAll, updateInspItem } from '../../../Service/InspItemService';
import { getCategoryAll } from '../../../Service/CategoriesService';
import { getInspCategoryAll } from '../../../Service/InspCategoriesService';
import { useForm, Controller, handleSubmit } from 'react-hook-form';
import Swal from 'sweetalert2';
import MTable from '../../../Components/MTable/MTable';
import Switch from '@mui/material/Switch';
import Overlay from '../../../Components/Overlay/Overlay';
const { $ } = window;
let processingId = -1;

const InspItemScreen = () => {
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm();
    const mTable = useRef();
    const [state, setState] = useState({
        inspItem: {
            id_category: null,
            id_insp_categ: null,
            name: '',
            // type: null,
            position: 1,
            is_active: true
        }, 
        processing: false
    });
    const [categories, setCategories] = useState([]);
    const [inspCategories, setInspCategories] = useState([]); 

    const columns = [
        { id: 1, title: 'Inspection Item Name', field: 'name', sortable: true },
        { id: 2, title: 'Category Name', field: 'categname', sortable: true },
        { id: 3, title: 'Inspection Category Name', field: 'inspcategname', sortable: true },
        {
            id: 4,
            title: 'Status',
            sortable: true,
            render: item => {
                return (
                    <Switch color="warning" checked={item.active} onChange={(e) => changeStatus(item.id, e.target.checked)} />
                );
            },
        },
        {
            id: 5,
            title: '',
            sortable: true,
            render: item => {
                return (
                    <div>
                        <a onClick={onEdit(item)} style={{ cursor: 'pointer', color: 'green', display: 'inline-block', marginRight: 15 }}>
                            <i className="fas fa-edit" />
                            <span style={{ marginLeft: 10 }}>Edit</span>
                        </a>
                        {/* <a onClick={onRemove(item)} style={{ cursor: 'pointer', color: 'maroon', display: 'inline-block' }}>
                            <i className="fas fa-trash" />
                            <span style={{ marginLeft: 10 }}>Remove</span>
                        </a> */}
                    </div>);
            },
        },
    ];

    const propsTable = { columns, getData: getInspItemAll };

    const removeData = id => {
        deleteInspItem(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

    const changeStatus = (id, status) => {
        const body = {
            is_active: status
        }

        changeStatusInspItem(id, body, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Change status success',
                    text: 'Data has been changed!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Change status fail',
                text: 'Data can not be changed!'
            });
        });
    }

    const onEdit = item => () => {
        reset(item);
        setState({...state, inspItem: item});
        console.log('selected ', item);
    }

    const onRemove = item => () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(item.id);
            }
        });
    }

    const onAddData = () => {
        onReset();
    }

    const startProcessing = () => {
        processingId = setTimeout(() => {
            setState({...state, processing: true});
        }, 150);
    }

    const stopProcessing = () => {
        clearTimeout(processingId);
        setState({...state, processing: false});
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);
        }
        formData.append("type", 1);
        startProcessing();
        const response = inspItem.id ? updateInspItem(formData) : createInspItem(formData);
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Save data success',
                    text: 'Data has been saved!'
                }).then(r => { mTable.current.refresh(); onReset() })
            }

            stopProcessing();
        }).catch((err) => {
            if (err.response.status == 422) {
                const obj = Object.keys(err.response.data.errors);
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: err.response.data.errors[obj[0]][0]
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Save data error!'
                });
            }
            stopProcessing();
        });
    }

    const onReset = () => {
        reset({
            id_category: null,
            id_insp_categ: null,
            name: '',
            // type: null,
            position: 1,
            is_active: true
        });
        setState({...state, inspItem: {}});
    }

    const { inspItem, processing } = state;

    useEffect(() => {
        getCategoryAll({'perpage': 1000, 'filter': 'is_active:true'}).then(res => {
            setCategories(res.data.data);
        });
        getInspCategoryAll({'perpage': 1000, 'filter': 'is_active:true'}).then(res => {
            setInspCategories(res.data.data);
    });
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Inspection Item </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Inspection Item Page</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-4'>
                            <Overlay display={processing} />
                            <form name="form-detail" onSubmit={handleSubmit(onSubmit)}>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h3 className="card-title">
                                            <i className="fas fa-tag mr-1" />
                                            {inspItem.id ? 'Edit Inspection Item' : 'New Inspection Item'}
                                        </h3>
                                    </div>
                                    <div className='card-body'>
                                        <div className='form-group'>
                                            <label>Category</label>
                                            <select className='form-control' {...register("id_category", { required: { value: true, message: 'Category is required!' } })} onChange={e => state.inspItem.id_category = e.target.value} >
                                                <option value="">Select Category</option>
                                                {(categories || []).map((e, i) => <option key={'key_cat_' + i} value={e.id} selected={state.inspItem.id_category == e.id ? true : false}>{e.name}</option>)}
                                            </select>
                                            {errors.id_category && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.id_category.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label>Inspection Category</label>
                                            <select className='form-control' {...register("id_insp_categ", { required: { value: true, message: 'Inspection Category is required!' } })} onChange={e => state.inspItem.id_insp_categ = e.target.value} >
                                                <option value="">Select Inspection Category</option>
                                                {(inspCategories || []).map((e, i) => <option key={'key_insp_cat_' + i} value={e.id} selected={state.inspItem.id_insp_categ == e.id ? true : false}>{e.name}</option>)}
                                            </select>
                                            {errors.id_insp_categ && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.id_insp_categ.message}</span>)}
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="name">Inspection Item Name</label>
                                            <input id="name" {...register("name", { required: { value: true, message: 'Inspection category is required!' } })} className='form-control' />
                                            {errors.name && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.name.message}</span>)}
                                        </div>
                                        {/* <div className='form-group'>
                                            <label>Type</label>
                                            <select className='form-control' {...register("type", { required: 'Type is required!' })} onChange={e => state.inspItem.type = e.target.value} >
                                                <option value="0">Select Type</option>
                                                <option key={'key_type_' + 1} value={1} selected={state.inspItem.type == 1 ? true : false}>IQC</option>
                                                <option key={'key_type_' + 2} value={2} selected={state.inspItem.type == 2 ? true : false}>IPQC</option>
                                            </select>
                                            {errors.type && (<span className='text-danger'>{errors.type.message}</span>)}
                                        </div> */}
                                        <div className='form-group'>
                                            <label htmlFor="name">Position Number</label>
                                            <input type="number" defaultValue="1" min="1" max="50" id="position" {...register("position", { required: { value: true, message: 'Position number is required!' } })} className='form-control' />
                                            {errors.position && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.position.message}</span>)}
                                        </div>
                                        {/* <div className='form-group'>
                                            <label htmlFor="brand-name">Active</label>
                                            <div>
                                                <Controller name="is_active" defaultValue={true} control={control} render={({ field }) => { return (<Switch color="warning" {...field} checked={field.value} />) }} />
                                            </div>
                                        </div> */}
                                        <div className='form-group'>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                                <div style={{ width: 150, marginRight: 10 }}><button type="button" className="btn btn-block btn-outline-danger" onClick={onReset}><i className='fa fa-times'></i>  Clear</button></div>
                                                <div style={{ width: 150 }}><button type="submit" className="btn btn-block btn-outline-dark"><i className='fa fa-save'></i> Save</button></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-tags mr-1" />
                                        List Inspection Item
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <MTable ref={mTable} {...propsTable} onAddData={onAddData} showIndex={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default InspItemScreen;