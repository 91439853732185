import React, { Component, useEffect, useState, useRef } from 'react';
import { changeStatusInspReport, createInspReport, deleteInspReport, getInspReportAll, updateInspReport } from '../../../Service/InspReportService';
import { getInspItemAll } from '../../../Service/InspItemService';
import { getCategoryAll } from '../../../Service/CategoriesService';
import { getInspCategoryAll } from '../../../Service/InspCategoriesService';
import { getItemCodeAll } from '../../../Service/SapService';
import { getPartSAPAll } from '../../../Service/SapService';
import { useForm, Controller, handleSubmit, get } from 'react-hook-form';
import Swal from 'sweetalert2';
import MTable from '../../../Components/MTable/MTable';
import Switch from '@mui/material/Switch';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
const { $ } = window;
let processingId = -1;

const InspReportScreen = () => {
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm();
    const mTable = useRef();
    const [state, setState] = useState({
        processing: false
    });
    const [inspReport, setInspReport] = useState({
        id: null,
        id_category: null,
        id_insp_categ: null,
        id_insp_item: null,
        type: null,
        code: "",
        specification: "",
        standard : "",
        instrument : "",
        frequency : "",
        is_active: true,
        assyproc: "",
        partcode: ""
    });

    const [categories, setCategories] = useState([]);
    const [inspCategories, setInspCategories] = useState([]);
    const [itemCodes, setItemCodes] = useState([]);
    const [partSAP, setPartSAP] = useState([]);

    const [inspItems, setInspItems] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isDisabledAssyType, setIsDisabledAssyType] = useState(false);

    const columns = [
        { id: 1, title: 'Category', field: 'categname', sortable: true },
        { id: 2, title: 'Insp Category', field: 'inspcategname', sortable: true },
        { id: 3, title: 'Insp Item', field: 'name', sortable: true },
        { id: 4, title: 'Model', field: 'model', sortable: true },
        { id: 5, title: 'Assy Type', field: 'assytype', sortable: true },
        { id: 6, title: 'Part Name', field: 'partname', sortable: true },
        // {
        //     id: 4,
        //     title: 'Model',
        //     sortable: true,
        //     field: 'code',
        //     render: item => {
        //         return (
        //             "(" + item.code.split(".")[0] + ") " + item.code.split(".")[1]
        //         );
        //     },
        // },
        { id: 7, title: 'Specification', field: 'specification', sortable: true },
        { id: 8, title: 'Standard', field: 'standard', sortable: true },
        {
            id: 7,
            title: 'Type',
            sortable: true,
            field: 'type',
            render: item => {
                return (
                    item.type == 1 ? `IQC` : `IPQC`
                );
            },
        },
        {
            id: 8,
            title: 'Status',
            sortable: true,
            render: item => {
                return (
                    <Switch color="warning" checked={item.is_active} onChange={(e) => changeStatus(item.id, e.target.checked)} />
                );
            },
        },
        {
            id: 9,
            title: '',
            sortable: true,
            render: item => {
                return (
                    <div>
                        <a onClick={onEdit(item)} style={{ cursor: 'pointer', color: 'green', display: 'inline-block', marginRight: 15 }}>
                            <i className="fas fa-edit" />
                            <span style={{ marginLeft: 10 }}>Edit</span>
                        </a>
                        {/* <a onClick={onRemove(item)} style={{ cursor: 'pointer', color: 'maroon', display: 'inline-block' }}>
                            <i className="fas fa-trash" />
                            <span style={{ marginLeft: 10 }}>Remove</span>
                        </a> */}
                    </div>);
            },
        },
    ];

    const propsTable = { columns, getData: getInspReportAll };

    const removeData = id => {
        deleteInspReport(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

    const changeStatus = (id, status) => {
        const body = {
            is_active: status
        }

        changeStatusInspReport(id, body, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Change status success',
                    text: 'Data has been changed!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Change status fail',
                text: 'Data can not be changed!'
            });
        });
    }

    const onEdit = item => () => {
        // console.log('item', item)
        reset(item);
        setInspReport(item);
        setIsDisabledAssyType(true);
        setIsDisabled(true);
        getInspItem(item.id_category, item.id_insp_categ);
        getPartSAP(item.code);
        if(itemCodes.length == 0) getItemCode();
        setShowDialog(true);
    }

    const onRemove = item => () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(item.id);
            }
        });
    }

    const startProcessing = () => {
        processingId = setTimeout(() => {
            setState({...state, processing: true});
        }, 150);
    }

    const stopProcessing = () => {
        clearTimeout(processingId);
        setState({...state, processing: false});
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);
        }
        var model = "";
        for (var key in itemCodes) {
            if(itemCodes[key].value == inspReport.code) {
                model = itemCodes[key].model;
                break;
            }
        }

        let partName = "";
        for (let key in partSAP) {
            if(partSAP[key].value == inspReport.partcode) {
                partName = partSAP[key].ItemName;
                break;
            }
        }

        formData.append("code", inspReport["code"]);
        formData.append("id_insp_item", inspReport["id_insp_item"]);
        formData.append("part_code", inspReport["partcode"]);
        formData.append("assy_type", inspReport["assyproc"]);
        formData.append("model", model);
        formData.append("part_name", partName);
        // console.log('formData', formData)
        startProcessing();
        const response = inspReport.id ? updateInspReport(formData) : createInspReport(formData);

        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Save data success',
                    text: 'Data has been saved!'
                }).then(r => { 
                    mTable.current.refresh(); 
                    // onReset(); 
                });
            }

            stopProcessing();
        }).catch((err) => {
            if (err.response.status == 422) {
                const obj = Object.keys(err.response.data.errors);
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: err.response.data.errors[obj[0]][0]
                });
            } else if (err.response.status == 400) {
                Swal.fire({
                    icon: 'error',
                    title: 'Update data error',
                    text: err.response.data.message
                }).then(() => {
                    setShowDialog(true);
                });;
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Save data error!'
                });
            }
            stopProcessing();
        }); 
        
        setShowDialog(false);
    }

    const onReset = () => {
        reset({
            id: null,
            id_category: null,
            id_insp_categ: null,
            id_insp_item: null,
            type: null,
            code: "",
            specification: "",
            standard : "",
            instrument : "",
            frequency : "",
            is_active: true
        });

        setInspReport({
            id: null,
            id_category: null,
            id_insp_categ: null,
            id_insp_item: null,
            type: null,
            code: "",
            specification: "",
            standard : "",
            instrument : "",
            frequency : "",
            is_active: true,
            assyproc: "",
        });
    }
    
    const getCategory = () => {
        getCategoryAll({'perpage': 1000}).then(res => {
            setCategories(res.data.data);
        });
    }

    const getInspCategory = () => {
        getInspCategoryAll({'perpage': 1000}).then(res => {
            setInspCategories(res.data.data);
        });
    };

    const getInspItem = (id_category, id_insp_categ) => {
        if(id_category != null && id_insp_categ != null){
            const body = "id_category:"+id_category+",id_insp_categ:"+id_insp_categ

            getInspItemAll({'filter': body, 'perpage': 1000}).then(res => {
                setInspItems(res.data.data);
            });
        }else{
            setInspItems([]); 
            inspReport.id_insp_item = null;
        }
    }

    const getItemCode = () => {
        getItemCodeAll().then(res => {
            setItemCodes(res.data);
        });
    }

    const getPartSAP = (part) => {
        getPartSAPAll({'filter' : part}).then(res => {
            setPartSAP(res.data);
        });
    }
//  console.log('inspReport', inspReport)
    const onAddData = () => {
        setIsDisabledAssyType(false);
        if(inspReport.id && inspReport.id != null) {
            onReset();
            setInspItems([]); 
            inspReport.id_insp_item = null;
        }
        if(itemCodes.length == 0) getItemCode();

        if(partSAP.length == 0) getPartSAP(); 
        setShowDialog(true);
    }

    const { processing } = state;

    useEffect(() => {
        getCategory();
        getInspCategory();
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Inspection Report </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Inspection Report Page</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-tags mr-1" />
                                        List Inspection Report
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <MTable ref={mTable} {...propsTable} showIndex={true} showAddButton={true} onAddData={onAddData} />
                                </div>
                            </div>
                        </div>
                        <Dialog header={inspReport.id && inspReport.id != null ? 'Edit Inspection Report' : 'New Inspection Report'} visible={showDialog} onHide={() => setShowDialog(false)} breakpoints={{'960px': '120vw'}} style={{width: '80vw'}} >
                            <form name="form-detail" onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-12 col-md-3'>
                                        <div className='form-group'>
                                            <label>Category</label>
                                            <select className='form-control' {...register("id_category", { required: 'Category is required!' })} onChange={e => {setInspReport({...inspReport, id_category: e.target.value}); getInspItem(e.target.value, inspReport.id_insp_categ);}} disabled={inspReport.id && inspReport.id != null} >
                                                <option value="" selected={inspReport.id_category == null ? true : false}></option>
                                                {(categories || []).map((e, i) => <option key={'key_cat_' + i} value={e.id} selected={inspReport.id_category == e.id ? true : false}>{e.name}</option>)}
                                            </select>
                                            {errors.id_category && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.id_category.message}</span>)}
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3'>
                                        <div className='form-group'>
                                            <label>Inspection Category</label>
                                            <select className='form-control' {...register("id_insp_categ", { required: 'Inspection Category is required!' })} onChange={e => {setInspReport({...inspReport, id_insp_categ: e.target.value}); getInspItem(inspReport.id_category, e.target.value);}} disabled={inspReport.id && inspReport.id != null} >
                                                <option value="" selected={inspReport.id_insp_categ == null ? true : false}></option>
                                                {(inspCategories || []).map((e, i) => <option key={'key_insp_cat_' + i} value={e.id} selected={inspReport.id_insp_categ == e.id ? true : false}>{e.name}</option>)}
                                            </select>
                                            {errors.id_insp_categ && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.id_insp_categ.message}</span>)}
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3'>
                                        <div className='form-group'>
                                            <label>Inspection Item</label>
                                            <Dropdown value={inspReport.id_insp_item} options={inspItems} 
                                                onChange={(e) => setInspReport({...inspReport, id_insp_item: e.target.value})} 
                                                optionValue="id" optionLabel="name" filter style={{width: '100%', height: '37px'}} disabled={inspReport.id && inspReport.id != null} />
                                            {errors.id_insp_item && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.id_insp_item.message}</span>)}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label>Assy. Process</label>
                                            <select className='form-control'
                                                disabled={isDisabledAssyType} 
                                                onChange={e => {
                                                setInspReport({...inspReport, assyproc: e.target.value});
                                                {e.target.value == 'CBU' ? setIsDisabled(true) : setIsDisabled(!true)}
                                            }} 
                                            value={inspReport.assyproc}
                                            >
                                                <option value="" selected={inspReport.assyproc == "" ? true : false}></option>
                                                <option value="CBU" selected={inspReport.assytype == 'CBU' ? true : false}>CBU</option>
                                                <option value="CKD" selected={inspReport.assytype == 'CKD' ? true : false}>CKD</option>
                                                <option value="SKD" selected={inspReport.assytype == 'SKD' ? true : false}>SKD</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-4'>
                                        <div className='form-group'>
                                            <label>Item Codes</label>
                                            <div>
                                                <Dropdown value={inspReport.code} options={itemCodes} 
                                                    onChange={(e) => {
                                                        setInspReport({...inspReport, code: e.target.value});
                                                        getPartSAP(e.target.value, inspReport.code);
                                                    }}
                                                    optionLabel="label" filter filterBy="label" style={{width: '100%', height: '37px'}} disabled={inspReport.id && inspReport.id != null} />
                                                {errors.code && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.code.message}</span>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <div className='form-group'>
                                            <label>Part</label>
                                                <div>
                                                    <Dropdown value={inspReport.partcode} options={partSAP} 
                                                        disabled={isDisabled}
                                                        onChange={(e) => {
                                                            setInspReport({...inspReport, partcode: e.target.value});
                                                        }}
                                                        optionLabel="ItemName" filter filterBy="ItemName" style={{width: '100%', height: '37px'}} />
                                                    {errors.code && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.code.message}</span>)}
                                                </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <div className='form-group'>
                                            <label>Type</label>
                                            <select className='form-control' {...register("type", { required: 'Type is required!' })} onChange={e => inspReport.type = e.target.value} disabled={inspReport.id && inspReport.id != null} >
                                                <option value=""></option>
                                                <option key={'key_type_' + 1} value={1} selected={inspReport.type == 1 ? true : false}>IQC</option>
                                                <option key={'key_type_' + 2} value={2} selected={inspReport.type == 2 ? true : false}>IPQC</option>
                                            </select>
                                            {errors.type && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.type.message}</span>)}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="specification">Specification</label>
                                            <input id="specification" {...register("specification", { required: { value: true, message: 'Specification is required!' } })} className='form-control' />
                                            {errors.specification && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.specification.message}</span>)}
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="standard">Standard</label>
                                            <input id="standard" {...register("standard", { required: { value: true, message: 'Standard is required!' } })} className='form-control' />
                                            {errors.standard && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.standard.message}</span>)}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="instrument">Instrument</label>
                                            <input id="instrument" {...register("instrument", { required: { value: true, message: 'Instrument is required!' } })} className='form-control' />
                                            {errors.instrument && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.instrument.message}</span>)}
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="frequency">Frequency</label>
                                            <input id="frequency" {...register("frequency", { required: { value: true, message: 'Frequency is required!' } })} className='form-control' />
                                            {errors.frequency && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.frequency.message}</span>)}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor="is_active">Active</label>
                                            <div>
                                                <Controller name="is_active" defaultValue={true} control={control} render={({ field }) => { return (<Switch color="warning" {...field} checked={field.value} />) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className='form-group'>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                                <div style={{ width: 150, marginRight: 10 }}><button type="button" className="btn btn-block btn-outline-danger" onClick={onReset}><i className='fa fa-times'></i>  Clear</button></div>
                                                <div style={{ width: 150 }}><button type="submit" className="btn btn-block btn-outline-dark"><i className='fa fa-save'></i> Save</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Dialog>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default InspReportScreen;