import axios from "axios";
import Swal from "sweetalert2";
import { loginUser } from "../../Service/UserService";
import { msAuthUser } from "../../Service/AuthService";

const ACTION_LOGOUT = 'ACTION_LOGOUT';
const AUTH_SUCCESS = "AUTH_SUCCESS";

const logout = () => dispatch => {
  delete axios.defaults.headers.common['Authorization'];
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('selectedCompany');
  dispatch({ type: ACTION_LOGOUT });
  sessionStorage.clear();
  window.location.href = '/';
}

const login = (payload, onError = err=>{}) => dispatch => {
  loginUser(payload, res => {
    const { access_token, refresh_token, username, nik, fullname, office, role, image_filename } = res.data;
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    const userInfo = { username, nik, fullname, office, role, image_filename };
    
    Swal.fire({
      icon: 'success',
      title: 'Succes!',
      text: 'Login succes, please wait for a while, system will be redirected to the home page',
      timer: 1500,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    }).then(r => {
      dispatch({ type: AUTH_SUCCESS, userInfo });
    })
    //
  }, onError)

}

const msLogin = (navigate, payload, onError = err => { }) => dispatch => {
  msAuthUser(payload, res => {
    const { access_token, refresh_token, username, nik, fullname, office, role, image_filename } = res.data;
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    localStorage.removeItem('image_name');
    const userInfo = { username, nik, fullname, office, role, image_filename };

    Swal.fire({
        icon: 'success',
        title: 'Succes!',
        text: 'Login succes, please wait for a while, system will be redirected to the home page',
        timer: 1500,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
        }
    }).then(r => {
        dispatch({ type: AUTH_SUCCESS, userInfo });
        navigate('/home');
    });
  }, onError);
}

export { ACTION_LOGOUT, AUTH_SUCCESS, login, logout, msLogin };