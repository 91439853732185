import React, { Component, useEffect, useState, useRef } from 'react';
import { changeStatusInspFfr, createInspFfr, deleteInspFfr, getInspFfrAll, updateInspFfr } from '../../../Service/InspFfrService';
import {getInspMstAll, getInspMstById, createInspMst, updateInspMst, changeStatusInspMst, deleteInspMst} from '../../../Service/MastStaService';
import { genMstExport, getExportFile, getImportFile, deleteExportFile, deleteFileById, executeFile, uploadFile } from '../../../Service/FileService';
import ErrorFileUpload from '../../../Components/FileHandler/ErrorFileUpload';
import { getCategoryAll } from '../../../Service/CategoriesService';
import { getInspCategoryAll } from '../../../Service/InspCategoriesService';
import { getItemCodeAll } from '../../../Service/SapService';
import { useForm, Controller, handleSubmit } from 'react-hook-form';
import { handlerFormError, importProductAlert, uuid } from "../../../Utils/Utils";
import Overlay from '../../../Components/Overlay/Overlay';
import Swal from 'sweetalert2';
import MTable from '../../../Components/MTable/MTable';
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { excel } from '../../../Images'
import Switch from '@mui/material/Switch';
import { toastError, toastSuccess } from "../../../Components/Toasts/Toast";
import Modal from "../../../Components/Layout/Modal";

const { $ } = window;
let processingId = -1;
const modalDownload = '#mst-download';
const modalUpload = '#mst-upload';

const MastStaScreen = () => {
    const { register, handleSubmit, watch, reset, control, formState: { errors } } = useForm();
    const mTable = useRef();
    const [state, setState] = useState({
        processing: false,
        fileExport: null,
        files: []
    });
    
    const [mstStdtime, setmstStdtime] = useState({
        id: null,
        id_category: null,
        itemname: "",
        itemcode: "",
        itemdesc: "",
        st_ave: 0,
        assytype: "",
        is_active: true
    });

    const [categories, setCategories] = useState([]);
    const [itemCodes, setItemCodes] = useState([]);

    const [showDialog, setShowDialog] = useState(false);
    const [showDialogUpload, setShowDialogUpload] = useState(false);
    const [showDialogDownload, setShowDialogDownload] = useState(false);
    const columns = [
        { id: 1, title: 'Category', field: 'category', sortable: true },
        { id: 2, title: 'Item Code', field: 'itemcode', sortable: true },
        { id: 3, title: 'Item Name', field: 'itemname', sortable: true },
        { id: 4, title: 'Item Desc', field: 'itemdesc', sortable: true },
        { id: 5, title: 'Standard Lead Time (Min/Unit/Person)', field: 'st_ave', sortable: true },
        { id: 6, title: 'Assy. Proccess', field: 'assytype', sortable: true },
        { id: 7, title: 'Updated Time', field: 'updated_date', sortable: true },
        {
            id: 8,
            title: 'Status',
            sortable: true,
            render: item => {
                return (
                    <Switch color="warning" checked={item.is_active} onChange={(e) => changeStatus(item, e.target.checked)} />
                    // <Switch color="warning" key={item.id} checked={item.is_active} onChange={(e) => changeStatus(item.id, e.value)} disabled />
                );
            },
        },
        {
            id: 9,
            title: 'Action',
            sortable: true,
            render: item => {
                return (
                    <div style={{ display:'grid', justifyContent:'center' }}>
                        <a onClick={onEdit(item)} style={{ cursor: 'pointer', color: 'green', display: 'inline-block' }}>
                            <i className="fas fa-edit" />
                            {/* <span style={{ marginLeft: 10 }}>Edit</span> */}
                        </a>
                        <a onClick={onRemove(item)} style={{ cursor: 'pointer', color: 'maroon', display: 'inline-block' }}>
                            <i className="fas fa-trash" />
                            {/* <span style={{ marginLeft: 10 }}>Delete</span> */}
                        </a>
                    </div>);
            },
        },
    ];
    
    const propsTable = { columns, getData: getInspMstAll, customFilter: 'is_active:true', order: 'updated_date', direction: 'desc' };
    
   

    const removeData = id => {
        deleteInspMst(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }

    const changeStatus = (item, status) => {
        const body = {
            is_active: status
        }
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                changeStatusInspMst(item.id, body, res => {
                    if (res.status == 200 || res.status == 201) {
                        item.is_active = status;
                        Swal.fire({
                            icon: 'success',
                            title: 'Change status success',
                            text: 'Data has been changed!'
                        }).then(res => { mTable.current.refresh(); });
                    }
                }, error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Change status fail',
                        text: 'Data can not be changed!'
                    });
                });
            }
        });
        
        // console.log(id, status, "<<< ini id dan status");
    }

    const onEdit = item => () => {
        reset(item);
        setmstStdtime(item);
        if(itemCodes.length == 0) getItemCode(); 
        setShowDialog(true);
    }

    const onRemove = item => () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(item.id);
            }
        });
    }

    const startProcessing = () => {
        processingId = setTimeout(() => {
            setState({...state, processing: true});
        }, 150);
    }

    const stopProcessing = () => {
        clearTimeout(processingId);
        setState({...state, processing: false});
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        for (var key in mstStdtime) {
            formData.append(key, mstStdtime[key]);
        }
        startProcessing();
        
        const response = mstStdtime.id ? updateInspMst(mstStdtime) : createInspMst(mstStdtime);
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                setShowDialog(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Save data success',
                    text: 'Data has been saved!'
                }).then(r => { mTable.current.refresh(); onReset() })
            }

            stopProcessing();
        }).catch((err) => {
            if (err.response.status == 422) {
                const obj = Object.keys(err.response.data.errors);
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: err.response.data.errors[obj[0]][0]
                });
            } else if (err.response.status == 400) {
                Swal.fire({
                    icon: 'error',
                    title: 'Update data error',
                    text: err.response.data.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Save data error!'
                });
            }
            stopProcessing();
        });

        setShowDialog(false);
    }

    const onReset = () => {
        reset({
            id: null,
            id_category: null,
            itemname: "",
            itemcode: "",
            itemdesc: "",
            st_ave: 0,
            assytype: "",
            is_active: true
        });

        setmstStdtime({
            id: null,
            id_category: null,
            itemname: "",
            itemcode: "",
            itemdesc: "",
            st_ave: 0,
            assytype: "",
            is_active: true
        });
    }
    
    const getCategory = () => {
        getCategoryAll({'perpage': 1000}).then(res => {
            setCategories(res.data.data);
        });
    }

    const getItemCode = () => {
        getItemCodeAll().then(res => {
            setItemCodes(res.data);
        });
    }

    const onAddData = () => {
        if(mstStdtime.id && mstStdtime.id != null) onReset();
        if(itemCodes.length == 0) getItemCode(); 
        setShowDialog(true);
    }

    const onCloseDownload = () => {
        $(modalDownload).modal('hide');
    }

    const onCloseUpload = () => {
        $(modalUpload).modal('hide');
    }

    const onDownload = () => {
        $(modalDownload).modal('show');
    }

    const onUpload = () => {
        $(modalUpload).modal('show');
    }

    const onGenFile = () => {
        // const { id_category } = inspFfr;
        // if(id_category === '' || id_category === null) {
        //     toastError('Please choose the Category !');
        //     return;
        // }

        startProcessing();
        const response = genMstExport();
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                toastSuccess(res.data.message);
                if (res.data.fileExport) {
                    setTimeout(() => {
                        setState({ ...state, fileExport: res.data.fileExport });
                    }, 150);
                }
            }
        }).catch(({ response: { data } }) => {
            toastError(data.message);
        }).finally(_ => stopProcessing());
    }
    
    const onDeleteFileTemp = (i) => {
        files.splice(i, 1);
        setState({ ...state, files });
    }

    const onDeleteFileImport = (id, index) => {
        startProcessing();
        const response = deleteFileById(id);
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                toastSuccess(res.data.message);
                files.splice(index, 1);
                setTimeout(() => {
                    setState({ ...state, files });
                }, 150);
            }
        }).catch(({ response: { data } }) => {
            toastError(data.message);
        }).finally(_ => stopProcessing());
    }

    const onExecuteFile = (id, index, action) => {
        startProcessing();
        const response = executeFile(id, action);
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                files.splice(index, 1);
                setTimeout(() => {
                    setState({ ...state, files });
                }, 150);

                /*Swal.fire({
                    icon: res.data.total_created || res.data.total_updated ? 'success' : 'warning',
                    title: res.data.total_created || res.data.total_updated ? 'Import success' : 'Import failed',
                    html: importProductAlert(res.data)
                });*/
                Swal.fire({
                    icon: 'success',
                    title:"Create MST Success!"
                });
            }
        }).catch(({ response: { data } }) => {
            files.splice(index, 1);
            setTimeout(() => {
                setState({ ...state, files });
            }, 150);

            Swal.fire({
                icon: data.total_created || data.total_updated ? 'success' : 'warning',
                title: data.total_created || data.total_updated ? 'Import success' : 'Import failed',
                html: importProductAlert(data)
            });
        }).finally(_ => {$(modalUpload).modal('hide'); mTable.current.refresh(); stopProcessing();});
    }

    const onFileChange = e => {
        if (files.length <= 2) {
            const _files = [...files, ...e.target.files];
            setState({ ...state, files: _files });
        } else {
            toastError('Maximum file is reached!');
        }
    }

    const onFileUpload = (i) => {
        const formData = new FormData();
        const file = files[i];
        formData.append('file', file);

        const response = uploadFile('mst_master', formData);
        startProcessing();
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                toastSuccess("File upload success");
                files[i] = res.data.data;
                setTimeout(() => {
                    setState({ ...state, files });
                }, 150);
            }
        }).catch(({ response: { data } }) => {
            handlerFormError(data);
        }).finally(_ => stopProcessing());
    }

    const onDeleteFile = () => {
        startProcessing();
        const response = deleteExportFile('mst_master');
        response.then(res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Export data success',
                    text: res.data.message
                }).then()
                setTimeout(() => {
                    setState({ ...state, fileExport: null });
                }, 150);
            }
        }).catch(({ response: { data } }) => {
            Swal.fire({
                icon: 'error',
                title: 'Export data error',
                text: data.data.message
            }).then()
        }).finally(_ => stopProcessing());
    }

    useEffect(() => {
        getCategory();
        const reqExportFile = getExportFile('mst_master').then(res => res.data);
        const reqImport = getImportFile('mst_master').then(res => res.data);
        Promise.all([reqExportFile, reqImport]).then( results => {
            const [_fileExport, _fileImport] = results;
            const fExport = _fileExport.status != 404 ? _fileExport : null;
            const fImport = _fileImport.status != 404 ? _fileImport : [];
            setTimeout(() => {
                setState({ ...state, fileExport: fExport, files: fImport });
            }, 100);
        }).catch(({ response: { data } }) => {
            setTimeout(() => {
                setState({ ...state, fileExport: null, files: [] });
            }, 100);
        });

    }, []);

    const { processing, fileExport, files } = state;
    // console.log(fileExport.file_url, "link url cuy");
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage Master Standard Time </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Master Standard Time Page</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-tags mr-1" />
                                        List Master Standard Time
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="col-md-12 mb-3 d-flex flex-row justify-content-start align-items-center">
                                        <button className="btn btn-default btn-md mr-2" onClick={onDownload}><i className="fas fa-download" /> Download</button>
                                        <button className="btn btn-default btn-md mr-2" onClick={onUpload}><i className="fas fa-upload" /> Upload</button>
                                    </div>
                                    <div className="col-md-12">
                                        <ErrorFileUpload refresh={state} source={"mst_master"} />
                                    </div>
                                    <div className="col-md-12">
                                        <MTable ref={mTable} {...propsTable} showIndex={true} showAddButton={true} onAddData={onAddData} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Dialog header={mstStdtime.id && mstStdtime.id != null ? 'Edit Master Standard Time' : 'New Master Standard Time'} visible={showDialog} onHide={() => setShowDialog(false)} breakpoints={{'960px': '120vw'}} style={{width: '60vw'}} >
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label>Category</label>
                                        <select className='form-control'  value={mstStdtime.id_category} {...register("id_category", { required: 'Category is required!' })} onChange={e => setmstStdtime({...mstStdtime, id_category: e.target.value})} >
                                            <option value=""></option>
                                            {categories?.map((e, i) =>  <option key={'key_cat_' + i} value={e.id}>{e.name}</option>
                                            
                                        )}
                                        </select>
                                        {errors.id_category && (<span className='text-danger'>{errors.id_category.message}</span>)}
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label>Item Code</label>
                                    <div>
                                        <Dropdown value={mstStdtime.itemcode} options={itemCodes} {...register("itemcode", { required: 'Item Code is required!' })} 
                                            onChange={(e) => {
                                                itemCodes.filter((item) => item.value == e.target.value).map(filter_item => (
                                                    setmstStdtime({...mstStdtime, itemcode: e.target.value, itemdesc: filter_item.label, itemname: filter_item.model})
                                                ));
                                            }}
                                            optionLabel="label" filter filterBy="label" style={{width: '100%', height: '37px'}} />
                                        {errors.code && (<span className='text-danger'>{errors.code.message}</span>)}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemname">Item Name</label>
                                        <input id="itemname" value={mstStdtime.itemname} onChange={(e) => setmstStdtime({...mstStdtime, itemname: e.target.value})} className='form-control' />
                                        {errors.itemname && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.itemname.message}</span>)}
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="itemdesc">Item Desc</label>
                                        <input id="itemdesc" value={mstStdtime.itemdesc} onChange={(e) => setmstStdtime({...mstStdtime, itemdesc: e.target.value})} className='form-control' />
                                        {errors.itemdesc && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.itemdesc.message}</span>)}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="assy">Assy. Process</label>
                                        <select className='form-control' id="assy" value={mstStdtime.assytype} {...register("assy", { required: 'Assy Type is required!' })} onChange={e => setmstStdtime({...mstStdtime, assytype: e.target.value})}>
                                            <option value=""></option>
                                            {['SKD', 'CKD', 'CBU'].map((e, i) => <option key={'key_assy_' + i} value={e}>{e}</option>)}
                                        </select>
                                        {/* <input type="text" id="assy" min={0} defaultValue={0} {...register("assy", { required: 'Assy Type is required!' })} onChange={(e) => {mstStdtime.assy = e.target.value }} className='form-control' /> */}
                                        {errors.claim && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.claim.message}</span>)}
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="leadtime">Standard Lead Time</label>
                                        <div style={{display: "flex", textAlign: "center", gap: 15, alignContent: "center", alignItems: "center"}}>
                                        <input type="number" id="leadtime" value={mstStdtime.st_ave} 
                                        {...register("leadtime", { required: 'Lead Time is required!' })} 
                                        onChange={(e) => {
                                            // console.log(e.target.value, "<<< ini apa");
                                            setmstStdtime({...mstStdtime, st_ave: e.target.value})
                                            
                                        }} 
                                        className='form-control' />
                                        <label>Min/Unit/Person</label>
                                        </div>
                                        {errors.leadtime && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.leadtime.message}</span>)}
                                    </div>
                                </div>
                            </div>
                           {mstStdtime.id && mstStdtime.id !== null &&
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="is_active">Status</label>
                                        <select className='form-control' id="is_active" value={mstStdtime.is_active} {...register("is_active", { required: 'Assy Type is required!' })} onChange={e => setmstStdtime({...mstStdtime, is_active: e.target.value})}>
                                            <option value=""></option>
                                            {["ACTIVE", "INACTIVE"].map((e, i) => <option key={'key_assy_' + i} value={e === "ACTIVE" ? true : false}>{e}</option>)}
                                        </select>
                                        {/* <input type="text" id="is_active" min={0} defaultValue={0} {...register("assy", { required: 'Assy Type is required!' })} onChange={(e) => {mstStdtime.assy = e.target.value }} className='form-control' /> */}
                                        {errors.claim && (<span className='text-danger' style={{ fontSize: 14 }}>{errors.claim.message}</span>)}
                                    </div>
                                </div>
                            </div>}
                            <div className="row">
                                <div className="col-12">
                                    <div className='form-group'>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                            <div style={{ width: 150, marginRight: 10 }}><button type="button" className="btn btn-block btn-outline-danger" onClick={onReset}><i className='fa fa-times'></i>  Clear</button></div>
                                            <div style={{ width: 150 }}><button type="submit" onClick={(e) => {e.preventDefault(); onSubmit();}} className="btn btn-block btn-outline-dark"><i className='fa fa-save'></i> Save</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        <Modal id={modalDownload} title={"Download MST"} onClose={onCloseDownload} modalSize="modal-md">
                            <Overlay display={processing} />
                            {
                                !fileExport ? 
                                <div className='row'>
                                    <div className='col-12 col-md-12'>
                                        {/* <div className='form-group'>
                                            <label>Category</label>
                                            <select className='form-control' value={inspFfr.id_category} {...register("id_category", { required: 'Category is required!' })} onChange={e => setInspFfr({...inspFfr, id_category: e.target.value})} >
                                                <option value=""></option>
                                                {(categories || []).map((e, i) => <option key={'key_cat_' + i} value={e.id}>{e.name}</option>)}
                                            </select>
                                            {errors.id_category && (<span className='text-danger'>{errors.id_category.message}</span>)}
                                        </div> */}
                                        <div className='form-group'>
                                            <button type="button" onClick={onGenFile} className="btn btn-block btn-outline-dark"><i className='fa fa-save'></i> Generate File</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='row'>
                                    <div className='col-12 col-md-12'>
                                        <img src={excel} alt="excel" style={{ width: 80, height: 80 }} />
                                        <p>{fileExport.file_name}</p>
                                        <button className="btn btn-danger btn-md mr-3 w-25" onClick={onDeleteFile}>Delete</button>
                                        <a href={fileExport.file_url} target="_blank" download className="btn btn-warning btn-md w-25">Download</a>
                                    </div>
                                </div>
                            }
                        </Modal>

                        <Modal id={modalUpload} title={"Upload MST"} onClose={onCloseUpload} modalSize="modal-md">
                            <Overlay display={processing} />
                            <div className="card w-100 p-2" style={{ border: '1px solid #ccc', borderRadius: 5 }}>
                                <div className="card-body d-flex flex-row justify-content-center">
                                    {
                                        files.length > 0 && files.map((item, i) => {
                                            return item.file_url
                                                ?
                                                <div key={i} className="card d-flex flex-column align-items-center p-2" style={i > 0 ? { border: '1px solid #ccc', borderRadius: 5, marginLeft: 20 } : { border: '1px solid #ccc', borderRadius: 5 }}>
                                                    <div className="" style={{ position: 'absolute', top: -10, right: -10 }}>
                                                        <Button type='button' className='p-button-rounded p-button-danger' icon="pi pi-times" style={{ width: 30, height: 30 }} onClick={() => onDeleteFileImport(item.id, i)} />
                                                    </div>
                                                    <img src={excel} alt="excel" style={{ width: 80, height: 80 }} />
                                                    <p style={{ wordBreak: 'break-word' }}>{item.file_name}</p>
                                                    <div className="d-flex">
                                                        {/* <button className="btn btn-info btn-xs mr-2" onClick={() => onExecuteFile(item.id, i, 'create')}>Create</button>
                                                        <button className="btn btn-warning btn-xs" onClick={() => onExecuteFile(item.id, i, 'update')}>Update</button> */}
                                                        <button className="btn btn-info btn-xs mr-2" onClick={() => onExecuteFile(item.id, i, 'update')}>Import</button>
                                                    </div>
                                                </div>
                                                : <div key={i} className="card d-flex flex-column align-items-center p-2 mr-2" style={i > 0 ? { border: '1px solid #ccc', borderRadius: 5, marginLeft: 20 } : { border: '1px solid #ccc', borderRadius: 5 }}>
                                                    <img src={excel} alt="excel" style={{ width: 80, height: 80 }} />
                                                    <p style={{ wordBreak: 'break-word' }}>{item.name}</p>
                                                    <div className="d-flex">
                                                        <button className="btn btn-danger btn-xs mr-2" onClick={() => onDeleteFileTemp(i)}>Delete</button>
                                                        <button className="btn btn-default btn-xs" onClick={() => onFileUpload(i)}>Upload</button>
                                                    </div>
                                                </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="w-100 text-center">
                                <input id="on-file-upload" className='d-none' type="file" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={onFileChange}
                                    key={uuid()}
                                />
                                <a type='button' className='btn btn-default btn-md' style={{ width: 120 }} onClick={() => { window.$("#on-file-upload").click() }}><i className='fa fa-file' /> Choose </a>
                            </div>
                        </Modal>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default MastStaScreen;