import React, {Component} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import LoginScreen from '../../Screen/Login/LoginScreen';

const ToLogin = () => {
  return <Navigate to="/login" replace={true} />;
};

const AuthNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<LoginScreen />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AuthNavigation;
