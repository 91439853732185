import axios from 'axios';
import { handleResponse } from './HelperService';

const getHistories = (id, onSuccess, onError) => {
    const response = axios.get(`v1/admin/workflow/histories/${id}`);
    return handleResponse (response, onSuccess, onError); 
};

const getDiagram = (id, onSuccess, onError) => {
    const response = axios.get(`v1/admin/workflow/diagram/${id}`);
    return handleResponse (response, onSuccess, onError); 
};

export { getHistories, getDiagram };