import React, { Component, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getLSBCById } from '../../Service/LSBCService';
const { $ } = window;
const localState = {};

const LSBCViewScreen = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [ LSBCData, setLSBCData ] = useState({
        id: 1,
        id_parent: null,
        docnumber: "",
        docdate: "",
        docsource: "",
        doctype: "",
        grade: "",
        lotorder: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        serialno: null,
        whfrom: null,
        inspdate: null,
        inspdocnum: null,
        inspgrade: null,
        inspwh: null,
        inspstatus: 0,
        data: []
    });

    const onGoback = () => {
        navigate(-1);
    }

    const getLSBCData = (id) => {
        getLSBCById(id).then(res => {
            setLSBCData(res.data);
        });
    }
    
    useEffect(() => {
        getLSBCData(id);
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage LSBC</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">LSBC</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> View LSBC</div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td width={'20%'}>DOCUMENT NO.</td>
                                                    <td colSpan={3}><b>{LSBCData.docnumber}</b></td>
                                                </tr>
                                                <tr>
                                                    <td width={'20%'}>DOC. DATE</td>
                                                    <td width={'30%'}>{LSBCData.docdate}</td>
                                                    <td width={'20%'}>DOC. SOURCE</td>
                                                    <td width={'30%'}>{LSBCData.docsource}</td>
                                                </tr>
                                                <tr>
                                                    <td>DOC. TYPE</td>
                                                    <td>{LSBCData.doctype}</td>
                                                    <td>GRADE</td>
                                                    <td>{LSBCData.grade}</td>
                                                </tr>
                                                <tr>
                                                    <td>LOT ORDER</td>
                                                    <td>{LSBCData.lotorder}</td>
                                                    <td>ITEM CODE</td>
                                                    <td>{LSBCData.itemcode}</td>
                                                </tr>
                                                <tr>
                                                    <td>ITEM NAME</td>
                                                    <td>{LSBCData.itemdesc}</td>
                                                    <td>WH LOCATION</td>
                                                    <td>{LSBCData.whfrom}</td>
                                                </tr>
                                                <tr>
                                                    <td>SERIAL NUMBER</td>
                                                    <td>{LSBCData.serialno}</td>
                                                    <td>INSPECTION DATE</td>
                                                    <td>{LSBCData.inspdate}</td>
                                                </tr>
                                                <tr>
                                                    <td>INSPECTION DOC. NUMBER</td>
                                                    <td>{LSBCData.inspdocnum}</td>
                                                    <td>INSPECTION GRADE</td>
                                                    <td>{LSBCData.inspgrade}</td>
                                                </tr>
                                                <tr>
                                                    <td>INSPECTION WH</td>
                                                    <td>{LSBCData.inspwh}</td>
                                                    <td>INSPECTION STATUS</td>
                                                    <td>{LSBCData.inspstatus}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} align="center">DETAIL DEFECT</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{textAlign: "center"}}>Item Code</th>
                                                                    <th style={{textAlign: "center"}}>Item Desc</th>
                                                                    <th style={{textAlign: "center"}}>Quantity</th>
                                                                    <th style={{textAlign: "center"}}>Defect Source</th>
                                                                    <th style={{textAlign: "center"}}>Defect Name</th>
                                                                    <th style={{textAlign: "center"}}>Defect Location</th>
                                                                    <th style={{textAlign: "center"}}>Defect Notes</th>
                                                                    <th style={{textAlign: "center"}}>Defect Correction</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {LSBCData.data.length > 0 &&
                                                                    <>
                                                                    {LSBCData.data.map((data, key) => (
                                                                        <tr key={key}>
                                                                            <td>{data.itemcode}</td>
                                                                            <td>{data.itemdesc}</td>
                                                                            <td width={`10%`}>{data.quantity}</td>
                                                                            <td>{data.defsource}</td>
                                                                            <td>{data.defname}</td>
                                                                            <td>{data.deflocation}</td>
                                                                            <td>{data.defnotes}</td>
                                                                            <td>{data.defcorrection}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </>
                                                                }
                                                                {LSBCData.data.length == 0 &&
                                                                    <tr>
                                                                        <td colSpan={8}>No data found</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className='form-group'>
                                        <div className='d-flex float-right'>
                                            <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='row'>
                                    <div className='col-12 mt-2'>
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default LSBCViewScreen;