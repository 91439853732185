import React, { Component, useEffect } from 'react';

const Footer = () => {

    useEffect(()=>{
        window.loadTree();
    }, []);

    return (
        <footer className="main-footer">
            <strong>Copyright © 2022 MODENA </strong>
        </footer>
    )

}

export default Footer;